export const WashingMachinesIndia = {
  request_info: {
    success: true,
    credits_used: 21,
    credits_used_this_request: 1,
    credits_remaining: 479,
    credits_reset_at: "2022-10-21T17:19:14.000Z",
  },
  request_parameters: {
    type: "search",
    search_term: "Washing Machines",
    amazon_domain: "amazon.in",
    category_id: "1380369031",
    page: "1",
  },
  request_metadata: {
    created_at: "2022-09-28T06:43:31.191Z",
    processed_at: "2022-09-28T06:43:34.949Z",
    total_time_taken: 3.76,
    amazon_url:
      "https://www.amazon.in/s/?k=Washing+Machines&ref=nb_sb_noss_2&node=1380369031&page=1",
  },
  search_results: [
    {
      position: 1,
      title:
        "Godrej 6.2 Kg Fully-Automatic Top Loading Washing Machine (WT EON 620 AP Gp Gr, Graphic Grey)",
      asin: "B09JSBCPW1",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_kitchen_sr_pg1_1?ie=UTF8&adId=A06777952YMJ80YZ8AG3D&url=%2FGodrej-Fully-Automatic-Loading-Washing-Machine%2Fdp%2FB09JSBCPW1%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-1-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_atf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51Lel5eJRnL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.2,
      ratings_total: 175,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 12990,
          currency: "INR",
          raw: "₹12,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 18990,
          currency: "INR",
          raw: "₹18,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 12990,
        currency: "INR",
        raw: "₹12,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 2,
      title:
        "Godrej 6.2 Kg Fully-Automatic Top Loading Washing Machine (WT EON 620 A Gp Gr, Grey)",
      asin: "B07QGWHH63",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_kitchen_sr_pg1_1?ie=UTF8&adId=A06779832TLZ55XULHXN6&url=%2FGodrej-Fully-Automatic-WT-EON-620%2Fdp%2FB07QGWHH63%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-2-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_atf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/511v47yi5EL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.2,
      ratings_total: 2819,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 12490,
          currency: "INR",
          raw: "₹12,490",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19410,
          currency: "INR",
          raw: "₹19,410",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 12490,
        currency: "INR",
        raw: "₹12,490",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 3,
      title:
        "Samsung 6.5 kg Fully-Automatic Top Loading Washing Machine (WA65A4002VS/TL, Imperial Silver, Diamond Drum)",
      asin: "B08GY3PZQF",
      link: "https://www.amazon.in/Samsung-Fully-Automatic-WA65A4002VS-TL-Technology/dp/B08GY3PZQF/ref=sr_1_3?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-3",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/610osBbwucL._AC_UY218_.jpg",
      bestseller: {
        link: "https://www.amazon.in/gp/bestsellers/kitchen/1380370031/ref=sr_bs_2_1380370031_1",
        category: "Combination Washers & Dryers",
      },
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 25618,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16800,
          currency: "INR",
          raw: "₹16,800",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 4,
      title:
        "Whirlpool 7 Kg 5 Star Semi-Automatic Top Loading Washing Machine (SUPERB ATOM 7.0, Grey Dazzle, TurboScrub Technology)",
      asin: "B07WGD8QQT",
      link: "https://www.amazon.in/Whirlpool-SUPERB-ATOM-GREY-DAZZLE/dp/B07WGD8QQT/ref=sr_1_4?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-4",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61yZL6bw+1L._AC_UY218_.jpg",
      bestseller: {
        link: "https://www.amazon.in/gp/bestsellers/kitchen/1380373031/ref=sr_bs_3_1380373031_1",
        category: "Clothes Washing Machines",
      },
      coupon: {
        badge_text: "Save ₹250",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 8260,
      prices: [
        {
          symbol: "₹",
          value: 9940,
          currency: "INR",
          raw: "₹9,940",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 11250,
          currency: "INR",
          raw: "₹11,250",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 9940,
        currency: "INR",
        raw: "₹9,940",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 5,
      title:
        "LG 7 Kg 5 Star Semi-Automatic Top Loading Washing Machine (P7020NGAZ, Dark Gray, Wind Jet Dry)",
      asin: "B09DD573SY",
      link: "https://www.amazon.in/LG-P7020NGAZ-Fully-Automatic-Washing-Machine/dp/B09DD573SY/ref=sr_1_5?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-5",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71w4Yo0JLML._AC_UY218_.jpg",
      bestseller: {
        link: "https://www.amazon.in/gp/bestsellers/kitchen/14316143031/ref=sr_bs_4_14316143031_1",
        category: "Semi-Automatic",
      },
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 3369,
      prices: [
        {
          symbol: "₹",
          value: 11190,
          currency: "INR",
          raw: "₹11,190",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16090,
          currency: "INR",
          raw: "₹16,090",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 11190,
        currency: "INR",
        raw: "₹11,190",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 6,
      title:
        "LG 6.5 Kg 5 Star Smart Inverter Fully-Automatic Top Loading Washing Machine (T65SKSF4Z, Middle Free Silver)",
      asin: "B084LF24Z8",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T65SKSF4Z/dp/B084LF24Z8/ref=sr_1_6?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-6",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71sgMM9ZQ9L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 7035,
      prices: [
        {
          symbol: "₹",
          value: 15990,
          currency: "INR",
          raw: "₹15,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 21490,
          currency: "INR",
          raw: "₹21,490",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 15990,
        currency: "INR",
        raw: "₹15,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 7,
      title:
        "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine (Elena SX 6510, SX -Silver, In-Built Heater)",
      asin: "B097K5RD6S",
      link: "https://www.amazon.in/IFB-Fully-Automatic-Loading-Washing-Machine/dp/B097K5RD6S/ref=sr_1_7?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-7",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71hrC9lDoVL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,500",
        text: "with coupon",
      },
      rating: 4.2,
      ratings_total: 956,
      prices: [
        {
          symbol: "₹",
          value: 24490,
          currency: "INR",
          raw: "₹24,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 29690,
          currency: "INR",
          raw: "₹29,690",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 24490,
        currency: "INR",
        raw: "₹24,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 8,
      title:
        "Whirlpool 7.5 Kg 5 Star Semi-Automatic Top Loading Washing Machine (ACE 7.5 SUPREME, Grey Dazzle)",
      asin: "B083G25P9L",
      link: "https://www.amazon.in/Whirlpool-7-5-Semi-Automatic-ACE-SUPREME/dp/B083G25P9L/ref=sr_1_8?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-8",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61WJqbSrQQL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹750",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 3882,
      prices: [
        {
          symbol: "₹",
          value: 11240,
          currency: "INR",
          raw: "₹11,240",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 12050,
          currency: "INR",
          raw: "₹12,050",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 11240,
        currency: "INR",
        raw: "₹11,240",
        name: "Primary",
        is_primary: true,
      },
    },

    {
      position: 10,
      title:
        "Whirlpool 6 Kg 5 Star Semi-Automatic Top Loading Washing Machine (SUPERB ATOM 60I, Grey Dazzle)",
      asin: "B083G1WGLF",
      link: "https://www.amazon.in/Whirlpool-Semi-Automatic-SUPERB-ATOM-60I/dp/B083G1WGLF/ref=sr_1_10?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-10",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/81l6BjsfDyL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 2228,
      prices: [
        {
          symbol: "₹",
          value: 9180,
          currency: "INR",
          raw: "₹9,180",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 12700,
          currency: "INR",
          raw: "₹12,700",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 9180,
        currency: "INR",
        raw: "₹9,180",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 11,
      title:
        "Amazon Basics 6.5 Kg Fully Automatic Top Loading Washing Machine (Grey, LED panel, Memory Feature)",
      asin: "B07V3SGB2V",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg1_1?ie=UTF8&adId=A01080971RYF720EBPPZP&url=%2FAmazonBasics-Automatic-Loading-Washing-machine_Silver_Hisense2%2Fdp%2FB07V3SGB2V%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-11-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 139,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 10990,
          currency: "INR",
          raw: "₹10,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19999,
          currency: "INR",
          raw: "₹19,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10990,
        currency: "INR",
        raw: "₹10,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 12,
      title:
        "Electrolux 7.5kg 5 Star EcoInverter Fully Automatic Front Load Washing Machine, Scandinavian Design with 40°C Vapour Wash for Sustainable Clothing, White, UltimateCare 300, EWF7524D3WB",
      asin: "B0BBTVDYCC",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg1_1?ie=UTF8&adId=A0661952TVJ13E9FP52&url=%2FEcoInverter-Scandinavian-Sustainable-UltimateCare-EWF7524D3WB%2Fdp%2FB0BBTVDYCC%2Fref%3Dsr_1_12_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-12-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61DIv64sFeL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 32990,
          currency: "INR",
          raw: "₹32,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 62990,
          currency: "INR",
          raw: "₹62,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 32990,
        currency: "INR",
        raw: "₹32,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 13,
      title:
        "Whirlpool 7 Kg 5 Star Royal Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL 7.0 GENX, Grey, Hard Water Wash)",
      asin: "B08QP41KBP",
      link: "https://www.amazon.in/Whirlpool-Fully-Automatic-WHITEMAGIC-ROYAL-7-0/dp/B08QP41KBP/ref=sr_1_13?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-13",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61j25i+2K7L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.2,
      ratings_total: 1382,
      prices: [
        {
          symbol: "₹",
          value: 15740,
          currency: "INR",
          raw: "₹15,740",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 18950,
          currency: "INR",
          raw: "₹18,950",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 15740,
        currency: "INR",
        raw: "₹15,740",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 14,
      title:
        "Bosch 6 kg 5 Star Inverter Fully Automatic Front Loading Washing Machine with In - built Heater (WLJ2016WIN, White )",
      asin: "B08PZBZD5M",
      link: "https://www.amazon.in/Bosch-Inverter-Automatic-Loading-Washing/dp/B08PZBZD5M/ref=sr_1_14?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-14",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61tUypW4kkL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.5,
      ratings_total: 1467,
      prices: [
        {
          symbol: "₹",
          value: 24990,
          currency: "INR",
          raw: "₹24,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 37790,
          currency: "INR",
          raw: "₹37,790",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 24990,
        currency: "INR",
        raw: "₹24,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 15,
      title:
        "Whirlpool 6 Kg 5 Star Royal Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL 6.0 GENX, Satin Grey, Hard Water Wash)",
      asin: "B08R8DGDSK",
      link: "https://www.amazon.in/Whirlpool-Fully-Automatic-WHITEMAGIC-ROYAL-6-0/dp/B08R8DGDSK/ref=sr_1_15?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-15",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51MMO-Xl8qL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      is_prime: true,
      rating: 4.3,
      ratings_total: 1532,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16800,
          currency: "INR",
          raw: "₹16,800",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 16,
      title:
        "IFB 7 Kg Fully-Automatic Top Loading Washing Machine (TL RES Aqua, Light Grey, Smart Sense,3D Wash Technology)",
      asin: "B097K6XBG2",
      link: "https://www.amazon.in/IFB-Fully-Automatic-Loading-Washing-Technology/dp/B097K6XBG2/ref=sr_1_16?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-16",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61O5ugi1HuL._AC_UY218_.jpg",
      rating: 4.1,
      ratings_total: 407,
      prices: [
        {
          symbol: "₹",
          value: 18153,
          currency: "INR",
          raw: "₹18,153",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 21550,
          currency: "INR",
          raw: "₹21,550",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 18153,
        currency: "INR",
        raw: "₹18,153",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 17,
      title:
        "Samsung 6.0 Kg Inverter 5 Star Fully-Automatic Front Loading Washing Machine (WW60R20GLMA/TL, White, Hygiene Steam)",
      asin: "B084LGQKD7",
      link: "https://www.amazon.in/Samsung-Fully-Automatic-Loading-WW60R20GLMA-TL/dp/B084LGQKD7/ref=sr_1_17?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-17",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71i8f3-DsuL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,500",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 4602,
      prices: [
        {
          symbol: "₹",
          value: 21990,
          currency: "INR",
          raw: "₹21,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 26900,
          currency: "INR",
          raw: "₹26,900",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 21990,
        currency: "INR",
        raw: "₹21,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 18,
      title:
        "Whirlpool 6.5 Kg 5 Star Royal Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL 6.5 GENX, Grey, Hard Water Wash)",
      asin: "B08QNZTQ96",
      link: "https://www.amazon.in/Whirlpool-6-5-Fully-Automatic-WHITEMAGIC-ROYAL/dp/B08QNZTQ96/ref=sr_1_18?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-18",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51hu9nbHShL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 2376,
      prices: [
        {
          symbol: "₹",
          value: 14990,
          currency: "INR",
          raw: "₹14,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 18800,
          currency: "INR",
          raw: "₹18,800",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 14990,
        currency: "INR",
        raw: "₹14,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 19,
      title:
        "Croma 6.5 kg 5 Star Semi Automatic Top Load Washing Machine with 2 Years Comprehensive Warranty (CRLW065SMF202351, Blue)",
      asin: "B0B6PZJ5XH",
      link: "https://www.amazon.in/Automatic-Washing-Comprehensive-Warranty-CRLW065SMF202351/dp/B0B6PZJ5XH/ref=sr_1_19?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-19",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/614z2VIgIcL._AC_UY218_.jpg",
      rating: 4,
      ratings_total: 508,
      prices: [
        {
          symbol: "₹",
          value: 6990,
          currency: "INR",
          raw: "₹6,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 12000,
          currency: "INR",
          raw: "₹12,000",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 6990,
        currency: "INR",
        raw: "₹6,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 20,
      title:
        "LG 7 kg 5 Star Inverter Fully-Automatic Top Loading Washing Machine (‎T70SKSF1Z, Middle Free Silver, TurboDrum)",
      asin: "B08DF1Y7T7",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SKSF1Z/dp/B08DF1Y7T7/ref=sr_1_20?keywords=Washing+Machines&qid=1664347412&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-20",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/716gA9dqtEL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹750",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 2994,
      prices: [
        {
          symbol: "₹",
          value: 17490,
          currency: "INR",
          raw: "₹17,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 24990,
          currency: "INR",
          raw: "₹24,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 17490,
        currency: "INR",
        raw: "₹17,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 21,
      title:
        "AmazonBasics 6.5 kg Fully-Automatic Top Load Washing Machine (Grey/Black, Full Metal body, LED Display)",
      asin: "B07V838GTT",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg1_1?ie=UTF8&adId=A03186773RCHNH4TA4EOX&url=%2FAmazonBasics-Fully-Automatic-Washing-Machine-Display%2Fdp%2FB07V838GTT%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-21-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/619A7wmPjYL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      is_prime: true,
      rating: 4,
      ratings_total: 3360,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 9999,
          currency: "INR",
          raw: "₹9,999",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 25000,
          currency: "INR",
          raw: "₹25,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 9999,
        currency: "INR",
        raw: "₹9,999",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 22,
      title:
        "Samsung 8 Kg 5 Star AI Control & Wi-Fi, Fully-Automatic Front Loading Washing Machine (WW80T504DAB1TL, Black Caviar, Digital Inverter)",
      asin: "B09KGVKZXS",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg1_1?ie=UTF8&adId=A006579322YF7AJNAD3XF&url=%2FSamsung-Control-Fully-Automatic-WW80T504DAB1TL-Inverter%2Fdp%2FB09KGVKZXS%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664347412%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-22-spons%26psc%3D1&qualifier=1664347412&id=7897808069045125&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61sIXFuim6L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,500",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 149,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 35990,
          currency: "INR",
          raw: "₹35,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 51800,
          currency: "INR",
          raw: "₹51,800",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 35990,
        currency: "INR",
        raw: "₹35,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
  ],
  related_brands: [
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/8872ceb0-7d14-499e-b322-dbe0b35ed4f6._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/f3cd599c-de0b-4f0b-bf22-aafe05dc6a77._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/e283ea86-49cf-49ad-a39b-adb4da546fba._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/733fba1c-9a63-4b49-ba75-91b28b0530c7._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/gladiator-image-upload-prod/d/A21TJRUUN4KGV/bdd6b793f5360a4b4ba8b504219cd369._CR0,0,1200,628_SX460_QL70_.jpg",
    },
  ],
  pagination: {
    total_results: 1000,
    current_page: 1,
    next_page_link:
      "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031&page=2&qid=1664347412&ref=sr_pg_2",
    total_pages: 102,
  },
  refinements: {
    delivery: [
      {
        name: "Get It by Tomorrow",
        value: "n:1380369031||p_90/6741118031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_90%3A6741118031&dc&qid=1664347412&rnid=6741116031&ref=sr_nr_p_90_1&ds=v1%3A1GOX8Y4XqRr5MWU8a4jsrXiFX%2FG0ix3GqjTgLt%2Bt4DY",
        refinement_display_name: "Delivery",
      },
      {
        name: "Get It in 2 Days",
        value: "n:1380369031||p_90/20912642031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_90%3A20912642031&dc&qid=1664347412&rnid=6741116031&ref=sr_nr_p_90_2&ds=v1%3AKxkDb5gFV6bsfBtV1meEUn0gHPHJHgGnAlGQO4OsT5A",
        refinement_display_name: "Delivery",
      },
    ],
    departments: [
      {
        name: "Home & Kitchen",
        value: "n/976442031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031&dc&qid=1664347412&ref=sr_ex_n_1&ds=v1%3A3at438fJPtpfCYKdcHbjkMTcfEwJZeZB5gSEU%2FHokLQ",
        refinement_display_name: "Departments",
      },
      {
        name: "Large Appliances",
        value: "n/1380263031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031&dc&qid=1664347412&ref=sr_ex_n_1&ds=v1%3A%2F69Wjj%2Br8nL9maNabB%2BiPuGjM7CXGDu9%2BmXipni1t2o",
        refinement_display_name: "Departments",
      },
      {
        name: "Washing Machines & Dryers",
        value: "n/1380369031",
        refinement_display_name: "Departments",
      },
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AqLdvL5E00xEm6PqWwvBOD2tQrfTbrYZrwvxgOMo142A",
        refinement_display_name: "Departments",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_2&ds=v1%3AOnEcf%2B0OPB4K8PZqC7YS5BcBIZtOdpAz3uK%2BB3GxyOA",
        refinement_display_name: "Departments",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AaKXuAyFLTKBnBDALbGn8aMVlwLM10aYYcAzM99luWFQ",
        refinement_display_name: "Departments",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_4&ds=v1%3ApgO3MFNNMoOBWWP1nF5jwan%2BtvfNMPN0HO5Q%2BRYsXkc",
        refinement_display_name: "Departments",
      },
    ],
    child_categories: [
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AqLdvL5E00xEm6PqWwvBOD2tQrfTbrYZrwvxgOMo142A",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_2&ds=v1%3AOnEcf%2B0OPB4K8PZqC7YS5BcBIZtOdpAz3uK%2BB3GxyOA",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AaKXuAyFLTKBnBDALbGn8aMVlwLM10aYYcAzM99luWFQ",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664347412&rnid=976442031&ref=sr_nr_n_4&ds=v1%3ApgO3MFNNMoOBWWP1nF5jwan%2BtvfNMPN0HO5Q%2BRYsXkc",
        refinement_display_name: "Child Categories",
      },
    ],
    reviews: [
      {
        name: "4 Stars & Up",
        value: "n:1380369031||p_72/1318476031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318476031&dc&qid=1664347412&rnid=1318475031&ref=sr_nr_p_72_1&ds=v1%3Ad3G%2BOt2ZXQOd7b6W%2BJd6vsEGUR8MmUe1yhaUkWDUOJQ",
        refinement_display_name: "Reviews",
      },
      {
        name: "3 Stars & Up",
        value: "n:1380369031||p_72/1318477031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318477031&dc&qid=1664347412&rnid=1318475031&ref=sr_nr_p_72_2&ds=v1%3A3N0BzoFlNcuRocFVWzkPuedm6E1s%2BogjUc%2BclV7KtNc",
        refinement_display_name: "Reviews",
      },
      {
        name: "2 Stars & Up",
        value: "n:1380369031||p_72/1318478031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318478031&dc&qid=1664347412&rnid=1318475031&ref=sr_nr_p_72_3&ds=v1%3A%2F6BMxFrYtFM%2BEKLz2nIOJUgXdDqnrUgMTD6f%2FTddjNU",
        refinement_display_name: "Reviews",
      },
      {
        name: "1 Star & Up",
        value: "n:1380369031||p_72/1318479031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318479031&dc&qid=1664347412&rnid=1318475031&ref=sr_nr_p_72_4&ds=v1%3A4BZipnAWR8xGRQCQnOyr70pVeeOCdymNblSo%2FMiX%2Bjc",
        refinement_display_name: "Reviews",
      },
    ],
    price: [
      {
        name: "Under ₹10,000",
        value: "n:1380369031||p_36/5271572031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271572031&dc&qid=1664347412&rnid=5271547031&ref=sr_nr_p_36_1&ds=v1%3AzNPszEk1BRC2Fs6bzG8LKMDxJg5x0T8YKb9UARlcl%2Fs",
        refinement_display_name: "Price",
      },
      {
        name: "₹10,000 - ₹15,000",
        value: "n:1380369031||p_36/5271573031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271573031&dc&qid=1664347412&rnid=5271547031&ref=sr_nr_p_36_2&ds=v1%3Ai%2BMfdE9Dcme277a6xs72KXuLU4SKxpSFOT0aHYTKRTQ",
        refinement_display_name: "Price",
      },
      {
        name: "₹15,000 - ₹20,000",
        value: "n:1380369031||p_36/5271574031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271574031&dc&qid=1664347412&rnid=5271547031&ref=sr_nr_p_36_3&ds=v1%3A5I3LXM%2FiY3wWLSDPQcFfJJIxDK0%2FhYAniVfE8dPECvI",
        refinement_display_name: "Price",
      },
      {
        name: "₹20,000 - ₹30,000",
        value: "n:1380369031||p_36/5271575031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271575031&dc&qid=1664347412&rnid=5271547031&ref=sr_nr_p_36_4&ds=v1%3ANqN0%2FLtm%2F2nR5%2B7T8icNL56%2F2UDQLm2XNuVpoaoSZrA",
        refinement_display_name: "Price",
      },
      {
        name: "Over ₹30,000",
        value: "n:1380369031||p_36/5271576031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271576031&dc&qid=1664347412&rnid=5271547031&ref=sr_nr_p_36_5&ds=v1%3A3H44f8qpURK5WbGKQn5Of8DtHrTyhbnTEABoBMwrYag",
        refinement_display_name: "Price",
      },
      {
        name: "₹        ₹            Go",
        value: "p_36/price-range",
        refinement_display_name: "Price",
      },
    ],
    brand: [
      {
        name: "Samsung",
        value: "n:1380369031||p_89/Samsung",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ASamsung&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_1&ds=v1%3A2T7MLxdUGIJsnfa9x6TVFHYDuXe8fW8ai9Hc5QFaYzE",
        refinement_display_name: "Brand",
      },
      {
        name: "LG",
        value: "n:1380369031||p_89/LG",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ALG&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_2&ds=v1%3A14Ue2PcjhzTquzR0dDBgCdTSyM77KdVpF6I%2F97YP85k",
        refinement_display_name: "Brand",
      },
      {
        name: "IFB",
        value: "n:1380369031||p_89/IFB",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AIFB&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_3&ds=v1%3AX4bpnpyd9AbcxD6rTMtzW53v8SkT6hfe7Mg4dmEMYo4",
        refinement_display_name: "Brand",
      },
      {
        name: "Whirlpool",
        value: "n:1380369031||p_89/Whirlpool",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhirlpool&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_4&ds=v1%3APhXgRJFeAng8fn%2Fzo0T8Baq1pFspHTyZYofnLkIR4Yk",
        refinement_display_name: "Brand",
      },
      {
        name: "Godrej",
        value: "n:1380369031||p_89/Godrej",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGodrej&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_5&ds=v1%3AFJVN6JVA09ag5NIXW4SWZ5O6Kf9tTCCkwA7%2BGD%2BDarA",
        refinement_display_name: "Brand",
      },
      {
        name: "Bosch",
        value: "n:1380369031||p_89/Bosch",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ABosch&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_6&ds=v1%3ADSmnDA7Uh7RLPoK3z3zT6YCtJyDZAhViQW0Juoihu%2B4",
        refinement_display_name: "Brand",
      },
      {
        name: "Panasonic",
        value: "n:1380369031||p_89/Panasonic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3APanasonic&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_7&ds=v1%3AEB2RabNIOZxYeAvNAAjYncnH%2B0vySYBBDldsdSnBTm0",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMAHaierWhite Westinghouse (Trademark By Electrolux)GenericElectroSkyMARJI&ANUVRUTTI",
        value: "n:1380369031||undefined",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3AN0FdJoCctyoZMAASpaD3Jah%2FBtq9UB%2BbZZSk0kD%2FFt4",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMA",
        value: "n:1380369031||p_89/CROMA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3AN0FdJoCctyoZMAASpaD3Jah%2FBtq9UB%2BbZZSk0kD%2FFt4",
        refinement_display_name: "Brand",
      },
      {
        name: "Haier",
        value: "n:1380369031||p_89/Haier",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AHaier&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_9&ds=v1%3AYaowsS1rtAfIIhnM%2BDi4I0d58Gv5C%2Ftcv%2B9NgSYMB8U",
        refinement_display_name: "Brand",
      },
      {
        name: "White Westinghouse (Trademark By Electrolux)",
        value:
          "n:1380369031||p_89/White Westinghouse (Trademark By Electrolux)",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhite+Westinghouse+%28Trademark+By+Electrolux%29&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_10&ds=v1%3AZm9cCqWlOwAsPNZMzRpiWWpy7VaH5UWd0Ho15eDVgXY",
        refinement_display_name: "Brand",
      },
      {
        name: "Generic",
        value: "n:1380369031||p_89/Generic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGeneric&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_11&ds=v1%3AeMWAxtXuFcNHVdxOTsCwq1%2BRkO0sEnLWpfRu1itd4Ow",
        refinement_display_name: "Brand",
      },
      {
        name: "ElectroSky",
        value: "n:1380369031||p_89/ElectroSky",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AElectroSky&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_12&ds=v1%3AKMKxMiDZ1iW%2B7LSMMqv3EhmEo%2BM7OjsIKJMmwzNveH4",
        refinement_display_name: "Brand",
      },
      {
        name: "MARJI&ANUVRUTTI",
        value: "n:1380369031||p_89/MARJI&ANUVRUTTI",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AMARJI%26ANUVRUTTI&dc&qid=1664347412&rnid=3837712031&ref=sr_nr_p_89_13&ds=v1%3AwoMfpWgwLbK1TWwhqG5M%2Fr%2Be6Vbjtw3Iay7zRIBNPqs",
        refinement_display_name: "Brand",
      },
    ],
  },
  ad_blocks: [
    {
      campaign_id: "9581059940902",
      brand_logo:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/03331d03-d120-4680-9e1a-fd12b8b391e9._CR0,0,400,400_AC_SX130_SY60_QL70_.jpg",
      background_image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/8872ceb0-7d14-499e-b322-dbe0b35ed4f6._CR0,0,1200,628_SX507_QL70_.jpg",
      advertiser_id: "8339266330602",
      ad_id: "8339266330602",
      link: "https://aax-eu.amazon.in/x/c/RGFa9jXnDrnvfqqwPQl-HtIAAAGDgtYZEwMAAAH2AQBOL0EgICAgICAgICAgICBOL0EgICAgICAgICAgICAR0ewv/https://www.amazon.in/stores/page/CD7D1712-2B7B-4231-8063-DD0803A40E1C/?_encoding=UTF8&store_ref=SB_A000999012K2JL0X34XPP&pd_rd_plhdr=t&aaxitk=83ae09f23ff9465c86efba78a3b7c57b&hsa_cr_id=9223335210002&lp_asins=B0BBTVDYCC&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_bkgd&pd_rd_w=beF81&content-id=amzn1.sym.c679a10e-5f33-4f2e-b014-f92eed44a58f%3Aamzn1.sym.c679a10e-5f33-4f2e-b014-f92eed44a58f&pf_rd_p=c679a10e-5f33-4f2e-b014-f92eed44a58f&pf_rd_r=X881Q1DZRE5TAB318BCP&pd_rd_wg=W55nz&pd_rd_r=4028ce23-f85b-48b7-a0cd-f25a92d6f644",
      title: "Electrolux | Washing Machine |Ecoinverter Motor",
      store_link:
        "https://www.amazon.in/stores/page/CD7D1712-2B7B-4231-8063-DD0803A40E1C/?_encoding=UTF8&store_ref=SB_A000999012K2JL0X34XPP&pd_rd_plhdr=t&aaxitk=83ae09f23ff9465c86efba78a3b7c57b&hsa_cr_id=9223335210002&lp_asins=B0BBTVDYCC&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_cta&pd_rd_w=beF81&content-id=amzn1.sym.c679a10e-5f33-4f2e-b014-f92eed44a58f%3Aamzn1.sym.c679a10e-5f33-4f2e-b014-f92eed44a58f&pf_rd_p=c679a10e-5f33-4f2e-b014-f92eed44a58f&pf_rd_r=X881Q1DZRE5TAB318BCP&pd_rd_wg=W55nz&pd_rd_r=4028ce23-f85b-48b7-a0cd-f25a92d6f644",
      store_id: "CD7D1712-2B7B-4231-8063-DD0803A40E1C",
      store_name: "Electrolux India MDA",
      products: [
        {
          asin: "B0BBTVDYCC",
          link: "https://www.amazon.in/dp/B0BBTVDYCC",
          image:
            "https://m.media-amazon.com/images/I/61DIv64sFeL._AC_SR160,134_QL70_.jpg",
          is_prime: true,
          price: {
            symbol: "₹",
            value: 32990,
            currency: "INR",
            raw: "₹32,990.00",
          },
        },
      ],
    },
  ],
  video_blocks: [
    {
      video_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A18A6AOADY4Q3N/1652439019753/1c0aba33-b3bc-4e1b-b302-828f97d28712/1652439083379sbv.mp4",
      thumbnail_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A18A6AOADY4Q3N/1652439019753/1c0aba33-b3bc-4e1b-b302-828f97d28712/1652439083379.0000000.jpg",
      campaign_id: "20016609205698",
      advertiser_id: "19795215225",
      has_audio: true,
      products: [
        {
          asin: "B08QNZTQ96",
          link: "https://www.amazon.in/dp/B08QNZTQ96",
          image:
            "https://m.media-amazon.com/images/I/51hu9nbHShL._AC_UL640_QL65_.jpg",
          title:
            "Whirlpool 6.5 Kg 5 Star Royal Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL 6.5 GENX, Grey, Hard Water Wash)",
          rating: 4.5,
          ratings_total: 2376,
        },
      ],
    },
    {
      video_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A2JCBNSAU629YP/1615455063263/87762215-0e8f-4649-97da-6b60d90611d6/1615455123852sbv.mp4",
      thumbnail_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A2JCBNSAU629YP/1615455063263/87762215-0e8f-4649-97da-6b60d90611d6/1615455123852.0000000.jpg",
      campaign_id: "20016269825404",
      advertiser_id: "49505163212",
      has_audio: true,
      products: [
        {
          asin: "B08FS92CQ1",
          link: "https://www.amazon.in/dp/B08FS92CQ1",
          image:
            "https://m.media-amazon.com/images/I/81ipap8oy+L._AC_UL640_QL65_.jpg",
          title:
            "Panasonic Econavi 8 Kg 5 Star Built-In Heater Fully-Automatic Top Loading Washing Machine (NA-F80V9LRB, Silver, Stain Master+)",
          rating: 4.5,
          ratings_total: 98,
        },
      ],
    },
  ],
};

export const WashingMachinesIndia_p2 = {
  request_info: {
    success: true,
    credits_used: 22,
    credits_used_this_request: 1,
    credits_remaining: 478,
    credits_reset_at: "2022-10-21T17:19:14.000Z",
  },
  request_parameters: {
    type: "search",
    search_term: "Washing Machines",
    amazon_domain: "amazon.in",
    category_id: "1380369031",
    page: "2",
  },
  request_metadata: {
    created_at: "2022-09-28T07:23:37.669Z",
    processed_at: "2022-09-28T07:23:40.228Z",
    total_time_taken: 2.56,
    amazon_url:
      "https://www.amazon.in/s/?k=Washing+Machines&ref=nb_sb_noss_2&node=1380369031&page=2",
  },
  search_results: [
    {
      position: 1,
      title:
        "Amazon Basics 6.5 Kg Fully Automatic Top Loading Washing Machine (Grey, LED panel, Memory Feature)",
      asin: "B07V3SGB2V",
      link: "https://www.amazon.in/sspa/click?ie=UTF8&spc=MTo4ODkwNjIwODA4MDU2ODMzOjE2NjQzNDk4MTg6c3BfYXRmX25leHQ6MjAwNTE0ODg5ODg1OTg6OjA6Og&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGZfbmV4dA&url=%2FAmazonBasics-Automatic-Loading-Washing-machine_Silver_Hisense2%2Fdp%2FB07V3SGB2V%2Fref%3Dsr_1_17_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664349818%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-17-spons%26psc%3D1",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 139,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 10990,
          currency: "INR",
          raw: "₹10,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19999,
          currency: "INR",
          raw: "₹19,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10990,
        currency: "INR",
        raw: "₹10,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 2,
      title:
        "AmazonBasics 6.5 kg Fully-Automatic Top Load Washing Machine (Grey/Black, Full Metal body, LED Display)",
      asin: "B07V838GTT",
      link: "https://www.amazon.in/sspa/click?ie=UTF8&spc=MTo4ODkwNjIwODA4MDU2ODMzOjE2NjQzNDk4MTg6c3BfYXRmX25leHQ6MjAwNTE3Njk3MDE5OTg6OjA6Og&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGZfbmV4dA&url=%2FAmazonBasics-Fully-Automatic-Washing-Machine-Display%2Fdp%2FB07V838GTT%2Fref%3Dsr_1_18_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664349818%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-18-spons%26psc%3D1",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/619A7wmPjYL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      is_prime: true,
      rating: 4,
      ratings_total: 3360,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 9999,
          currency: "INR",
          raw: "₹9,999",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 25000,
          currency: "INR",
          raw: "₹25,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 9999,
        currency: "INR",
        raw: "₹9,999",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 3,
      title:
        "Samsung 6.0 Kg 5 Star Semi-Automatic Top Loading Washing Machine (WT60R2000LL/TL, Light Grey, Center jet technology)",
      asin: "B08FMN5ZQM",
      link: "https://www.amazon.in/Samsung-Semi-Automatic-WT60R2000LL-TL-technology/dp/B08FMN5ZQM/ref=sr_1_19?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-19",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61ySBQQ9GlL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.2,
      ratings_total: 1248,
      prices: [
        {
          symbol: "₹",
          value: 8990,
          currency: "INR",
          raw: "₹8,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 9900,
          currency: "INR",
          raw: "₹9,900",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8990,
        currency: "INR",
        raw: "₹8,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 4,
      title:
        "Amazon Basics 6.5 Kg Fully Automatic Top Loading Washing Machine (Grey, LED panel, Memory Feature)",
      asin: "B07V3SGB2V",
      link: "https://www.amazon.in/AmazonBasics-Automatic-Loading-Washing-machine_Silver_Hisense2/dp/B07V3SGB2V/ref=sr_1_20?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-20",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 139,
      prices: [
        {
          symbol: "₹",
          value: 10990,
          currency: "INR",
          raw: "₹10,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19999,
          currency: "INR",
          raw: "₹19,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10990,
        currency: "INR",
        raw: "₹10,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 5,
      title:
        "LG 7 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine with Heater (FHM1207SDL, Silver, 6 Motion Direct Drive)",
      asin: "B09LM39YR9",
      link: "https://www.amazon.in/LG-Inverter-Control-Fully-Automatic-FHM1207SDL/dp/B09LM39YR9/ref=sr_1_21?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-21",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/81L+k3MW3DL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹2,000", text: "with coupon" },
      rating: 4.4,
      ratings_total: 1738,
      prices: [
        {
          symbol: "₹",
          value: 28990,
          currency: "INR",
          raw: "₹28,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 46990,
          currency: "INR",
          raw: "₹46,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 28990,
        currency: "INR",
        raw: "₹28,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 6,
      title:
        "Panasonic 6.5 kg 5 Star Semi-Automatic Top Loading Washing Machine (NA-W65E5ARB, Blue, Powerful Motor)",
      asin: "B08B98715P",
      link: "https://www.amazon.in/Panasonic-Semi-Automatic-Loading-NA-W65E5ARB-Powerful/dp/B08B98715P/ref=sr_1_22?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-22",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61Lt1nXUF2L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 2210,
      prices: [
        {
          symbol: "₹",
          value: 8990,
          currency: "INR",
          raw: "₹8,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 11000,
          currency: "INR",
          raw: "₹11,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8990,
        currency: "INR",
        raw: "₹8,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 7,
      title:
        "Samsung 7.0 Kg 5 Star Semi-Automatic Top Loading Washing Machine (WT70M3000UU/TL, LIGHT GRAY, Double Storm Pulsator)",
      asin: "B09T6NH99B",
      link: "https://www.amazon.in/Samsung-Semi-Automatic-WT70M3000UU-TL-Pulsator/dp/B09T6NH99B/ref=sr_1_23?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-23",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/71StafL7njL._AC_UY218_.jpg",
      coupon: { badge_text: "Save ₹500", text: "with coupon" },
      rating: 4.3,
      ratings_total: 302,
      prices: [
        {
          symbol: "₹",
          value: 10490,
          currency: "INR",
          raw: "₹10,490",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 13500,
          currency: "INR",
          raw: "₹13,500",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10490,
        currency: "INR",
        raw: "₹10,490",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 8,
      title:
        "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine with In-Built Heater (FHM1408BDW, White, 6 Motion Direct Drive, 1400 RPM & Steam)",
      asin: "B0B1DNMQG6",
      link: "https://www.amazon.in/LG-Inverter-Control-Fully-Automatic-FHM1408BDW/dp/B0B1DNMQG6/ref=sr_1_24?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-24",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61HYe0mzhIL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹2,000", text: "with coupon" },
      rating: 4.4,
      ratings_total: 2065,
      prices: [
        {
          symbol: "₹",
          value: 31490,
          currency: "INR",
          raw: "₹31,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 42990,
          currency: "INR",
          raw: "₹42,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 31490,
        currency: "INR",
        raw: "₹31,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 9,
      title:
        "Panasonic 6 Kg 5 Star Fully-Automatic Top Loading Washing Machine (NA-F60LF1HRB, Grey)",
      asin: "B08B9756FB",
      link: "https://www.amazon.in/Panasonic-Fully-Automatic-Loading-Washing-NA-F60LF1HRB/dp/B08B9756FB/ref=sr_1_25?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-25",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61E5gS4YBIL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.4,
      ratings_total: 4316,
      prices: [
        {
          symbol: "₹",
          value: 13490,
          currency: "INR",
          raw: "₹13,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20000,
          currency: "INR",
          raw: "₹20,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13490,
        currency: "INR",
        raw: "₹13,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 10,
      title:
        "Whirlpool 7.5 Kg 5 Star Royal Plus Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL PLUS 7.5, Grey, Hard Water Wash)",
      asin: "B082FJ9RS7",
      link: "https://www.amazon.in/Whirlpool-7-5-Fully-Automatic-ROYAL-PLUS/dp/B082FJ9RS7/ref=sr_1_26?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-26",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/71pVSyDJD9L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹1,000", text: "with coupon" },
      rating: 4.3,
      ratings_total: 3353,
      prices: [
        {
          symbol: "₹",
          value: 17690,
          currency: "INR",
          raw: "₹17,690",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20300,
          currency: "INR",
          raw: "₹20,300",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 17690,
        currency: "INR",
        raw: "₹17,690",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 11,
      title:
        "Samsung 8 Kg 5 Star Inverter, Hygiene Steam Fully-Automatic Front Loading Washing Machine (WW80T4040CE1TL, White)",
      asin: "B09KGW3NWL",
      link: "https://www.amazon.in/sspa/click?ie=UTF8&spc=MTo4ODkwNjIwODA4MDU2ODMzOjE2NjQzNDk4MTg6c3BfbXRmOjIwMDU5NzczNjM3Njk4OjowOjo&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&url=%2FSamsung-Inverter-Hygiene-Fully-Automatic-WW80T4040CE1TL%2Fdp%2FB09KGW3NWL%2Fref%3Dsr_1_27_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664349818%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-27-spons%26psc%3D1",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61Qt1PoCbrL._AC_UY218_.jpg",
      coupon: { badge_text: "Save ₹1,500", text: "with coupon" },
      rating: 4.4,
      ratings_total: 169,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 31490,
          currency: "INR",
          raw: "₹31,490",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 45000,
          currency: "INR",
          raw: "₹45,000",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 31490,
        currency: "INR",
        raw: "₹31,490",
        name: "Primary",
        is_primary: true,
      },
    },

    {
      position: 13,
      title:
        "iFFALCON 7 Kg Fully-Automatic Front Load Washing Machine with In-built Heater (FWF70-G123061A03, White, Honey Comb Crystal Drum, 16 Wash Program)",
      asin: "B09F6K9YZ4",
      link: "https://www.amazon.in/IFFALCON-Fully-Automatic-Washing-Machine-FWF70-G123061A03/dp/B09F6K9YZ4/ref=sr_1_29?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-29",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/611BP5J3jUL._AC_UY218_.jpg",
      rating: 3.7,
      ratings_total: 81,
      prices: [
        {
          symbol: "₹",
          value: 16990,
          currency: "INR",
          raw: "₹16,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 39990,
          currency: "INR",
          raw: "₹39,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 16990,
        currency: "INR",
        raw: "₹16,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 14,
      title:
        "LG 8.0 Kg Inverter Fully-Automatic Top Loading Washing Machine (T80SJSF1Z, Middle Free Silver)",
      asin: "B08C33ZG82",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T80SJSF1Z/dp/B08C33ZG82/ref=sr_1_30?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-30",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/612ga-KT5zL._AC_UY218_.jpg",
      rating: 4.5,
      ratings_total: 643,
      prices: [
        {
          symbol: "₹",
          value: 22599,
          currency: "INR",
          raw: "₹22,599",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 37990,
          currency: "INR",
          raw: "₹37,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 22599,
        currency: "INR",
        raw: "₹22,599",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 15,
      title:
        "LG 7 kg Inverter Fully-Automatic Top Loading Washing Machine (T70SPSF2Z, Middle Free Silver)",
      asin: "B08DH9PKJ2",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SPSF2Z/dp/B08DH9PKJ2/ref=sr_1_31?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-31",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61i8y0xbbpS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.4,
      ratings_total: 1145,
      prices: [
        {
          symbol: "₹",
          value: 19490,
          currency: "INR",
          raw: "₹19,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 29990,
          currency: "INR",
          raw: "₹29,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 19490,
        currency: "INR",
        raw: "₹19,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 16,
      title:
        "Godrej 6.5 Kg 5 Star Fully-Automatic Top Loading Washing Machine (WTEON 650 AD 5.0 ROGR, Grey, Acu Wash Drum)",
      asin: "B08CW1ZNG2",
      link: "https://www.amazon.in/Godrej-Fully-Automatic-WTEON-650-ROGR/dp/B08CW1ZNG2/ref=sr_1_32?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-32",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/711QU6ggL-L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.3,
      ratings_total: 508,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 17500,
          currency: "INR",
          raw: "₹17,500",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 17,
      title:
        "Samsung 6.0 Kg Inverter 5 star Fully-Automatic Front Loading Washing Machine (WW60R20GLSS/TL, Silver, Hygiene steam)",
      asin: "B08DDTG2XX",
      link: "https://www.amazon.in/Samsung-Inverter-Fully-Automatic-WW60R20GLSS-TL/dp/B08DDTG2XX/ref=sr_1_33?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-33",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/71m+C6830pL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹1,000", text: "with coupon" },
      rating: 4.4,
      ratings_total: 980,
      prices: [
        {
          symbol: "₹",
          value: 23490,
          currency: "INR",
          raw: "₹23,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 32190,
          currency: "INR",
          raw: "₹32,190",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23490,
        currency: "INR",
        raw: "₹23,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 18,
      title:
        "LG 7 Kg 5 Star Inverter Fully-Automatic Top Loading Washing Machine (T70SJMB1Z, Middle Black, Jet Spray+)",
      asin: "B08DF1ZM2C",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SJMB1Z/dp/B08DF1ZM2C/ref=sr_1_34?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-34",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/71-smDZeF+L._AC_UY218_.jpg",
      rating: 4.4,
      ratings_total: 248,
      prices: [
        {
          symbol: "₹",
          value: 20150,
          currency: "INR",
          raw: "₹20,150",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 26990,
          currency: "INR",
          raw: "₹26,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 20150,
        currency: "INR",
        raw: "₹20,150",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 19,
      title:
        "LG 7.5 Kg 5 Star Semi-Automatic Top Loading Washing Machine (P7525SPAZ, Purple, Roller Jet Pulsator)",
      asin: "B09FLBJDNZ",
      link: "https://www.amazon.in/LG-Semi-Automatic-Loading-P7525SPAZ-Pulsator/dp/B09FLBJDNZ/ref=sr_1_35?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-35",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61enRbJd5eL._AC_UY218_.jpg",
      rating: 4.1,
      ratings_total: 154,
    },
    {
      position: 20,
      title:
        "Bosch 8 kg 5 Star 1400RPM INVERTER TOUCH CONTROL Fully Automatic Front Load with Heater Silver  (WAJ28262IN)",
      asin: "B08J3KFLBT",
      link: "https://www.amazon.in/Bosch-1400RPM-INVERTER-Automatic-WAJ28262IN/dp/B08J3KFLBT/ref=sr_1_36?keywords=Washing+Machines&qid=1664349818&qu=eyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%3D%3D&s=kitchen&sr=1-36",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61aB7OSEdfL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      is_prime: true,
      rating: 4.5,
      ratings_total: 1104,
      prices: [
        {
          symbol: "₹",
          value: 34990,
          currency: "INR",
          raw: "₹34,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 58490,
          currency: "INR",
          raw: "₹58,490",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 34990,
        currency: "INR",
        raw: "₹34,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 21,
      title:
        "Samsung 8 Kg 5 Star AI Control & Wi-Fi, Fully-Automatic Front Loading Washing Machine (WW80T504DAB1TL, Black Caviar, Digital Inverter)",
      asin: "B09KGVKZXS",
      link: "https://www.amazon.in/sspa/click?ie=UTF8&spc=MTo4ODkwNjIwODA4MDU2ODMzOjE2NjQzNDk4MTg6c3BfYnRmOjIwMDU5NzczNjM3Nzk4OjowOjo&sp_csd=d2lkZ2V0TmFtZT1zcF9idGY&url=%2FSamsung-Control-Fully-Automatic-WW80T504DAB1TL-Inverter%2Fdp%2FB09KGVKZXS%2Fref%3Dsr_1_37_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664349818%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-37-spons%26psc%3D1",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/61sIXFuim6L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹1,500", text: "with coupon" },
      rating: 4.4,
      ratings_total: 149,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 35990,
          currency: "INR",
          raw: "₹35,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 51800,
          currency: "INR",
          raw: "₹51,800",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 35990,
        currency: "INR",
        raw: "₹35,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 22,
      title:
        "Haier HWM65-AE 6.5Kg Top Load Fully-Automatic Washing Machine, Moonlight Grey",
      asin: "B08ZJJZDMP",
      link: "https://www.amazon.in/sspa/click?ie=UTF8&spc=MTo4ODkwNjIwODA4MDU2ODMzOjE2NjQzNDk4MTg6c3BfYnRmOjIwMDc1MDE1MDkzMjAzOjowOjo&sp_csd=d2lkZ2V0TmFtZT1zcF9idGY&url=%2FHaier-Automatic-Washing-HWM65-AE-Moonlight%2Fdp%2FB08ZJJZDMP%2Fref%3Dsr_1_38_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664349818%26qu%3DeyJxc2MiOiI2LjI3IiwicXNhIjoiNS45NyIsInFzcCI6IjQuNTEifQ%253D%253D%26s%3Dkitchen%26sr%3D1-38-spons%26psc%3D1",
      categories: [{ name: "Washing Machines & Dryers", id: "1380369031" }],
      image: "https://m.media-amazon.com/images/I/41+ZO1DpncL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: { badge_text: "Save ₹750", text: "with coupon" },
      rating: 4.3,
      ratings_total: 223,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19990,
          currency: "INR",
          raw: "₹19,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
  ],
  pagination: {
    total_results: 1000,
    current_page: 2,
    next_page_link:
      "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031&page=3&qid=1664349818&ref=sr_pg_3",
    total_pages: 102,
  },
  refinements: {
    delivery: [
      {
        name: "Get It by Tomorrow",
        value: "n:1380369031||p_90/6741118031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_90%3A6741118031&dc&qid=1664349818&rnid=6741116031&ref=sr_nr_p_90_1&ds=v1%3AhcPfL%2BN2c%2F8LaK8IMCwEtI1IFt3DEl3heZltDakxuOA",
        refinement_display_name: "Delivery",
      },
      {
        name: "Get It in 2 Days",
        value: "n:1380369031||p_90/20912642031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_90%3A20912642031&dc&qid=1664349818&rnid=6741116031&ref=sr_nr_p_90_2&ds=v1%3A2Z88Bo%2FJvC8fpwRpr5u5u%2FDqSO80zQ3frJJQP0OWwkw",
        refinement_display_name: "Delivery",
      },
    ],
    departments: [
      {
        name: "Home & Kitchen",
        value: "n/976442031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031&dc&qid=1664349818&ref=sr_ex_n_1&ds=v1%3AycRYF5VCoYrg8etGWRbDZ3szwKEugcXB8W0fesib1uE",
        refinement_display_name: "Departments",
      },
      {
        name: "Large Appliances",
        value: "n/1380263031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031&dc&qid=1664349818&ref=sr_ex_n_1&ds=v1%3A91N85gpw0e3rVGd2%2B%2F1w255NI%2Bu3YOziRNP8U9zaHwA",
        refinement_display_name: "Departments",
      },
      {
        name: "Washing Machines & Dryers",
        value: "n/1380369031",
        refinement_display_name: "Departments",
      },
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AIeSkJs4Jd0OIMtHNr74AUpJrK2eXSlgLoRX9YBdizq0",
        refinement_display_name: "Departments",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_2&ds=v1%3AGf49ok50WuMt1XdWdxFj%2FD7K7jdM4OUmjPXYvBWwyO8",
        refinement_display_name: "Departments",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AumtbRgFN%2F6sWFBGUQlUJh2UZsLc5U7%2Fm7CXd1OfP0rg",
        refinement_display_name: "Departments",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_4&ds=v1%3AASIdOjkMpw06ngF7owMjTsvxbKxr0cKx6qZRvIEXU%2FQ",
        refinement_display_name: "Departments",
      },
    ],
    child_categories: [
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AIeSkJs4Jd0OIMtHNr74AUpJrK2eXSlgLoRX9YBdizq0",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_2&ds=v1%3AGf49ok50WuMt1XdWdxFj%2FD7K7jdM4OUmjPXYvBWwyO8",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AumtbRgFN%2F6sWFBGUQlUJh2UZsLc5U7%2Fm7CXd1OfP0rg",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664349818&rnid=976442031&ref=sr_nr_n_4&ds=v1%3AASIdOjkMpw06ngF7owMjTsvxbKxr0cKx6qZRvIEXU%2FQ",
        refinement_display_name: "Child Categories",
      },
    ],
    reviews: [
      {
        name: "4 Stars & Up",
        value: "n:1380369031||p_72/1318476031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318476031&dc&qid=1664349818&rnid=1318475031&ref=sr_nr_p_72_1&ds=v1%3Au3lmh1%2FzuqyYtzg3iObVZY5v%2BTUpKLBSIzWA0Ln4Y08",
        refinement_display_name: "Reviews",
      },
      {
        name: "3 Stars & Up",
        value: "n:1380369031||p_72/1318477031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318477031&dc&qid=1664349818&rnid=1318475031&ref=sr_nr_p_72_2&ds=v1%3A38YpjfuvpZ5GnJcaXP%2FHQN7WGSiZw7KkvuFgZPcbGLg",
        refinement_display_name: "Reviews",
      },
      {
        name: "2 Stars & Up",
        value: "n:1380369031||p_72/1318478031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318478031&dc&qid=1664349818&rnid=1318475031&ref=sr_nr_p_72_3&ds=v1%3ApMpsqXMjzCu%2BgQO6ajpRJgAuyiXwKRGRETyhBQzrZFk",
        refinement_display_name: "Reviews",
      },
      {
        name: "1 Star & Up",
        value: "n:1380369031||p_72/1318479031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318479031&dc&qid=1664349818&rnid=1318475031&ref=sr_nr_p_72_4&ds=v1%3AquAZ7D4qmIS580P5fdY%2FMvTZz6bDEutNAQaW1xumKvI",
        refinement_display_name: "Reviews",
      },
    ],
    price: [
      {
        name: "Under ₹10,000",
        value: "n:1380369031||p_36/5271572031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271572031&dc&qid=1664349818&rnid=5271547031&ref=sr_nr_p_36_1&ds=v1%3AEA64VOUcIQAxNHTbiWaMWiM462yGTFVe%2BGH25INmXho",
        refinement_display_name: "Price",
      },
      {
        name: "₹10,000 - ₹15,000",
        value: "n:1380369031||p_36/5271573031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271573031&dc&qid=1664349818&rnid=5271547031&ref=sr_nr_p_36_2&ds=v1%3Acz%2Bk8Lbz9FsoOxlTkRsNxQTrdIvf4WARN7lmlWw0zIA",
        refinement_display_name: "Price",
      },
      {
        name: "₹15,000 - ₹20,000",
        value: "n:1380369031||p_36/5271574031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271574031&dc&qid=1664349818&rnid=5271547031&ref=sr_nr_p_36_3&ds=v1%3AeZO51wDy2yDx32i9l9OdcCX19%2FK12omxsIT5DOU1D3M",
        refinement_display_name: "Price",
      },
      {
        name: "₹20,000 - ₹30,000",
        value: "n:1380369031||p_36/5271575031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271575031&dc&qid=1664349818&rnid=5271547031&ref=sr_nr_p_36_4&ds=v1%3AVR2YXOY%2BFJ5ZFAzfn0c8mpwAaJO75rFZcSoNt6ln5Es",
        refinement_display_name: "Price",
      },
      {
        name: "Over ₹30,000",
        value: "n:1380369031||p_36/5271576031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271576031&dc&qid=1664349818&rnid=5271547031&ref=sr_nr_p_36_5&ds=v1%3A%2F33zmjD0XQg1hcVbmRbqTzzGG8iKNQeLKi4aIM%2BV%2FPw",
        refinement_display_name: "Price",
      },
      {
        name: "₹        ₹            Go",
        value: "p_36/price-range",
        refinement_display_name: "Price",
      },
    ],
    brand: [
      {
        name: "Samsung",
        value: "n:1380369031||p_89/Samsung",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ASamsung&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_1&ds=v1%3A9%2BTqn7e2oFM6Msflzw%2B8eqipFYm1E6Jmm6mJOkGJW70",
        refinement_display_name: "Brand",
      },
      {
        name: "LG",
        value: "n:1380369031||p_89/LG",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ALG&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_2&ds=v1%3A3uYog%2FJNvrKD6F9bqQTJZFoBRvdtrW%2FmG7DQfLH8iZQ",
        refinement_display_name: "Brand",
      },
      {
        name: "IFB",
        value: "n:1380369031||p_89/IFB",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AIFB&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_3&ds=v1%3Ay8FqOl0HigviKIB8zojcV6CDmVfOZuyKWW7fF1T4xiw",
        refinement_display_name: "Brand",
      },
      {
        name: "Whirlpool",
        value: "n:1380369031||p_89/Whirlpool",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhirlpool&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_4&ds=v1%3AScIyvgzHzPRd3v6OgTuYqjkJbX3L0e8%2Bow4iyK1B9XY",
        refinement_display_name: "Brand",
      },
      {
        name: "Godrej",
        value: "n:1380369031||p_89/Godrej",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGodrej&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_5&ds=v1%3ARENyzMUBca02W%2BgZWHC%2FjLX9ar7Rs8jgO3UuSrY3CsU",
        refinement_display_name: "Brand",
      },
      {
        name: "Bosch",
        value: "n:1380369031||p_89/Bosch",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ABosch&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_6&ds=v1%3ALzobpEAOaQLvadQXaQsE%2BwkOLhvL0BWX3RT2q%2FDz6Is",
        refinement_display_name: "Brand",
      },
      {
        name: "Panasonic",
        value: "n:1380369031||p_89/Panasonic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3APanasonic&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_7&ds=v1%3AEWY8xSPiVcXtZqG3BpBsyXyrcOJHvqT5QZ8UZx1wh7U",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMAHaierWhite Westinghouse (Trademark By Electrolux)GenericElectroSkyMARJI&ANUVRUTTI",
        value: "n:1380369031||undefined",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3ADH7K3Ig4fMy49O3tv%2BS%2FcfdwgU%2BnmsXUqUtS6OOveuU",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMA",
        value: "n:1380369031||p_89/CROMA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3ADH7K3Ig4fMy49O3tv%2BS%2FcfdwgU%2BnmsXUqUtS6OOveuU",
        refinement_display_name: "Brand",
      },
      {
        name: "Haier",
        value: "n:1380369031||p_89/Haier",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AHaier&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_9&ds=v1%3A%2Br4qCN0TGh34Tqwq8jYPNW3Qg7rbw1Ux%2FJc%2FLr4FCTU",
        refinement_display_name: "Brand",
      },
      {
        name: "White Westinghouse (Trademark By Electrolux)",
        value:
          "n:1380369031||p_89/White Westinghouse (Trademark By Electrolux)",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhite+Westinghouse+%28Trademark+By+Electrolux%29&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_10&ds=v1%3A1YTtNsCZa9LHatrrd3WR1fPiaazlrewLkNrrjxf27%2Fw",
        refinement_display_name: "Brand",
      },
      {
        name: "Generic",
        value: "n:1380369031||p_89/Generic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGeneric&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_11&ds=v1%3AaCTW0BqO8lWu0F6%2BTLatii8j37%2FcUJQmR9xQkA1aKLM",
        refinement_display_name: "Brand",
      },
      {
        name: "ElectroSky",
        value: "n:1380369031||p_89/ElectroSky",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AElectroSky&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_12&ds=v1%3AXqJwLKCGoRTedUIQRLw8uRonVtapu%2F9UtYy1hP7F1Ww",
        refinement_display_name: "Brand",
      },
      {
        name: "MARJI&ANUVRUTTI",
        value: "n:1380369031||p_89/MARJI&ANUVRUTTI",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AMARJI%26ANUVRUTTI&dc&qid=1664349818&rnid=3837712031&ref=sr_nr_p_89_13&ds=v1%3Apo96QUToRbM1RPFNO8Ha70QfnX9r6aSI71KCQdO8aqw",
        refinement_display_name: "Brand",
      },
    ],
  },
  ad_blocks: [
    {
      campaign_id: "9581059940902",
      brand_logo:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/03331d03-d120-4680-9e1a-fd12b8b391e9._CR0,0,400,400_AC_SX130_SY60_QL70_.jpg",
      background_image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/8872ceb0-7d14-499e-b322-dbe0b35ed4f6._CR0,0,1200,628_SX507_QL70_.jpg",
      advertiser_id: "8339266330602",
      ad_id: "8339266330602",
      link: "https://aax-eu.amazon.in/x/c/RJ-6oM3ZZoFzCIJG21knLW4AAAGDgvrOMgMAAAH2AQBOL0EgICAgICAgICAgICBOL0EgICAgICAgICAgICAachI_/https://www.amazon.in/stores/page/CD7D1712-2B7B-4231-8063-DD0803A40E1C/?_encoding=UTF8&store_ref=SB_A000999012K2JL0X34XPP&pd_rd_plhdr=t&aaxitk=83ae09f23ff9465c86efba78a3b7c57b&hsa_cr_id=9223335210002&lp_asins=B0BBTVDYCC&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_bkgd&pd_rd_w=J0bsS&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=196C36CSYGWE9KEDDYM1&pd_rd_wg=NnZf1&pd_rd_r=c1a46551-d25c-48d9-b5c0-eb5ef8f16dde",
      title: "Electrolux | Washing Machine |Ecoinverter Motor",
      store_link:
        "https://www.amazon.in/stores/page/CD7D1712-2B7B-4231-8063-DD0803A40E1C/?_encoding=UTF8&store_ref=SB_A000999012K2JL0X34XPP&pd_rd_plhdr=t&aaxitk=83ae09f23ff9465c86efba78a3b7c57b&hsa_cr_id=9223335210002&lp_asins=B0BBTVDYCC&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_cta&pd_rd_w=J0bsS&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=196C36CSYGWE9KEDDYM1&pd_rd_wg=NnZf1&pd_rd_r=c1a46551-d25c-48d9-b5c0-eb5ef8f16dde",
      store_id: "CD7D1712-2B7B-4231-8063-DD0803A40E1C",
      store_name: "Electrolux India MDA",
      products: [
        {
          asin: "B0BBTVDYCC",
          link: "https://www.amazon.in/dp/B0BBTVDYCC",
          image:
            "https://m.media-amazon.com/images/I/61DIv64sFeL._AC_SR160,134_QL70_.jpg",
          is_prime: true,
          price: {
            symbol: "₹",
            value: 32990,
            currency: "INR",
            raw: "₹32,990.00",
          },
        },
      ],
    },
  ],
};

export const WashingMachinesIndia_p3 = {
  request_info: {
    success: true,
    credits_used: 46,
    credits_used_this_request: 1,
    credits_remaining: 454,
    credits_reset_at: "2022-10-21T17:19:14.000Z",
  },
  request_parameters: {
    type: "search",
    search_term: "Washing Machines",
    category_id: "1380369031",
    page: "2",
    amazon_domain: "amazon.in",
  },
  request_metadata: {
    created_at: "2022-10-01T15:43:42.426Z",
    processed_at: "2022-10-01T15:43:45.066Z",
    total_time_taken: 2.64,
    amazon_url:
      "https://www.amazon.in/s/?k=Washing+Machines&ref=nb_sb_noss_2&node=1380369031&page=2",
  },
  search_results: [
    {
      position: 1,
      title:
        "Samsung 6.0 Kg Inverter 5 Star Fully-Automatic Front Loading Washing Machine (WW60R20GLMA/TL, White, Hygiene Steam)",
      asin: "B084LGQKD7",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_next_kitchen_sr_pg2_1?ie=UTF8&adId=A0316105FF39BVZPT0HN&url=%2FSamsung-Fully-Automatic-Loading-WW60R20GLMA-TL%2Fdp%2FB084LGQKD7%2Fref%3Dsr_1_17_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-17-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_atf_next",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71i8f3-DsuL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 4612,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 21990,
          currency: "INR",
          raw: "₹21,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 26900,
          currency: "INR",
          raw: "₹26,900",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 21990,
        currency: "INR",
        raw: "₹21,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 2,
      title:
        "Samsung 6.0 Kg Inverter 5 star Fully-Automatic Front Loading Washing Machine (WW60R20GLSS/TL, Silver, Hygiene steam)",
      asin: "B08DDTG2XX",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_next_kitchen_sr_pg2_1?ie=UTF8&adId=A0315949KJCBBSI62XME&url=%2FSamsung-Inverter-Fully-Automatic-WW60R20GLSS-TL%2Fdp%2FB08DDTG2XX%2Fref%3Dsr_1_18_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-18-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_atf_next",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71m+C6830pL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 984,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 23490,
          currency: "INR",
          raw: "₹23,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 32190,
          currency: "INR",
          raw: "₹32,190",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23490,
        currency: "INR",
        raw: "₹23,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 3,
      title:
        "IFB 6 Kg 5 Star Front Load Washing Machine 2X Power Dual Steam (DIVA AQUA BXS 6008, White & Black, Active Color Protection, Hard Water Wash)",
      asin: "B09ZBJRGWH",
      link: "https://www.amazon.in/IFB-DIVA-AQUA-BXS-6008/dp/B09ZBJRGWH/ref=sr_1_19?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-19",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61Gbp0b1lmL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.2,
      ratings_total: 1847,
      prices: [
        {
          symbol: "₹",
          value: 21490,
          currency: "INR",
          raw: "₹21,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 27090,
          currency: "INR",
          raw: "₹27,090",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 21490,
        currency: "INR",
        raw: "₹21,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 4,
      title:
        "Samsung 6.0 Kg 5 Star Semi-Automatic Top Loading Washing Machine (WT60R2000LL/TL, Light Grey, Center jet technology)",
      asin: "B08FMN5ZQM",
      link: "https://www.amazon.in/Samsung-Semi-Automatic-WT60R2000LL-TL-technology/dp/B08FMN5ZQM/ref=sr_1_20?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-20",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61ySBQQ9GlL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.2,
      ratings_total: 1250,
      prices: [
        {
          symbol: "₹",
          value: 8990,
          currency: "INR",
          raw: "₹8,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 9900,
          currency: "INR",
          raw: "₹9,900",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8990,
        currency: "INR",
        raw: "₹8,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 5,
      title:
        "Samsung 7.0 Kg 5 Star Semi-Automatic Top Loading Washing Machine (WT70M3000UU/TL, LIGHT GRAY, Double Storm Pulsator)",
      asin: "B09T6NH99B",
      link: "https://www.amazon.in/Samsung-Semi-Automatic-WT70M3000UU-TL-Pulsator/dp/B09T6NH99B/ref=sr_1_21?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-21",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71StafL7njL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 304,
      prices: [
        {
          symbol: "₹",
          value: 10490,
          currency: "INR",
          raw: "₹10,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 13500,
          currency: "INR",
          raw: "₹13,500",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10490,
        currency: "INR",
        raw: "₹10,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 6,
      title:
        "LG 6.5 Kg 5 Star Smart Inverter Fully-Automatic Top Loading Washing Machine (T65SKSF4Z, Middle Free Silver)",
      asin: "B084LF24Z8",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T65SKSF4Z/dp/B084LF24Z8/ref=sr_1_22?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-22",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71sgMM9ZQ9L._AC_UY218_.jpg",
      amazons_choice: {
        link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T65SKSF4Z/dp/B084LF24Z8/ref=ice_ac_b_dpb?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-22",
        keywords: '"Washing Machines"',
      },
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 7048,
      prices: [
        {
          symbol: "₹",
          value: 15990,
          currency: "INR",
          raw: "₹15,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 21490,
          currency: "INR",
          raw: "₹21,490",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 15990,
        currency: "INR",
        raw: "₹15,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 7,
      title:
        "Panasonic 6.5 kg 5 Star Semi-Automatic Top Loading Washing Machine (NA-W65E5ARB, Blue, Powerful Motor)",
      asin: "B08B98715P",
      link: "https://www.amazon.in/Panasonic-Semi-Automatic-Loading-NA-W65E5ARB-Powerful/dp/B08B98715P/ref=sr_1_23?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-23",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61Lt1nXUF2L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 2211,
      prices: [
        {
          symbol: "₹",
          value: 8990,
          currency: "INR",
          raw: "₹8,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 11000,
          currency: "INR",
          raw: "₹11,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8990,
        currency: "INR",
        raw: "₹8,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 8,
      title:
        "Croma 6.5 kg 5 Star Semi Automatic Top Load Washing Machine with 2 Years Comprehensive Warranty (CRLW065SMF202351, Blue)",
      asin: "B0B6PZJ5XH",
      link: "https://www.amazon.in/Automatic-Washing-Comprehensive-Warranty-CRLW065SMF202351/dp/B0B6PZJ5XH/ref=sr_1_24?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-24",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/614z2VIgIcL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4,
      ratings_total: 514,
      prices: [
        {
          symbol: "₹",
          value: 6990,
          currency: "INR",
          raw: "₹6,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 12000,
          currency: "INR",
          raw: "₹12,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 6990,
        currency: "INR",
        raw: "₹6,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 9,
      title:
        "Samsung 8.5 kg 5 Star Semi-Automatic Top Loading Washing Machine (WT85B4200GG/TL, Light Grey, Air turbo drying)",
      asin: "B0B7JTWLMJ",
      link: "https://www.amazon.in/Samsung-Semi-Automatic-Loading-WT85B4200GG-TL/dp/B0B7JTWLMJ/ref=sr_1_25?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-25",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71UT1TNUtBL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      prices: [
        {
          symbol: "₹",
          value: 13490,
          currency: "INR",
          raw: "₹13,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 17700,
          currency: "INR",
          raw: "₹17,700",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13490,
        currency: "INR",
        raw: "₹13,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 10,
      title:
        "Panasonic 6 Kg 5 Star Fully-Automatic Top Loading Washing Machine (NA-F60LF1HRB, Grey)",
      asin: "B08B9756FB",
      link: "https://www.amazon.in/Panasonic-Fully-Automatic-Loading-Washing-NA-F60LF1HRB/dp/B08B9756FB/ref=sr_1_26?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-26",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61E5gS4YBIL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.4,
      ratings_total: 4349,
      prices: [
        {
          symbol: "₹",
          value: 13490,
          currency: "INR",
          raw: "₹13,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20000,
          currency: "INR",
          raw: "₹20,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13490,
        currency: "INR",
        raw: "₹13,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 11,
      title:
        "Amazon Basics 6.5 Kg Fully Automatic Top Loading Washing Machine (Grey, LED panel, Memory Feature)",
      asin: "B07V3SGB2V",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg2_1?ie=UTF8&adId=A021143519P42OKXJSTBH&url=%2FAmazonBasics-Automatic-Loading-Washing-machine_Silver_Hisense2%2Fdp%2FB07V3SGB2V%2Fref%3Dsr_1_27_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-27-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 141,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 10990,
          currency: "INR",
          raw: "₹10,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19999,
          currency: "INR",
          raw: "₹19,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10990,
        currency: "INR",
        raw: "₹10,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 12,
      title:
        "AmazonBasics 7 kg Semi Automatic Washing Machine (with Heavy wash function, White/Blue color)",
      asin: "B07V3SGB3G",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg2_1?ie=UTF8&adId=A0822194218S1KYUUIIQ8&url=%2FAmazonBasics-Semi-Automatic-Washing-Machine%2Fdp%2FB07V3SGB3G%2Fref%3Dsr_1_28_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-28-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71+JK-QC68L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4,
      ratings_total: 715,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 8490,
          currency: "INR",
          raw: "₹8,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16999,
          currency: "INR",
          raw: "₹16,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8490,
        currency: "INR",
        raw: "₹8,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 13,
      title:
        "Whirlpool 7.5 Kg 5 Star Royal Plus Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL PLUS 7.5, Grey, Hard Water Wash)",
      asin: "B082FJ9RS7",
      link: "https://www.amazon.in/Whirlpool-7-5-Fully-Automatic-ROYAL-PLUS/dp/B082FJ9RS7/ref=sr_1_29?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-29",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71pVSyDJD9L._AC_UY218_.jpg",
      rating: 4.3,
      ratings_total: 3363,
      prices: [
        {
          symbol: "₹",
          value: 17990,
          currency: "INR",
          raw: "₹17,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20300,
          currency: "INR",
          raw: "₹20,300",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 17990,
        currency: "INR",
        raw: "₹17,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 14,
      title:
        "LG 6 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine (FHM1006SDW, White, 6 Motion Direct Drive)",
      asin: "B09LLMH3CQ",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-FHM1006SDW/dp/B09LLMH3CQ/ref=sr_1_30?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-30",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/7108LHrN2eL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 2494,
      prices: [
        {
          symbol: "₹",
          value: 23990,
          currency: "INR",
          raw: "₹23,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 33990,
          currency: "INR",
          raw: "₹33,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23990,
        currency: "INR",
        raw: "₹23,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 15,
      title:
        "Bosch 6 kg 5 Star Inverter Fully Automatic Front Loading Washing Machine with In - built Heater (WLJ2016WIN, White )",
      asin: "B08PZBZD5M",
      link: "https://www.amazon.in/Bosch-Inverter-Automatic-Loading-Washing/dp/B08PZBZD5M/ref=sr_1_31?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-31",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61tUypW4kkL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.5,
      ratings_total: 1469,
      prices: [
        {
          symbol: "₹",
          value: 25990,
          currency: "INR",
          raw: "₹25,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 37790,
          currency: "INR",
          raw: "₹37,790",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 25990,
        currency: "INR",
        raw: "₹25,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 16,
      title:
        "LG 7.5 Kg 5 Star Smart Inverter Fully-Automatic Top Load Washing Machine (T75SKSF1Z, Middle Free Silver, TurboDrum | Smart Motion)",
      asin: "B08ZKL5PK2",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T75SKSF1Z/dp/B08ZKL5PK2/ref=sr_1_32?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-32",
      availability: {
        raw: "Only 2 left in stock.",
      },
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61BQnTgt44L._AC_UY218_.jpg",
      rating: 4.2,
      ratings_total: 257,
      prices: [
        {
          symbol: "₹",
          value: 18890,
          currency: "INR",
          raw: "₹18,890",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 24990,
          currency: "INR",
          raw: "₹24,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 18890,
        currency: "INR",
        raw: "₹18,890",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 17,
      title: "Onida 6.5 kg Washer Only (WS65WLPT1LR Liliput, Lava Red)",
      asin: "B00ZOPZSSU",
      link: "https://www.amazon.in/Onida-Washer-Only-WS65WLPT1LR-Liliput/dp/B00ZOPZSSU/ref=sr_1_33?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-33",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51XrJqjb0QL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.2,
      ratings_total: 4652,
      prices: [
        {
          symbol: "₹",
          value: 5400,
          currency: "INR",
          raw: "₹5,400",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 5800,
          currency: "INR",
          raw: "₹5,800",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 5400,
        currency: "INR",
        raw: "₹5,400",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 18,
      title:
        "LG 7 kg Inverter Fully-Automatic Top Loading Washing Machine (T70SPSF2Z, Middle Free Silver)",
      asin: "B08DH9PKJ2",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SPSF2Z/dp/B08DH9PKJ2/ref=sr_1_34?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-34",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61i8y0xbbpS._AC_UY218_.jpg",
      rating: 4.4,
      ratings_total: 1150,
      prices: [
        {
          symbol: "₹",
          value: 19315,
          currency: "INR",
          raw: "₹19,315",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 25990,
          currency: "INR",
          raw: "₹25,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 19315,
        currency: "INR",
        raw: "₹19,315",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 19,
      title:
        "LG 8 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine with Inbuilt heater (FHV1408Z2M, Middle Black, AI DD Technology & Steam for Hygiene)",
      asin: "B0BBMX3NNF",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-FHV1408Z2M-Technology/dp/B0BBMX3NNF/ref=sr_1_35?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-35",
      availability: {
        raw: "Only 2 left in stock.",
      },
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71jWsAfEcCL._AC_UY218_.jpg",
      is_prime: true,
      prices: [
        {
          symbol: "₹",
          value: 36849,
          currency: "INR",
          raw: "₹36,849",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 52990,
          currency: "INR",
          raw: "₹52,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 36849,
        currency: "INR",
        raw: "₹36,849",
        name: "Primary",
        is_primary: true,
      },
      delivery: {
        tagline: "Get it by Wednesday, October 5",
      },
    },
    {
      position: 20,
      title:
        "LG 7 Kg 5 Star Inverter Fully-Automatic Top Loading Washing Machine (T70SJMB1Z, Middle Black, Jet Spray+)",
      asin: "B08DF1ZM2C",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SJMB1Z/dp/B08DF1ZM2C/ref=sr_1_36?keywords=Washing+Machines&qid=1664639023&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-36",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71-smDZeF+L._AC_UY218_.jpg",
      rating: 4.5,
      ratings_total: 252,
      prices: [
        {
          symbol: "₹",
          value: 20050,
          currency: "INR",
          raw: "₹20,050",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 26990,
          currency: "INR",
          raw: "₹26,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 20050,
        currency: "INR",
        raw: "₹20,050",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 21,
      title:
        "Samsung 8 Kg 5 Star Inverter Fully-Automatic Top Loading Washing Machine (WA80T4560BM/TL, Magnolia Plum, Activ Wash+ Technology)",
      asin: "B08WX2LSP4",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg2_1?ie=UTF8&adId=A090224039CFFQWYQ4K2D&url=%2FSamsung-Fully-Automatic-WA80T4560BM-TL-Technology%2Fdp%2FB08WX2LSP4%2Fref%3Dsr_1_37_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-37-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/817DWNtKC5L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 578,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 19990,
          currency: "INR",
          raw: "₹19,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 28500,
          currency: "INR",
          raw: "₹28,500",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 19990,
        currency: "INR",
        raw: "₹19,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 22,
      title:
        "Haier HWM70-AE 7Kg Top Loading Washing Machine (Moonlight Grey, Quick Wash)",
      asin: "B08ZJHJ5DJ",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg2_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_38_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-38-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/41VYzY29VaL._AC_UY218_.jpg",
      rating: 4,
      ratings_total: 126,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 14990,
          currency: "INR",
          raw: "₹14,990",
          asin: "B08ZJHJ5DJ",
          link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg2_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_38_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-38-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_btf",
        },
      ],
      price: {
        symbol: "₹",
        value: 14990,
        currency: "INR",
        raw: "₹14,990",
        asin: "B08ZJHJ5DJ",
        link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg2_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_38_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639023%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-38-spons%26psc%3D1&qualifier=1664639023&id=42225566281862&widgetName=sp_btf",
      },
    },
  ],
  related_brands: [
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/f3cd599c-de0b-4f0b-bf22-aafe05dc6a77._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/e283ea86-49cf-49ad-a39b-adb4da546fba._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/733fba1c-9a63-4b49-ba75-91b28b0530c7._CR0,0,1200,628_SX460_QL70_.jpg",
    },
  ],
  pagination: {
    total_results: 1000,
    current_page: 2,
    next_page_link:
      "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031&page=3&qid=1664639023&ref=sr_pg_3",
    total_pages: 103,
  },
  refinements: {
    departments: [
      {
        name: "Home & Kitchen",
        value: "n/976442031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031&dc&qid=1664639023&ref=sr_ex_n_1&ds=v1%3Ash58M810lwZ%2B%2BQ77ixGcIEVEJY1sd9UNUT0AKk9dTjk",
        refinement_display_name: "Departments",
      },
      {
        name: "Large Appliances",
        value: "n/1380263031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031&dc&qid=1664639023&ref=sr_ex_n_1&ds=v1%3Amvj8NVuu9tZlK4ltG%2Fp1vtn0rb6AsCyj2wuamsgTBfY",
        refinement_display_name: "Departments",
      },
      {
        name: "Washing Machines & Dryers",
        value: "n/1380369031",
        refinement_display_name: "Departments",
      },
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AKD%2BuwZmUr9hJgiBUpri3h47nUItId3aHr6gyltiUx9c",
        refinement_display_name: "Departments",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_2&ds=v1%3Aco3LrKpxonLGktknK1SofkcVhrAP1dX3thpJJ7M%2FZa4",
        refinement_display_name: "Departments",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AfF%2Fd831WdiV85y2PccxtfIYZ7TyvtVEpkdSD9ONgbjU",
        refinement_display_name: "Departments",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_4&ds=v1%3AA34uO74rq4hzVfSi53bsSNvZfk4wn%2FJq4IUfTVAOOTY",
        refinement_display_name: "Departments",
      },
    ],
    child_categories: [
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_1&ds=v1%3AKD%2BuwZmUr9hJgiBUpri3h47nUItId3aHr6gyltiUx9c",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_2&ds=v1%3Aco3LrKpxonLGktknK1SofkcVhrAP1dX3thpJJ7M%2FZa4",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AfF%2Fd831WdiV85y2PccxtfIYZ7TyvtVEpkdSD9ONgbjU",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664639023&rnid=976442031&ref=sr_nr_n_4&ds=v1%3AA34uO74rq4hzVfSi53bsSNvZfk4wn%2FJq4IUfTVAOOTY",
        refinement_display_name: "Child Categories",
      },
    ],
    reviews: [
      {
        name: "4 Stars & Up",
        value: "n:1380369031||p_72/1318476031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318476031&dc&qid=1664639023&rnid=1318475031&ref=sr_nr_p_72_1&ds=v1%3AjUfgdnyQpTF5%2F%2FfpgMXiZ782DzBoxbUpN4SUsK8CfOw",
        refinement_display_name: "Reviews",
      },
      {
        name: "3 Stars & Up",
        value: "n:1380369031||p_72/1318477031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318477031&dc&qid=1664639023&rnid=1318475031&ref=sr_nr_p_72_2&ds=v1%3Au8Ej2%2FROJ4KneU7hHOcIGXkpDer2UrJDzju0a43CV8o",
        refinement_display_name: "Reviews",
      },
      {
        name: "2 Stars & Up",
        value: "n:1380369031||p_72/1318478031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318478031&dc&qid=1664639023&rnid=1318475031&ref=sr_nr_p_72_3&ds=v1%3AjdvHFG%2F9RoLfxJzFF0Zb%2BAmUNgpxiKNTOCKT3UvGlBs",
        refinement_display_name: "Reviews",
      },
      {
        name: "1 Star & Up",
        value: "n:1380369031||p_72/1318479031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318479031&dc&qid=1664639023&rnid=1318475031&ref=sr_nr_p_72_4&ds=v1%3ACxReXDUNwBSx71%2FojLlvo3%2B84wzGrX7zRUtV8gh73rg",
        refinement_display_name: "Reviews",
      },
    ],
    price: [
      {
        name: "Under ₹10,000",
        value: "n:1380369031||p_36/5271572031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271572031&dc&qid=1664639023&rnid=5271547031&ref=sr_nr_p_36_1&ds=v1%3AerpEEmSy%2F%2BexP6Lmyzsfa6%2FWtQBiF8hvnDqvyfOhAyA",
        refinement_display_name: "Price",
      },
      {
        name: "₹10,000 - ₹15,000",
        value: "n:1380369031||p_36/5271573031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271573031&dc&qid=1664639023&rnid=5271547031&ref=sr_nr_p_36_2&ds=v1%3AaHAT2XW3a5NYltIBPQh5oJMFmBTqO1aAZOaVCIqzVpo",
        refinement_display_name: "Price",
      },
      {
        name: "₹15,000 - ₹20,000",
        value: "n:1380369031||p_36/5271574031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271574031&dc&qid=1664639023&rnid=5271547031&ref=sr_nr_p_36_3&ds=v1%3AlvI1nCFq47O%2BrC8WY3h2UQBmuFjWfSr%2FunnRdPGpVYA",
        refinement_display_name: "Price",
      },
      {
        name: "₹20,000 - ₹30,000",
        value: "n:1380369031||p_36/5271575031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271575031&dc&qid=1664639023&rnid=5271547031&ref=sr_nr_p_36_4&ds=v1%3ANJ7KtPdjRvtVGr05yU89DHYego65FL4mBjtZN%2B%2BKQPc",
        refinement_display_name: "Price",
      },
      {
        name: "Over ₹30,000",
        value: "n:1380369031||p_36/5271576031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271576031&dc&qid=1664639023&rnid=5271547031&ref=sr_nr_p_36_5&ds=v1%3AUNnx5RK0IvHU8Mos25pgWF8XF1n9zEcRgcMRU5oYxmQ",
        refinement_display_name: "Price",
      },
      {
        name: "₹        ₹            Go",
        value: "p_36/price-range",
        refinement_display_name: "Price",
      },
    ],
    brand: [
      {
        name: "Samsung",
        value: "n:1380369031||p_89/Samsung",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ASamsung&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_1&ds=v1%3AQPtu86ysfKBnSLjL%2Bu5L6yTADnucdAYZZZl2r3BxXeE",
        refinement_display_name: "Brand",
      },
      {
        name: "LG",
        value: "n:1380369031||p_89/LG",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ALG&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_2&ds=v1%3ACzkIhlwbBVvzEtFnue1hPWBpa2p0WX5RWcoq%2FqtEJnw",
        refinement_display_name: "Brand",
      },
      {
        name: "IFB",
        value: "n:1380369031||p_89/IFB",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AIFB&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_3&ds=v1%3AjzY0JG0mQPgyGD1Iqz1fSlBvyHraGRiCI6lvM%2FkUqZ4",
        refinement_display_name: "Brand",
      },
      {
        name: "Whirlpool",
        value: "n:1380369031||p_89/Whirlpool",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhirlpool&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_4&ds=v1%3AfPOSqJHEi0EfgT286%2BjWXAh9nFg%2BOYSZv9bgIioelBA",
        refinement_display_name: "Brand",
      },
      {
        name: "Godrej",
        value: "n:1380369031||p_89/Godrej",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGodrej&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_5&ds=v1%3AG0l%2BdQ39ztWqh1QVl61UgebI1OGp6C5M3Wwye%2F7NyAY",
        refinement_display_name: "Brand",
      },
      {
        name: "Bosch",
        value: "n:1380369031||p_89/Bosch",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ABosch&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_6&ds=v1%3Ava%2FKRPd%2Fb%2Fhof9SxORfq4SxlGSJgDSEFW%2B59i%2BHtpIk",
        refinement_display_name: "Brand",
      },
      {
        name: "Panasonic",
        value: "n:1380369031||p_89/Panasonic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3APanasonic&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_7&ds=v1%3AJRbBTTS1Z9ofRTUnYEJ5Jp5SuadWunWAplim3mZ%2FrZE",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMAAmazonBasicsLloydOnidaHaierDMRMideaTOSHIBAGenericELECTROLUX",
        value: "n:1380369031||undefined",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3APSmWOkfDPIPUfnluDWEa0YiHSiSagT99ruXMQA8bjEw",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMA",
        value: "n:1380369031||p_89/CROMA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3APSmWOkfDPIPUfnluDWEa0YiHSiSagT99ruXMQA8bjEw",
        refinement_display_name: "Brand",
      },
      {
        name: "AmazonBasics",
        value: "n:1380369031||p_89/AmazonBasics",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AAmazonBasics&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_9&ds=v1%3AIrFwGmUGYiQ0I43rw90bwzI3yASR7ccOZ14oA%2BSOXKk",
        refinement_display_name: "Brand",
      },
      {
        name: "Lloyd",
        value: "n:1380369031||p_89/Lloyd",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ALloyd&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_10&ds=v1%3AxeH%2FgpiW7NfDWDwjImZcUtA%2FTuxxKiynoG6WQ8EKLbI",
        refinement_display_name: "Brand",
      },
      {
        name: "Onida",
        value: "n:1380369031||p_89/Onida",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AOnida&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_11&ds=v1%3AEmwxtiaGOgABnDSm%2FSpmGj2HPI1i2RXOIwjntp6VmQw",
        refinement_display_name: "Brand",
      },
      {
        name: "Haier",
        value: "n:1380369031||p_89/Haier",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AHaier&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_12&ds=v1%3AxOhhHytnOArEPSR0TzdXY4d9CAtz7pzYJdleSU%2BhJz8",
        refinement_display_name: "Brand",
      },
      {
        name: "DMR",
        value: "n:1380369031||p_89/DMR",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ADMR&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_13&ds=v1%3A8OXVEsJoV3jQXzGU%2BtCga5ElnPQFwfshL7AJJYna80I",
        refinement_display_name: "Brand",
      },
      {
        name: "Midea",
        value: "n:1380369031||p_89/Midea",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AMidea&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_14&ds=v1%3Aipnc6VYB2x7zaW4%2Fom03m3HGJ5IsjgkfgRZUe9yuZz8",
        refinement_display_name: "Brand",
      },
      {
        name: "TOSHIBA",
        value: "n:1380369031||p_89/TOSHIBA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ATOSHIBA&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_15&ds=v1%3AeI9gx58nDmsboOJns0HTq2GXd1wLDef5mNQ9%2BJSpwNQ",
        refinement_display_name: "Brand",
      },
      {
        name: "Generic",
        value: "n:1380369031||p_89/Generic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGeneric&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_16&ds=v1%3AfejjcoNWuFme%2BqHdtFQ1m17N0TGFODpbOpj%2BfKA94WQ",
        refinement_display_name: "Brand",
      },
      {
        name: "ELECTROLUX",
        value: "n:1380369031||p_89/ELECTROLUX",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AELECTROLUX&dc&qid=1664639023&rnid=3837712031&ref=sr_nr_p_89_17&ds=v1%3ADz3apicFLzruusO3%2BWo2NAMIFL9simJolXkxw3hBolU",
        refinement_display_name: "Brand",
      },
    ],
  },
  ad_blocks: [
    {
      campaign_id: "8580327290602",
      brand_logo:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/ed947e32-63b0-4f6a-9cca-23ed8f79445d._CR0,0,800,600_AC_SX130_SY60_QL70_.jpg",
      background_image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/f3cd599c-de0b-4f0b-bf22-aafe05dc6a77._CR0,0,1200,628_SX507_QL70_.jpg",
      advertiser_id: "2869255360002",
      ad_id: "2869255360002",
      link: "https://aax-eu.amazon.in/x/c/RCA92Hv9HZHvTUDq03-7PcoAAAGDlDe4uwMAAAH2AQBOL0EgICAgICAgICAgICBOL0EgICAgICAgICAgICB9cSni/https://www.amazon.in/stores/page/F246F99F-4758-4B90-80C6-C9D33286F29A/?_encoding=UTF8&store_ref=SB_A05194301XI4F23EKW3YJ&pd_rd_plhdr=t&aaxitk=c3d1c20c25d04a4ff06e43b093072f6d&hsa_cr_id=7343737100302&lp_asins=B07V6Z1YBJ&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_bkgd&pd_rd_w=RWmjI&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=1TMNPZ7R8EKCD6X9Z8W2&pd_rd_wg=8S5GE&pd_rd_r=4b63121a-4ec8-4f9e-a4d0-817ff728e16c",
      title: "Premium Washing Machines By AmazonBasics",
      store_link:
        "https://www.amazon.in/stores/page/F246F99F-4758-4B90-80C6-C9D33286F29A/?_encoding=UTF8&store_ref=SB_A05194301XI4F23EKW3YJ&pd_rd_plhdr=t&aaxitk=c3d1c20c25d04a4ff06e43b093072f6d&hsa_cr_id=7343737100302&lp_asins=B07V6Z1YBJ&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_cta&pd_rd_w=RWmjI&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=1TMNPZ7R8EKCD6X9Z8W2&pd_rd_wg=8S5GE&pd_rd_r=4b63121a-4ec8-4f9e-a4d0-817ff728e16c",
      store_id: "F246F99F-4758-4B90-80C6-C9D33286F29A",
      store_name: "AmazonBasics",
      products: [
        {
          asin: "B07V6Z1YBJ",
          link: "https://www.amazon.in/dp/B07V6Z1YBJ",
          image:
            "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_SR160,134_QL70_.jpg",
          is_prime: true,
          price: {
            symbol: "₹",
            value: 12490,
            currency: "INR",
            raw: "₹12,490.00",
          },
        },
      ],
    },
  ],
  video_blocks: [
    {
      video_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A18A6AOADY4Q3N/1652439019753/1c0aba33-b3bc-4e1b-b302-828f97d28712/1652439083379sbv.mp4",
      thumbnail_link:
        "https://d5glw3nnbe3pc.cloudfront.net/transcode/storyTeller/A18A6AOADY4Q3N/1652439019753/1c0aba33-b3bc-4e1b-b302-828f97d28712/1652439083379.0000000.jpg",
      campaign_id: "20016609205698",
      advertiser_id: "19795215225",
      has_audio: true,
      products: [
        {
          asin: "B08QNZTQ96",
          link: "https://www.amazon.in/dp/B08QNZTQ96",
          image:
            "https://m.media-amazon.com/images/I/51hu9nbHShL._AC_UL640_QL65_.jpg",
          title:
            "Whirlpool 6.5 Kg 5 Star Royal Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL 6.5 GENX, Grey, Hard Water Wash)",
          rating: 4.5,
          ratings_total: 2383,
        },
      ],
    },
  ],
};

export const WashingMachinesIndia_p4 = {
  request_info: {
    success: true,
    credits_used: 47,
    credits_used_this_request: 1,
    credits_remaining: 453,
    credits_reset_at: "2022-10-21T17:19:14.000Z",
  },
  request_parameters: {
    type: "search",
    search_term: "Washing Machines",
    category_id: "1380369031",
    page: "3",
    amazon_domain: "amazon.in",
  },
  request_metadata: {
    created_at: "2022-10-01T15:51:41.853Z",
    processed_at: "2022-10-01T15:51:44.939Z",
    total_time_taken: 3.09,
    amazon_url:
      "https://www.amazon.in/s/?k=Washing+Machines&ref=nb_sb_noss_2&node=1380369031&page=3",
  },
  search_results: [
    {
      position: 1,
      title:
        "Amazon Basics 6.5 Kg Fully Automatic Top Loading Washing Machine (Grey, LED panel, Memory Feature)",
      asin: "B07V3SGB2V",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_next_kitchen_sr_pg3_1?ie=UTF8&adId=A01080971RYF720EBPPZP&url=%2FAmazonBasics-Automatic-Loading-Washing-machine_Silver_Hisense2%2Fdp%2FB07V3SGB2V%2Fref%3Dsr_1_33_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-33-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_atf_next",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.1,
      ratings_total: 141,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 10990,
          currency: "INR",
          raw: "₹10,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19999,
          currency: "INR",
          raw: "₹19,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 10990,
        currency: "INR",
        raw: "₹10,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 2,
      title:
        "Samsung 6.0 Kg Inverter 5 Star Fully-Automatic Front Loading Washing Machine (WW60R20GLMA/TL, White, Hygiene Steam)",
      asin: "B084LGQKD7",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_atf_next_kitchen_sr_pg3_1?ie=UTF8&adId=A0316105FF39BVZPT0HN&url=%2FSamsung-Fully-Automatic-Loading-WW60R20GLMA-TL%2Fdp%2FB084LGQKD7%2Fref%3Dsr_1_34_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-34-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_atf_next",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71i8f3-DsuL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 4612,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 21990,
          currency: "INR",
          raw: "₹21,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 26900,
          currency: "INR",
          raw: "₹26,900",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 21990,
        currency: "INR",
        raw: "₹21,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 3,
      title:
        "AmazonBasics 7 kg Fully Automatic Top Load Washing Machine (Memory feature, Rust free)",
      asin: "B07V5W37BD",
      link: "https://www.amazon.in/AmazonBasics-Front-Load-Washing-Machine_14/dp/B07V5W37BD/ref=sr_1_35?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-35",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/51lN00BvwRL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4,
      ratings_total: 3366,
      prices: [
        {
          symbol: "₹",
          value: 11890,
          currency: "INR",
          raw: "₹11,890",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 27000,
          currency: "INR",
          raw: "₹27,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 11890,
        currency: "INR",
        raw: "₹11,890",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 4,
      title:
        "IFB 6 Kg 5 Star Front Load Washing Machine 2X Power Dual Steam (DIVA AQUA BXS 6008, White & Black, Active Color Protection, Hard Water Wash)",
      asin: "B09ZBJRGWH",
      link: "https://www.amazon.in/IFB-DIVA-AQUA-BXS-6008/dp/B09ZBJRGWH/ref=sr_1_36?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-36",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61Gbp0b1lmL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.2,
      ratings_total: 1847,
      prices: [
        {
          symbol: "₹",
          value: 21490,
          currency: "INR",
          raw: "₹21,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 27090,
          currency: "INR",
          raw: "₹27,090",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 21490,
        currency: "INR",
        raw: "₹21,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 5,
      title:
        "LG 6 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine (FHM1006SDW, White, 6 Motion Direct Drive)",
      asin: "B09LLMH3CQ",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-FHM1006SDW/dp/B09LLMH3CQ/ref=sr_1_37?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-37",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/7108LHrN2eL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 2494,
      prices: [
        {
          symbol: "₹",
          value: 23990,
          currency: "INR",
          raw: "₹23,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 33990,
          currency: "INR",
          raw: "₹33,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23990,
        currency: "INR",
        raw: "₹23,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 6,
      title:
        "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine with In - built Heater (WAJ2416SIN, Silver)",
      asin: "B08SR372S7",
      link: "https://www.amazon.in/Bosch-Inverter-Control-Automatic-Loading/dp/B08SR372S7/ref=sr_1_38?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-38",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71laDnTjKPL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.5,
      ratings_total: 2026,
      prices: [
        {
          symbol: "₹",
          value: 31490,
          currency: "INR",
          raw: "₹31,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 48590,
          currency: "INR",
          raw: "₹48,590",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 31490,
        currency: "INR",
        raw: "₹31,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 7,
      title:
        "IFB 7 Kg 5 Star Front Load Washing Machine 2X Power Dual Steam (NEO DIVA BXS 7010, White & Black, Active Color Protection, Hard Water Wash)",
      asin: "B0B38Q299B",
      link: "https://www.amazon.in/IFB-NEO-DIVA-BXS-7010/dp/B0B38Q299B/ref=sr_1_39?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-39",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61SYeIbA4ML._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 1613,
      prices: [
        {
          symbol: "₹",
          value: 28990,
          currency: "INR",
          raw: "₹28,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 35490,
          currency: "INR",
          raw: "₹35,490",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 28990,
        currency: "INR",
        raw: "₹28,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 8,
      title:
        "Haier HWM65-AE 6.5Kg Top Load Fully-Automatic Washing Machine, Moonlight Grey",
      asin: "B08ZJJZDMP",
      link: "https://www.amazon.in/Haier-Automatic-Washing-HWM65-AE-Moonlight/dp/B08ZJJZDMP/ref=sr_1_40?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-40",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/41+ZO1DpncL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹500",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 223,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 19990,
          currency: "INR",
          raw: "₹19,990",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 9,
      title:
        "Samsung 7 Kg 5 Star Inverter, Hygiene Steam Fully-Automatic Front Loading Washing Machine (WW70T4020EE1TL, White, Diamond Drum)",
      asin: "B09KGY7S81",
      link: "https://www.amazon.in/Samsung-Inverter-Hygiene-Fully-Automatic-WW70T4020EE1TL/dp/B09KGY7S81/ref=sr_1_41?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-41",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/7144KdCjAyL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 313,
      prices: [
        {
          symbol: "₹",
          value: 27490,
          currency: "INR",
          raw: "₹27,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 40300,
          currency: "INR",
          raw: "₹40,300",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 27490,
        currency: "INR",
        raw: "₹27,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 10,
      title:
        "Godrej 6.5 Kg 5 Star Fully-Automatic Top Loading Washing Machine (WTEON 650 AD 5.0 ROGR, Grey, Acu Wash Drum)",
      asin: "B08CW1ZNG2",
      link: "https://www.amazon.in/Godrej-Fully-Automatic-WTEON-650-ROGR/dp/B08CW1ZNG2/ref=sr_1_42?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-42",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/711QU6ggL-L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.3,
      ratings_total: 508,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 17500,
          currency: "INR",
          raw: "₹17,500",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 11,
      title:
        "Samsung 6.0 Kg Inverter 5 star Fully-Automatic Front Loading Washing Machine (WW60R20GLSS/TL, Silver, Hygiene steam)",
      asin: "B08DDTG2XX",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg3_1?ie=UTF8&adId=A0315949KJCBBSI62XME&url=%2FSamsung-Inverter-Fully-Automatic-WW60R20GLSS-TL%2Fdp%2FB08DDTG2XX%2Fref%3Dsr_1_43_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-43-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71m+C6830pL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.4,
      ratings_total: 984,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 23490,
          currency: "INR",
          raw: "₹23,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 32190,
          currency: "INR",
          raw: "₹32,190",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23490,
        currency: "INR",
        raw: "₹23,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 12,
      title:
        "AmazonBasics 7 kg Semi Automatic Washing Machine (with Heavy wash function, White/Blue color)",
      asin: "B07V3SGB3G",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_mtf_kitchen_sr_pg3_1?ie=UTF8&adId=A0822194218S1KYUUIIQ8&url=%2FAmazonBasics-Semi-Automatic-Washing-Machine%2Fdp%2FB07V3SGB3G%2Fref%3Dsr_1_44_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-44-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_mtf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71+JK-QC68L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4,
      ratings_total: 715,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 8490,
          currency: "INR",
          raw: "₹8,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16999,
          currency: "INR",
          raw: "₹16,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8490,
        currency: "INR",
        raw: "₹8,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 13,
      title:
        "Whirlpool 7.5 Kg 5 Star Royal Plus Fully-Automatic Top Loading Washing Machine (WHITEMAGIC ROYAL PLUS 7.5, Grey, Hard Water Wash)",
      asin: "B082FJ9RS7",
      link: "https://www.amazon.in/Whirlpool-7-5-Fully-Automatic-ROYAL-PLUS/dp/B082FJ9RS7/ref=sr_1_45?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-45",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71pVSyDJD9L._AC_UY218_.jpg",
      rating: 4.3,
      ratings_total: 3363,
      prices: [
        {
          symbol: "₹",
          value: 17990,
          currency: "INR",
          raw: "₹17,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20300,
          currency: "INR",
          raw: "₹20,300",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 17990,
        currency: "INR",
        raw: "₹17,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 14,
      title:
        "IFB 8kg 5 Star Fully-Automatic Front Loading Washing Machine (Senator WSS Steam, Silver, Inbuilt Heater, Aqua Energie Water Softener), Large",
      asin: "B08M643JST",
      link: "https://www.amazon.in/IFB-Fully-Automatic-Senator-WSS-Steam/dp/B08M643JST/ref=sr_1_46?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-46",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71btlBqj9OL._AC_UY218_.jpg",
      rating: 4.3,
      ratings_total: 434,
      prices: [
        {
          symbol: "₹",
          value: 36800,
          currency: "INR",
          raw: "₹36,800",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 43990,
          currency: "INR",
          raw: "₹43,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 36800,
        currency: "INR",
        raw: "₹36,800",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 15,
      title:
        "Bosch 6 kg 5 Star Inverter Fully Automatic Front Loading Washing Machine with In - built Heater (WLJ2016WIN, White )",
      asin: "B08PZBZD5M",
      link: "https://www.amazon.in/Bosch-Inverter-Automatic-Loading-Washing/dp/B08PZBZD5M/ref=sr_1_47?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-47",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61tUypW4kkL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.5,
      ratings_total: 1469,
      prices: [
        {
          symbol: "₹",
          value: 25990,
          currency: "INR",
          raw: "₹25,990",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 37790,
          currency: "INR",
          raw: "₹37,790",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 25990,
        currency: "INR",
        raw: "₹25,990",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 16,
      title:
        "AmazonBasics 7 kg Semi Automatic Washing Machine (with Heavy wash function, White/Blue color)",
      asin: "B07V3SGB3G",
      link: "https://www.amazon.in/AmazonBasics-Semi-Automatic-Washing-Machine/dp/B07V3SGB3G/ref=sr_1_48?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-48",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/71+JK-QC68L._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4,
      ratings_total: 715,
      prices: [
        {
          symbol: "₹",
          value: 8490,
          currency: "INR",
          raw: "₹8,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 16999,
          currency: "INR",
          raw: "₹16,999",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 8490,
        currency: "INR",
        raw: "₹8,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 17,
      title:
        "Godrej EDGE ULTIMA 8 Kg 5 star Semi-Automatic Top Loading Washing Machine (WSEDGE ULTS 80 5.0 DB2M CSGR, Crystal Grey, Stainless Steel Wash and Spin Drum)",
      asin: "B08X6ZYWBD",
      link: "https://www.amazon.in/Godrej-Automatic-Washing-Machine-WS80ULTIMA/dp/B08X6ZYWBD/ref=sr_1_49?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-49",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61tfnhWu28L._AC_UY218_.jpg",
      rating: 4.1,
      ratings_total: 17,
      prices: [
        {
          symbol: "₹",
          value: 13990,
          currency: "INR",
          raw: "₹13,990",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20050,
          currency: "INR",
          raw: "₹20,050",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13990,
        currency: "INR",
        raw: "₹13,990",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 18,
      title:
        "LG 7 kg Inverter Fully-Automatic Top Loading Washing Machine (T70SPSF2Z, Middle Free Silver)",
      asin: "B08DH9PKJ2",
      link: "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-T70SPSF2Z/dp/B08DH9PKJ2/ref=sr_1_50?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-50",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61i8y0xbbpS._AC_UY218_.jpg",
      rating: 4.4,
      ratings_total: 1150,
      prices: [
        {
          symbol: "₹",
          value: 19315,
          currency: "INR",
          raw: "₹19,315",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 25990,
          currency: "INR",
          raw: "₹25,990",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 19315,
        currency: "INR",
        raw: "₹19,315",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 19,
      title:
        "Samsung 8 Kg 5 Star Inverter, Hygiene Steam Fully-Automatic Front Loading Washing Machine (WW80T4040CE1TL, White)",
      asin: "B09KGW3NWL",
      link: "https://www.amazon.in/Samsung-Inverter-Hygiene-Fully-Automatic-WW80T4040CE1TL/dp/B09KGW3NWL/ref=sr_1_51?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-51",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61Qt1PoCbrL._AC_UY218_.jpg",
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.3,
      ratings_total: 172,
      prices: [
        {
          symbol: "₹",
          value: 31490,
          currency: "INR",
          raw: "₹31,490",
          name: "Primary",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 45000,
          currency: "INR",
          raw: "₹45,000",
          name: "Original",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 31490,
        currency: "INR",
        raw: "₹31,490",
        name: "Primary",
        is_primary: true,
      },
    },
    {
      position: 20,
      title:
        "IFB 6 Kg 5 Star Front Load Washing Machine 2X Power Dual Steam (DIVA AQUA SXS 6008, Silver, Active Color Protection, Hard Water Wash)",
      asin: "B09ZBLDBLN",
      link: "https://www.amazon.in/IFB-DIVA-AQUA-SXS-6008/dp/B09ZBLDBLN/ref=sr_1_52?keywords=Washing+Machines&qid=1664639502&qu=eyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%3D%3D&s=kitchen&sr=1-52",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61mQtGLRehL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      coupon: {
        badge_text: "Save ₹1,000",
        text: "with coupon",
      },
      rating: 4.2,
      ratings_total: 5976,
      prices: [
        {
          symbol: "₹",
          value: 23490,
          currency: "INR",
          raw: "₹23,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 27690,
          currency: "INR",
          raw: "₹27,690",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 23490,
        currency: "INR",
        raw: "₹23,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
    {
      position: 21,
      title:
        "Haier HWM70-AE 7Kg Top Loading Washing Machine (Moonlight Grey, Quick Wash)",
      asin: "B08ZJHJ5DJ",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg3_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_53_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-53-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/41VYzY29VaL._AC_UY218_.jpg",
      rating: 4,
      ratings_total: 126,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 14990,
          currency: "INR",
          raw: "₹14,990",
          asin: "B08ZJHJ5DJ",
          link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg3_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_53_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-53-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_btf",
        },
      ],
      price: {
        symbol: "₹",
        value: 14990,
        currency: "INR",
        raw: "₹14,990",
        asin: "B08ZJHJ5DJ",
        link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg3_1?ie=UTF8&adId=A073691534UN35M37LAAP&url=%2FHaier-Automatic-Washing-HWM70-AE-Moonlight%2Fdp%2FB08ZJHJ5DJ%2Fref%3Dsr_1_53_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-53-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_btf",
      },
    },
    {
      position: 22,
      title:
        "Panasonic 6 Kg 5 Star Fully-Automatic Top Loading Washing Machine (NA-F60LF1HRB, Grey)",
      asin: "B08B9756FB",
      link: "https://www.amazon.in/gp/slredirect/picassoRedirect.html/ref=pa_sp_btf_kitchen_sr_pg3_1?ie=UTF8&adId=A0891977CC0X02KRAIKB&url=%2FPanasonic-Fully-Automatic-Loading-Washing-NA-F60LF1HRB%2Fdp%2FB08B9756FB%2Fref%3Dsr_1_54_sspa%3Fkeywords%3DWashing%2BMachines%26qid%3D1664639502%26qu%3DeyJxc2MiOiI2LjMyIiwicXNhIjoiNi4wMCIsInFzcCI6IjQuMzkifQ%253D%253D%26s%3Dkitchen%26sr%3D1-54-spons%26psc%3D1&qualifier=1664639502&id=2076094240737753&widgetName=sp_btf",
      categories: [
        {
          name: "Washing Machines & Dryers",
          id: "1380369031",
        },
      ],
      image: "https://m.media-amazon.com/images/I/61E5gS4YBIL._AC_UY218_.jpg",
      deal: {
        link: "https://www.amazon.in/greatindianfestival",
        badge_text: "Great Indian Festival",
      },
      rating: 4.4,
      ratings_total: 4349,
      sponsored: true,
      prices: [
        {
          symbol: "₹",
          value: 13490,
          currency: "INR",
          raw: "₹13,490",
          name: "Great Indian Festival",
          is_primary: true,
        },
        {
          symbol: "₹",
          value: 20000,
          currency: "INR",
          raw: "₹20,000",
          name: "Great Indian Festival",
          is_rrp: true,
        },
      ],
      price: {
        symbol: "₹",
        value: 13490,
        currency: "INR",
        raw: "₹13,490",
        name: "Great Indian Festival",
        is_primary: true,
      },
    },
  ],
  related_brands: [
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/f3cd599c-de0b-4f0b-bf22-aafe05dc6a77._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/e283ea86-49cf-49ad-a39b-adb4da546fba._CR0,0,1200,628_SX460_QL70_.jpg",
    },
    {
      image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/8a7b41c8-8827-4e7b-909f-a76f334ee19f._CR0,0,1200,628_SX460_QL70_.jpg",
    },
  ],
  pagination: {
    total_results: 1000,
    current_page: 3,
    next_page_link:
      "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031&page=4&qid=1664639502&ref=sr_pg_4",
    total_pages: 103,
  },
  refinements: {
    departments: [
      {
        name: "Home & Kitchen",
        value: "n/976442031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031&dc&qid=1664639502&ref=sr_ex_n_1&ds=v1%3AGTA1nye3orT5Q6Mge4RGz9U%2Fro5sOqdjDB3wP8faIus",
        refinement_display_name: "Departments",
      },
      {
        name: "Large Appliances",
        value: "n/1380263031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031&dc&qid=1664639502&ref=sr_ex_n_1&ds=v1%3AGBa67O2ai%2FLr5oLYWJVmpaKVlKdofJNHie27YaP06qM",
        refinement_display_name: "Departments",
      },
      {
        name: "Washing Machines & Dryers",
        value: "n/1380369031",
        refinement_display_name: "Departments",
      },
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_1&ds=v1%3A1UId5aoTedP9VCHVVjgnVL3aZDjMYPDMBBRjibpdQgI",
        refinement_display_name: "Departments",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_2&ds=v1%3A%2BA7svkLaGKP4X76%2FkvZ63KfadWQYhtHamS8fwWxlqxw",
        refinement_display_name: "Departments",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AMMj2ZHqDUfGkCQinQB5VNumx13J2BagwibbcKQPTDfQ",
        refinement_display_name: "Departments",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_4&ds=v1%3Ax1raeUUgV2eLKfEhRo%2FXojpxm8A4hm%2BJDATHT1vaBak",
        refinement_display_name: "Departments",
      },
    ],
    child_categories: [
      {
        name: "Combination Washers & Dryers",
        value: "n/1380370031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380370031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_1&ds=v1%3A1UId5aoTedP9VCHVVjgnVL3aZDjMYPDMBBRjibpdQgI",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Semi-Automatic",
        value: "n/14316143031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A14316143031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_2&ds=v1%3A%2BA7svkLaGKP4X76%2FkvZ63KfadWQYhtHamS8fwWxlqxw",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Stacked Washer & Dryer Units",
        value: "n/1380372031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380372031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_3&ds=v1%3AMMj2ZHqDUfGkCQinQB5VNumx13J2BagwibbcKQPTDfQ",
        refinement_display_name: "Child Categories",
      },
      {
        name: "Clothes Washing Machines",
        value: "n/1380373031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A976442031%2Cn%3A1380263031%2Cn%3A1380369031%2Cn%3A1380373031&dc&qid=1664639502&rnid=976442031&ref=sr_nr_n_4&ds=v1%3Ax1raeUUgV2eLKfEhRo%2FXojpxm8A4hm%2BJDATHT1vaBak",
        refinement_display_name: "Child Categories",
      },
    ],
    reviews: [
      {
        name: "4 Stars & Up",
        value: "n:1380369031||p_72/1318476031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318476031&dc&qid=1664639502&rnid=1318475031&ref=sr_nr_p_72_1&ds=v1%3AKME4Z5EWEmqBRGufPmh0pLotthwW4f4tatEVtftuAfs",
        refinement_display_name: "Reviews",
      },
      {
        name: "3 Stars & Up",
        value: "n:1380369031||p_72/1318477031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318477031&dc&qid=1664639502&rnid=1318475031&ref=sr_nr_p_72_2&ds=v1%3Au5VE9dnCQSV2BGAjliFc2HYia6kaxIOVmQexS7Jb5xQ",
        refinement_display_name: "Reviews",
      },
      {
        name: "2 Stars & Up",
        value: "n:1380369031||p_72/1318478031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318478031&dc&qid=1664639502&rnid=1318475031&ref=sr_nr_p_72_3&ds=v1%3ABiAZAatTqRytESNxRFC8GIsifR9fitbwKPLegDD1%2Fhc",
        refinement_display_name: "Reviews",
      },
      {
        name: "1 Star & Up",
        value: "n:1380369031||p_72/1318479031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_72%3A1318479031&dc&qid=1664639502&rnid=1318475031&ref=sr_nr_p_72_4&ds=v1%3A5bkWJHv%2FL8mebW0TKUZE4AWW7XH2FmTffnRIENCJqXM",
        refinement_display_name: "Reviews",
      },
    ],
    price: [
      {
        name: "Under ₹10,000",
        value: "n:1380369031||p_36/5271572031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271572031&dc&qid=1664639502&rnid=5271547031&ref=sr_nr_p_36_1&ds=v1%3AIh6ae6UUaBlnVVqlbqSIWNsqDyiHCm1f5spq7Qa%2BkbM",
        refinement_display_name: "Price",
      },
      {
        name: "₹10,000 - ₹15,000",
        value: "n:1380369031||p_36/5271573031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271573031&dc&qid=1664639502&rnid=5271547031&ref=sr_nr_p_36_2&ds=v1%3AvxioBI2S9HgCcYl0w8Yxd2EHDIlU4gl5bniZ8l7SkAU",
        refinement_display_name: "Price",
      },
      {
        name: "₹15,000 - ₹20,000",
        value: "n:1380369031||p_36/5271574031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271574031&dc&qid=1664639502&rnid=5271547031&ref=sr_nr_p_36_3&ds=v1%3A3MJDSDjlpWA7LHqY6yTj6QyhcwJQb56xgwWopKTk8F8",
        refinement_display_name: "Price",
      },
      {
        name: "₹20,000 - ₹30,000",
        value: "n:1380369031||p_36/5271575031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271575031&dc&qid=1664639502&rnid=5271547031&ref=sr_nr_p_36_4&ds=v1%3AJrAsIBTAfqZO3ZHtCiojiKLgRCdq%2FRecGUDArwOgfYw",
        refinement_display_name: "Price",
      },
      {
        name: "Over ₹30,000",
        value: "n:1380369031||p_36/5271576031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_36%3A5271576031&dc&qid=1664639502&rnid=5271547031&ref=sr_nr_p_36_5&ds=v1%3AEbkhJyPb%2BLQx3WKniva2ZzMjXTLCiLRyeEwcK%2BW0pOs",
        refinement_display_name: "Price",
      },
      {
        name: "₹        ₹            Go",
        value: "p_36/price-range",
        refinement_display_name: "Price",
      },
      {
        name: "Today's Deals",
        value: "n:1380369031||p_n_specials_match/21618256031",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_n_specials_match%3A21618256031&dc&qid=1664639502&rnid=21618255031&ref=sr_nr_p_n_specials_match_1&ds=v1%3A5Kn%2FYbgv%2BmjkKJSGoEAK3m2%2BLRY%2BZG3AS90JuI0%2FR3Q",
        refinement_display_name: "Price",
      },
    ],
    brand: [
      {
        name: "Samsung",
        value: "n:1380369031||p_89/Samsung",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ASamsung&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_1&ds=v1%3A8StlKsqG12My%2Fvl%2B7P3hCM%2FjeqF5GLXD7rSAGtCco%2Bw",
        refinement_display_name: "Brand",
      },
      {
        name: "LG",
        value: "n:1380369031||p_89/LG",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ALG&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_2&ds=v1%3ANFP%2FZyiRhLrSHX5S2ILGgalKoDpPkzODlR8zWBhRKLw",
        refinement_display_name: "Brand",
      },
      {
        name: "IFB",
        value: "n:1380369031||p_89/IFB",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AIFB&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_3&ds=v1%3AGO8a4AomxS5hVdWSOxadC7y0OTfbbmSiDWXSCbZW8DI",
        refinement_display_name: "Brand",
      },
      {
        name: "Whirlpool",
        value: "n:1380369031||p_89/Whirlpool",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AWhirlpool&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_4&ds=v1%3ASaJ%2FM41aqi%2BMdLB%2F7A%2B1SMHjay3OP4HR1qO2y4NINW0",
        refinement_display_name: "Brand",
      },
      {
        name: "Godrej",
        value: "n:1380369031||p_89/Godrej",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGodrej&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_5&ds=v1%3AVM%2B8Zop8%2BqrUgU1v28I%2FzrZgt0QxhE7PLopQxa89eKw",
        refinement_display_name: "Brand",
      },
      {
        name: "Bosch",
        value: "n:1380369031||p_89/Bosch",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ABosch&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_6&ds=v1%3AyjtodCWoH43F4MuLM4qWRg0up4KA364XDAxXS9JVRw8",
        refinement_display_name: "Brand",
      },
      {
        name: "Panasonic",
        value: "n:1380369031||p_89/Panasonic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3APanasonic&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_7&ds=v1%3A7VtRPHy1NoI2fHjMUGDxUw3jhfFC%2FTr%2BKAs0OQzQ0rM",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMAHaierDMRMideaTOSHIBAGenericOracusELECTROLUX",
        value: "n:1380369031||undefined",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3AKl008s%2F7NEPCHUV49FP1lNugBA7HL%2FtB2TlSafSucEs",
        refinement_display_name: "Brand",
      },
      {
        name: "CROMA",
        value: "n:1380369031||p_89/CROMA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ACROMA&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_8&ds=v1%3AKl008s%2F7NEPCHUV49FP1lNugBA7HL%2FtB2TlSafSucEs",
        refinement_display_name: "Brand",
      },
      {
        name: "Haier",
        value: "n:1380369031||p_89/Haier",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AHaier&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_9&ds=v1%3AY6baJl0sZmIyYedCZ0zHWL1GTSKgDebxR0ymkNSC2oU",
        refinement_display_name: "Brand",
      },
      {
        name: "DMR",
        value: "n:1380369031||p_89/DMR",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ADMR&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_10&ds=v1%3A%2Br0c90ge9e4LzyF4Y9CH%2B72W62V33ndckd%2F%2BeH3ecv0",
        refinement_display_name: "Brand",
      },
      {
        name: "Midea",
        value: "n:1380369031||p_89/Midea",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AMidea&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_11&ds=v1%3ABSTgFow2Ppyir8Nv%2BN3vt2L47eGvkIjbm7QG1GECZp0",
        refinement_display_name: "Brand",
      },
      {
        name: "TOSHIBA",
        value: "n:1380369031||p_89/TOSHIBA",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3ATOSHIBA&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_12&ds=v1%3AZx13haIK%2BUVaJD4h1rRvVnoIyHvc7bUMArFaH7%2Bpcvk",
        refinement_display_name: "Brand",
      },
      {
        name: "Generic",
        value: "n:1380369031||p_89/Generic",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AGeneric&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_13&ds=v1%3AEDspqKfA1J74xae1ePf6OaJEoRSFVec3nRQ%2FM3EGhqM",
        refinement_display_name: "Brand",
      },
      {
        name: "Oracus",
        value: "n:1380369031||p_89/Oracus",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AOracus&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_14&ds=v1%3AWaq9bC7UuLjk81QakyWmerzRS5qurdB92f4IoQCQGDY",
        refinement_display_name: "Brand",
      },
      {
        name: "ELECTROLUX",
        value: "n:1380369031||p_89/ELECTROLUX",
        link: "https://www.amazon.in/s?k=Washing+Machines&i=kitchen&rh=n%3A1380369031%2Cp_89%3AELECTROLUX&dc&qid=1664639502&rnid=3837712031&ref=sr_nr_p_89_15&ds=v1%3A0vAy3ZTyd88D2MPu7Qa%2Bh%2BLlqvlRFG3CcYo79zaDbwk",
        refinement_display_name: "Brand",
      },
    ],
  },
  ad_blocks: [
    {
      campaign_id: "8580327290602",
      brand_logo:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/ed947e32-63b0-4f6a-9cca-23ed8f79445d._CR0,0,800,600_AC_SX130_SY60_QL70_.jpg",
      background_image:
        "https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/f3cd599c-de0b-4f0b-bf22-aafe05dc6a77._CR0,0,1200,628_SX507_QL70_.jpg",
      advertiser_id: "2869255360002",
      ad_id: "2869255360002",
      link: "https://aax-eu.amazon.in/x/c/RO0amlHDHJtrttpPj8BJxZsAAAGDlD8JLQMAAAH2AQBOL0EgICAgICAgICAgICBOL0EgICAgICAgICAgICDqtc_R/https://www.amazon.in/stores/page/F246F99F-4758-4B90-80C6-C9D33286F29A/?_encoding=UTF8&store_ref=SB_A05194301XI4F23EKW3YJ&pd_rd_plhdr=t&aaxitk=c3d1c20c25d04a4ff06e43b093072f6d&hsa_cr_id=7343737100302&lp_asins=B07V6Z1YBJ&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_bkgd&pd_rd_w=RMz7g&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=P5XFZCHEN83H608JXC3R&pd_rd_wg=GO5wt&pd_rd_r=dce8baae-fa41-434c-9b50-604bc4e23ff0",
      title: "Premium Washing Machines By AmazonBasics",
      store_link:
        "https://www.amazon.in/stores/page/F246F99F-4758-4B90-80C6-C9D33286F29A/?_encoding=UTF8&store_ref=SB_A05194301XI4F23EKW3YJ&pd_rd_plhdr=t&aaxitk=c3d1c20c25d04a4ff06e43b093072f6d&hsa_cr_id=7343737100302&lp_asins=B07V6Z1YBJ&lp_query=Washing%20Machines&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_cta&pd_rd_w=RMz7g&content-id=amzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1%3Aamzn1.sym.f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_p=f8f8fdd1-2328-42fe-ad6d-4748614149f1&pf_rd_r=P5XFZCHEN83H608JXC3R&pd_rd_wg=GO5wt&pd_rd_r=dce8baae-fa41-434c-9b50-604bc4e23ff0",
      store_id: "F246F99F-4758-4B90-80C6-C9D33286F29A",
      store_name: "AmazonBasics",
      products: [
        {
          asin: "B07V6Z1YBJ",
          link: "https://www.amazon.in/dp/B07V6Z1YBJ",
          image:
            "https://m.media-amazon.com/images/I/51rG6Q6C-jS._AC_SR160,134_QL70_.jpg",
          is_prime: true,
          price: {
            symbol: "₹",
            value: 12490,
            currency: "INR",
            raw: "₹12,490.00",
          },
        },
      ],
    },
  ],
};
