import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { WhatsappShareButton } from "react-share";


const WhatsappShareButtonProj = ({ url, title, imageUrl }) => {
  const message = encodeURIComponent(`${title}: ${url}`);
  const whatsappUrl = `https://wa.me/?text=${message}`;

  return (
    <WhatsappShareButton
      url={url}
      title={title}
      separator=" - "
      media={imageUrl}
    >
      <FaWhatsapp size={30} color="green" />
    </WhatsappShareButton>
  );
};

export default WhatsappShareButtonProj;
