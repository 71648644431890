import { Breadcrumb, Button, Col, Layout, Row, Spin, Tooltip } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useParams } from "react-router-dom";
import { ThunderboltOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { blogDataSample } from "../../datajson/blogsData";
import Footers from "../../layouts/footers/Footers";
import HeadersCopy from "../../layouts/headers/Headers copy";
import { getLinkTg } from "../../../utilities/getLinkRef";
import SEO from "../../../seo/SEO";
import slugify from "react-slugify";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogs,
  SIDE_BAR_TITLES,
} from "../../../store/actions/homepageActions";
import FBShareButton from "../../../utilities/share/FBShareButton";
import WhatsappShareButton from "../../../utilities/share/WhatsappShareButtonProj";
import ShareOnInstagram from "../../../utilities/share/ShareOnInstagram";
import "./blog.css";
import { writeXml } from "../../../utilities/writeCustomizedJson";
import ScrollButton from "../../layouts/ScrollButton";
// import { testHtml } from "../../datajson/test";
const BlogDetails = () => {
  const location = useLocation();

  const currentUrl = window.location.href;

  // const title = location.state.passdata;
  // const [blogData, setBlogData] = useState(testHtml[0].data);
  const [blogData, setBlogData] = useState();

  const [blogsData, setBlogsData] = useState([]);

  const pathname = window.location.href;

  //const titles = location.state.title;
  const blogDatafrom = useSelector((state) => state.home.getAllBlogs) || [];

  const blogSideBarData =
    useSelector((state) => state.home.sideBarTitles) || [];

  const [loading, setLoading] = useState(false);
  const [relTitels, setRelTitels] = useState([]);

  const { bid } = useParams();
  const category = "electronics";
  // let keywords = [title];

  const dispatch = useDispatch();
  const getBlogData = async () => {
    setLoading(true);
    const res = await dispatch(getBlogs());
    if (res) {
      res && setBlogsData(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (blogDatafrom.length < 1) {
      setLoading(true);
      getBlogData();
    } else {
      setBlogsData(blogDatafrom);
    }
  }, []);

  useEffect(() => {
    const relatedTitle = [];
    blogsData &&
      blogsData.length > 0 &&
      blogsData.map((item, index) => {
        const id = item.data.id;
        const title = item.data.title;
        const slug = item.data.slug;
        return id !== bid && relatedTitle.push({ id, title, slug });
      });
    setRelTitels(relatedTitle.slice(0, 10));
    if (relatedTitle.length > 0) {
      //sitemap-blog
      writeXml(category, relatedTitle);
      dispatch({ type: SIDE_BAR_TITLES, payload: relatedTitle });
    }
    return () => {};
  }, [blogsData]);

  useEffect(() => {
    if (blogsData && blogsData.length > 0) {
      const data = blogsData.filter((item) => {
        return item.data.id === bid;
      });
      setBlogData(data && data.length > 0 && data[0].data);
      // setBlogData(testHtml.data);
    }
    return () => {};
  }, [blogsData, bid]);

  return (
    <div>
      <Layout>
        <SEO
          title={blogData && blogData.title}
          description={blogData && blogData.content?.intro}
          image={blogData && blogData.content?.images[0].url}
          article={blogData && blogData.title}
          //keywords={keywords}
        />
        <meta property="og:title" content={blogData && blogData.title} />
        <meta
          property="og:image"
          content={blogData && blogData.content?.images[0].url}
        />

        <HeadersCopy />
        <div style={{ margin: 30 }}>
          {" "}
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>&nbsp;{"/"}&nbsp;
              <Link to="/blogs">Blogs</Link>&nbsp;{"/"}&nbsp;
              <Link to="/blogs">
                {currentUrl.split("/")[4].charAt(0).toUpperCase() +
                  currentUrl.split("/")[4].substring(1)}
              </Link>
              &nbsp;{"/"}&nbsp;
              <Link>{blogData && blogData.title.substring(0, 50) + "..."}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Content
          style={{
            padding: "0 20px",
            background: "white",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          {loading ? (
            <Col
              span={24}
              style={{
                textAlign: "center",
                height: 500,
                paddingTop: "8%",
              }}
            >
              <Spin
                style={{ textAlign: "center" }}
                size="large"
                tip="Loading..."
              />
            </Col>
          ) : (
            <Row>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <div>
                  <br />
                  <br />
                  <center>
                    <LazyLoadImage
                      src={blogData && blogData.content?.images[0].url}
                      alt={blogData && blogData.title}
                      width={"70%"}
                      height={"70%"}
                      effect="blur"
                      // style={{ paddingLeft: "70px" }}
                    />
                  </center>
                  <br />
                  <br />
                  <Row>
                    <div style={{ margin: 10 }}>
                      <strong>By :</strong> {blogData && blogData.author?.name}{" "}
                      <Tooltip
                        title={
                          blogData && "Expart At : " + blogData.author?.bio
                        }
                      >
                        <span style={{ color: "#007AFE" }}>
                          <QuestionCircleOutlined />
                        </span>
                      </Tooltip>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <FBShareButton
                        url={`https://ecronhub.com/blogs/${category}/${slugify(
                          blogData && blogData.title
                        )}/${blogData && blogData.id}`}
                        title={blogData && blogData.title}
                      />
                    </Col>
                    <Col style={{ marginLeft: 20 }}>
                      <WhatsappShareButton
                        url={`https://ecronhub.com/blogs/${category}/${slugify(
                          blogData && blogData.title
                        )}/${blogData && blogData.id}`}
                        title={blogData && blogData.title}
                        separator=" - "
                        media={blogData && blogData.content?.images[0].url}
                      />
                    </Col>
                    <Col style={{ marginLeft: 20 }}>
                      <ShareOnInstagram
                        url={`https://ecronhub.com/blogs/${category}/${slugify(
                          blogData && blogData.title.substring(0, 50)
                        )}/${blogData && blogData.id}`}
                        title={blogData && blogData.title}
                        imageUrl={blogData && blogData.content?.images[0].url}
                      />
                    </Col>
                  </Row>

                  <br />
                  <br />
                  <h1>{blogData && blogData.title}</h1>
                  <h2>
                    <i>{blogData && blogData.content?.intro}</i>
                  </h2>
                  <br />
                  <br />
                  {blogData &&
                  blogData &&
                  blogData.html &&
                  blogData.html === "html" ? (
                    <div>
                      {blogData &&
                        blogData.contentHtml &&
                        blogData.contentHtml.map((item, index) => {
                          return (
                            <>
                              &nbsp;
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html: item.description.htmlString,
                                }}
                              />
                              <center>
                                <LazyLoadImage
                                  src={
                                    item.description &&
                                    item.description.images &&
                                    item.description.images[0].url
                                  }
                                  alt={item.intro}
                                  width={"80%"}
                                  height={"70%"}
                                  effect="blur"
                                  // style={{ padding: "70px" }}
                                />
                              </center>
                              <Row>
                                <Col>
                                  <FBShareButton
                                    url={pathname}
                                    title={item && item.intro}
                                  />
                                </Col>
                                <Col style={{ marginLeft: 20 }}>
                                  <WhatsappShareButton
                                    url={pathname}
                                    title={item && item.intro}
                                    separator=" - "
                                    // media={
                                    //   blogData &&
                                    //   blogData.content?.images[0].url
                                    // }
                                  />
                                </Col>
                                <Col style={{ marginLeft: 20 }}>
                                  <ShareOnInstagram
                                    url={pathname}
                                    title={item && item.intro}
                                    // imageUrl={
                                    //   blogData &&
                                    //   blogData.content?.images[0].url
                                    // }
                                  />
                                </Col>
                              </Row>
                              <center>
                                <h3>
                                  Get it{" "}
                                  <a
                                    href={item.description.linktobuy}
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                  >
                                    Here
                                  </a>
                                </h3>
                              </center>
                            </>
                          );
                        })}
                    </div>
                  ) : (
                    <div>
                      <center>
                        <LazyLoadImage
                          src={blogData && blogData.content?.images[1].url}
                          alt={blogData && blogData.title}
                          width={"60%"}
                          height={"60%"}
                          effect="blur"
                          // style={{ padding: "70px" }}
                        />
                      </center>
                      <br />

                      {blogData &&
                        blogData.content?.deccription.map((item, key) => {
                          const details = item.split(":");
                          return (
                            <>
                              <h2>{details[0]}</h2>
                              <p>{details[1]}</p>{" "}
                            </>
                          );
                        })}

                      <center>
                        <a
                          href={
                            blogData &&
                            blogData.content?.linktobuy + getLinkTg()
                          }
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <Button type="primary">
                            <ThunderboltOutlined />

                            <strong>CHECK THE PRODUCT</strong>
                          </Button>
                        </a>
                        <a
                          href={getLinkTg(
                            blogData && blogData.content?.linktobuy
                          )}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <Button type="primary"></Button>
                        </a>
                      </center>
                    </div>
                  )}
                </div>
                &nbsp;
              </Col>
              &nbsp; &nbsp;
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                style={{ padding: 20 }}
              >
                <div style={{ padding: 20 }}>
                  <h2>Related Topics</h2>
                </div>
                <ul>
                  {relTitels &&
                    relTitels.length > 0 &&
                    relTitels.map((item, index) => {
                      return (
                        <li>
                          {" "}
                          <Link
                            to={`/blogs/${category}/${slugify(
                              item.slug || item.title
                            )}/${item.id}`}
                          >
                            {item.title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </Col>
            </Row>
          )}
        </Content>
        <div style={{ margin: 0, textAlign: "center" }}>
          {" "}
          <ScrollButton />
        </div>
        <Footers />
      </Layout>
    </div>
  );
};

export default BlogDetails;
