import LayoutHome from "./components/layouts/LayoutHome";
import Homepage from "./components/pages/home/Homepage";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/login/PrivateRoute";
import { useEffect } from "react";
import Privacy from "./commons/Privacy";
import Contact from "./commons/Contact";
import ErrorPage from "./components/layouts/404";
import SingleProduct from "./components/pages/products/SingleProduct";
import Affiiate from "./commons/affiiate";
import About from "./commons/About";
import "./App.css";
import SEO from "./seo/SEO";
import SingleProductWallmart from "./components/pages/products/SingleProductWallmart";
import Blogs from "./components/pages/blogs/Blogs";
import Disclaimer from "./commons/Disclaimer";
import BlogDetails from "./components/pages/blogs/BlogDetails";
import DemoBlog from "./components/pages/blogs/DemoBlog";
import JToXConverter from "./components/pages/tools/JToXConverter";
import EMICalculator from "./components/pages/tools/EMICalculator";
import Xml2Json from "./components/pages/tools/Xml2Json";
function App() {
  //detect country

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <div>
      {/* <SEO /> */}

      <Routes>
        <Route exact path="/" element={<Homepage loc="/" />} />
        <Route exact path="/electronics/:slug" element={<SingleProduct />} />
        <Route
          exact
          path="/electronics"
          element={<Homepage loc="electronics" />}
        />
        <Route
          exact
          path="/projectors"
          element={<Homepage loc="projectors" />}
        />
        <Route exact path="/projectors/:slug" element={<SingleProduct />} />
        <Route exact path="/oled-tv" element={<Homepage loc="oled-tv" />} />
        <Route exact path="/oled-tv/:slug" element={<SingleProduct />} />
        <Route exact path="/oled-tv" element={<Homepage loc="oled-tv" />} />
        <Route exact path="/oled-tv/:slug" element={<SingleProduct />} />
        <Route
          exact
          path="/appliances"
          element={<Homepage loc="appliances" />}
        />
        <Route exact path="/appliances/:slug" element={<SingleProduct />} />
        <Route
          exact
          path="/car-accessories"
          element={<Homepage loc="car-accessories" />}
        />
        <Route
          exact
          path="/car-accessories/:slug"
          element={<SingleProduct />}
        />
        <Route exact path="/products/:slug" element={<SingleProduct />} />
        <Route
          exact
          path="/appliances/walmart"
          element={<SingleProductWallmart />}
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute user={true}>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route
          path="hi"
          element={
            <PrivateRoute user={false}>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route
          exact
          path="/blogs/:category/:slug/:bid"
          element={<BlogDetails />}
        />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/affiliate" element={<Affiiate />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/json-to-xml" element={<JToXConverter />} />
        <Route exact path="/xml-to-json" element={<Xml2Json />} />

        <Route exact path="/home-loan-calculator" element={<EMICalculator />} />
        {/* <Route path="landing" element={<Landing />} />
    
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="analytics" element={<Analytics />} />
      <Route path="admin" element={<Admin />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
