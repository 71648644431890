// const title = "Example Website Page";
// const url = "https://www.example.com/page-to-share";
// const imageUrl = "https://www.example.com/image-to-share.jpg";
import { FaInstagram } from "react-icons/fa";

const ShareOnInstagram = ({ url, imageUrl, title }) => {
  // const url = "https://www.example.com/page-to-share";
  const handleShare = () => {
   // const shareUrl = `https://www.instagram.com/?url=${url}&media=${imageUrl}&title=${title}`;
  //  window.open(shareUrl, "_blank");
  };
  const shareUrl = `https://www.instagram.com/?url=${url}&media=${imageUrl}&title=${title}`;
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={shareUrl}
      className="fb-xfbml-parse-ignore"
    >
      {" "}
      <FaInstagram
        // onClick={handleShare}
        size={30}
        style={{ color: "#E26868" }}
      />
    </a>
  );
};
export default ShareOnInstagram;
