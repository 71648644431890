import { Breadcrumb, Col, Layout, Menu, Row } from "antd";

import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";

import React from "react";
import Headers from "./headers/Headers";
import Footers from "./footers/Footers";
import { NavLink } from "react-router-dom";
import HeadersCopy from "./headers/Headers copy";
import ScrollButton from "./ScrollButton";
const { Content } = Layout;

const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

const LayoutHome = ({ children }) => {
  return (
    <Layout className="layout">
      <HeadersCopy />
      <Content
        style={{
          padding: "0 20px",
        }}
      >
        <div className="site-layout-content" style={{ minHeight: "500" }}>
          {children}
        </div>
      </Content>
      <div style={{ margin: 0, textAlign: "center" }}>
        {" "}
        <ScrollButton />
      </div>
      <Footers />
    </Layout>
  );
};

export default LayoutHome;
