import { Button, Col, List, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";
import SEO from "../../../seo/SEO";
import {
  getCategoryProducts,
  SIDE_BAR_TITLES,
} from "../../../store/actions/homepageActions";
import { blogDataSample, bolgsData } from "../../datajson/blogsData";
import ProductCard from "../electronics/ProductCard";
import BlogCard from "./BlogCard";

const BlogsListPagination = ({ path = "", loc = "blogs", data = [] }) => {
  const blogData = useSelector((state) => state.home.getAllBlogs);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const blogDataSearch = useSelector((state) => state.home.searchResults) || [];
  const [blogsData, setBlogsData] = useState(blogData ||[]);

  // useEffect(() => {
  //   setBlogsData(blogDataSearch);
  //   console.log("blogDataSearch ", blogDataSearch.length);
  //   return () => {};
  // }, [blogDataSearch]);

  useEffect(() => {
    setLoading(true);
    if (blogData) {
      setLoading(false);
    }
    return () => {};
  }, []);

  useEffect(() => {
    // setSortPageData(filterPageProducts);
    return () => {};
  }, []);

  const handleSort = () => {};

  const category = "electronics";

  return (
    <Row>
      {/* <SEO
        title={path.substring(1, loc.length)}
        description={"Categories"}
        article={loc}
      /> */}
      {loading ? (
        <Col
          span={24}
          style={{
            textAlign: "center",
            height: 500,
            paddingTop: "8%",
          }}
        >
          <Spin style={{ textAlign: "center" }} size="large" tip="Loading..." />
        </Col>
      ) : (
        <div className="list-parent">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={16}
              xl={16}
              //style={{ backgroundColor: "yellow" }}
            >
              <List
                // grid={{ gutter: [24, 12], column: 4 }}
                grid={{
                  gutter: 12,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                pagination={{
                  onChange: (page) => {},
                  pageSize: 10,
                  size: "default",
                  hideOnSinglePage: true,
                }}
                dataSource={blogData && blogData.reverse()}
                renderItem={(item, key) => (
                  <List.Item key={key}>
                    <BlogCard data={item} path={path} loc={loc} />{" "}
                  </List.Item>
                )}
                style={{ marginTop: 2 }}
              />
            </Col>
            &nbsp; &nbsp;
            <Col xs={24} sm={24} md={12} lg={8} xl={7} style={{ padding: 20 }}>
              <div style={{ padding: 20 }}>
                <h2>Related Topics</h2>
              </div>
              <ul>
                {blogsData &&
                  blogsData.length > 0 &&
                  blogsData
                    .reverse()
                    .slice(10, 30)
                    .map((item, index) => {
                      return (
                        <li>
                          {" "}
                          <Link
                            to={`/blogs/${category}/${slugify(
                              item.data.slug ? item.data.slug : item.data.title
                            )}/${item.data.id}`}
                            state={{ passdata: item.data.title }}
                          >
                            {item.data.title}
                          </Link>
                        </li>
                      );
                    })}
              </ul>
            </Col>
          </Row>
          {/* <List
            // grid={{ gutter: [24, 12], column: 4 }}
            grid={{
              gutter: 12,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 4,
              xl: 4,
              xxl: 3,
            }}
            pagination={{
              onChange: (page) => {},
              pageSize: 12,
              size: "default",
              hideOnSinglePage: true,
            }}
            dataSource={blogData}
            renderItem={(item, key) => (
              <List.Item key={key}>
                <ProductCard data={item} path={path} loc={loc} />{" "}
              </List.Item>
            )}
            style={{ marginTop: 2 }}
          /> */}
          &nbsp;
        </div>
      )}
    </Row>
  );
};

export default BlogsListPagination;
