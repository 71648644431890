import { Button, Col, Divider, List, Rate, Row } from "antd";
import React, { useEffect, useState } from "react";
import ProductCardSimilar from "../electronics/ProductCardSimilar";
import {
  LaptopOutlined,
  NotificationOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { IconMap } from "antd/lib/result";
import { NavLink } from "react-router-dom";
import { proB0B5RPHBFR } from "../../datajson/projectors/usaB0B5RPHBFR";
import LayoutHome from "../../layouts/LayoutHome";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fitbit } from "../../datajson/wallmart/One";

//ecron-frontend\src\components\datajson\B09LP7GKGR.js

const SingleProductWallmart = () => {
  const { product } = fitbit;
  //onedB0B3S19V46; //uB09LP7GKGR;
  const [mainImage, setMainImage] = useState(product.main_image.link);
  const handleClick = (val) => {
    setMainImage(val);
  };
  return (
    <LayoutHome>
      &nbsp;
      <h3>{product.title}</h3>
      <Row>
        <Col>
          Price : {product.buybox_winner.price.currency_symbol}{" "}
          {product.buybox_winner.price}{" "}
          {product.rating && (
            <p>
              <Rate allowHalf defaultValue={product.rating} />
              <h5>Customer Rating : {product.rating}</h5>
            </p>
          )}
        </Col>
        <Col></Col>
        {/* {product.buybox_winner.rrp && (
          <Col style={{ marginLeft: 2 }}>
            Max Price :{" "}
            {product.buybox_winner.rrp && product.buybox_winner.rrp.symbol}
            <del>
              <strong>
                {product.buybox_winner.rrp && product.buybox_winner.rrp.value}
              </strong>
            </del>
          </Col>
        )} */}
      </Row>
      <Row>
        <Col
          xs={{
            span: 24,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          {" "}
          <LazyLoadImage
            src={mainImage}
            alt={product.title}
            width="90%"
            height={"90%"}
            effect="blur"
          />
        </Col>
        <Col
          xs={{
            span: 24,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          <Row>
            {product.images &&
              product.images.map((item, index) => (
                <Col
                  style={{ padding: 4 }}
                  xs={{
                    span: 12,
                  }}
                  md={{ span: 6 }}
                  lg={{
                    span: 6,
                  }}
                >
                  <LazyLoadImage
                    key={index}
                    src={item.link}
                    alt={product.title}
                    width="85%"
                    height={"85%"}
                    onClick={() => handleClick(item.link)}
                    onMouseEnter={() => handleClick(item.link)}
                    effect="blur"
                  />
                  &nbsp;
                </Col>
              ))}
          </Row>

          <Row justify="center" style={{ marginTop: 40 }}>
            <Col style={{ marginTop: 20, paddingLeft: 20 }}>
              <Button type="ghost">
                <HeartOutlined /> Add To Wish List
              </Button>
            </Col>
            <Col style={{ marginTop: 20, paddingLeft: 20 }}>
              <Button type="default">
                <ShoppingCartOutlined />
                Add To Cart
              </Button>
            </Col>
            <Col style={{ marginTop: 20, paddingLeft: 20 }}>
              <a
                href={product.link}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <Button type="primary">
                  <ThunderboltOutlined />

                  <strong>ORDER NOW</strong>
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      &nbsp;
      {product.videos && <h4>Vedios :</h4>}
      {/* <Row>
        {product.videos &&
          product.videos.slice(0, 9).map((item, index) => (
            <Col
              style={{ padding: 4 }}
              xs={{
                span: 12,
              }}
              md={{ span: 6 }}
              lg={{
                span: 6,
              }}
            >
              <video controls name="media" height={150} poster={item.thumbnail}>
                <source src={item.link} type="video/mp4" />
              </video>
              &nbsp;
            </Col>
          ))}
      </Row> */}
      <Divider style={{ width: 5 }} />
      <Row>
        <Col
          style={{ padding: 4 }}
          xs={{
            span: 24,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          <ul>
            <h4>Key Features of this Item: </h4>
            <Divider />
            <p>{product.description_full}</p>
            {/* {product.feature_bullets.map((item, index) => (
              <li>{item}</li>
            ))} */}
          </ul>
        </Col>
        <Col
          style={{ padding: 4 }}
          xs={{
            span: 12,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          <h4>Product information Specifications: </h4>
          <Divider />
          <div>{product.description_html}</div>
          {product.specifications.map((item, index) => (
            <>
              <strong>{item.name}</strong> : {item.value}
              <p />
            </>
          ))}
        </Col>
      </Row>
    </LayoutHome>
  );
};

export default SingleProductWallmart;
