import { Button, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import slugify from "react-slugify";
import { FaFacebook } from "react-icons/fa";
import FBShareButton from "../../../utilities/share/FBShareButton";
import WhatsappShareButton from "../../../utilities/share/WhatsappShareButtonProj";
import ShareOnInstagram from "../../../utilities/share/ShareOnInstagram";
import "./blog.css";

const BlogCard = ({ data }) => {
  const blogDataSampleData = data.data;

  const category = blogDataSampleData.category
    ? blogDataSampleData.category
    : "electronics";

  return (
    <Content style={{ margin: "24px 16px 0" }}>
      <div
        style={{
          padding: 24,
          minHeight: 260,
          background: "white",
        }}
      >
        <Row>
          {" "}
          <Col lg={7}>
            <br />

            <center>
              {" "}
              <LazyLoadImage
                src={blogDataSampleData.content.images[0].url}
                alt={blogDataSampleData.content.images[0].caption}
                width={"90%"}
                height={200}
                effect="blur"
              />
            </center>
          </Col>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <Col lg={14} style={{ padding: 20 }}>
            <br />
            <h2>{blogDataSampleData.title}</h2>
            <p>{blogDataSampleData.content.body.substring(0, 120) + "..."}</p>
            <center>
              <Button type="primary">
                <Link
                  to={`/blogs/${category}/${slugify(
                    blogDataSampleData.slug || blogDataSampleData.title
                  )}/${blogDataSampleData.id}`}
                  state={{ passdata: blogDataSampleData.title }}
                >
                  {"Here are " +
                    blogDataSampleData.title.substr(
                      0,
                      blogDataSampleData.title.indexOf(" ", 10)
                    ) +
                    "..."}
                </Link>
              </Button>
            </center>
            <Row>
              <Col>
                <FBShareButton
                  url={`https://ecronhub.com/blogs/${category}/${slugify(
                    blogDataSampleData && blogDataSampleData.title
                  )}/${blogDataSampleData && blogDataSampleData.id}`}
                />
              </Col>
              <Col style={{ marginLeft: 20 }}>
                <WhatsappShareButton
                  url={`https://ecronhub.com/blogs/${category}/${slugify(
                    blogDataSampleData && blogDataSampleData.title
                  )}/${blogDataSampleData && blogDataSampleData.id}`}
                  title={blogDataSampleData && blogDataSampleData.title}
                  separator=" - "
                  media={
                    blogDataSampleData &&
                    blogDataSampleData.content?.images[0].url
                  }
                />
              </Col>
              <Col style={{ marginLeft: 20 }}>
                <ShareOnInstagram
                  url={`https://ecronhub.com/blogs/${category}/${slugify(
                    blogDataSampleData && blogDataSampleData.title
                  )}/${blogDataSampleData && blogDataSampleData.id}`}
                  title={blogDataSampleData && blogDataSampleData.title}
                  imageUrl={
                    blogDataSampleData &&
                    blogDataSampleData.content?.images[0].url
                  }
                />
              </Col>
            </Row>
            {/* <FBShareButton
              url={`https://ecronhub.com/blogs/${category}/${slugify(
                blogDataSampleData.title
              )}/${blogDataSampleData.id}`}
            /> */}
            {/* {blogDataSampleData[0].deccription.map((item, key) => (
              <p>{item}</p>
            ))} */}
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default BlogCard;
