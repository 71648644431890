import React from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";

const PrivateRoute = ({ user, redirectPath = "/", children }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PrivateRoute;
