import React, { useState, useEffect } from "react";
import { notification, message } from "antd";

import {
  Dropdown,
  Layout,
  Menu,
  Avatar,
  Row,
  Col,
  Space,
  Carousel,
  Input,
  Button,
  Form,
  Select,
  AutoComplete,
} from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  LoginOutlined,
  SearchOutlined,
  FilterOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "./header.css";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCountry,
  setCountryName,
} from "../../../store/actions/homepageActions";
import Search from "antd/lib/transfer/search";
import Navbar from "./Navbar";
// import Input from "antd/lib/input/Input";
const { Option } = Select;
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "grey",
};

const { Header } = Layout;
const HeadersCopy = () => {
  const MENU = (
    <Menu className="header-menu">
      <Menu.Item key="1">
        <h4>
          <strong>Guest</strong>
        </h4>
      </Menu.Item>
      <Menu.Item key="2">
        <LoginOutlined />
      </Menu.Item>
    </Menu>
  );
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const [country, setCountry] = useState(
    localStorage.getItem("Country") !== undefined
      ? localStorage.getItem("Country")
      : "USA"
  );
  const dispatch = useDispatch();
  useEffect(() => {
    //setCountry(localStorage.getItem("Country"));
    return () => {};
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("Country") === undefined ||
      localStorage.getItem("Country") === null
    ) {
      dispatch(getCountry());
    } //else {
    //   setCountryName(localStorage.getItem("Country"));
    // }
  }, []);

  const openNotification = () => {
    notification.open({
      message: "Notification Title",
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      onClick: () => {},
    });
  };
  const handleSelect = (e) => {
    localStorage.setItem("Country", e);
    navigate("/");
    // openNotification();
    // setTimeout(() => {
    //   message.success("Country change successfully");
    // }, 2000);
    window.location.reload();
    dispatch(setCountryName(e));
  };

  const options = [
    // { label: "One", value: "One" },
    // { label: "Two", value: "Two" },
    // { label: "Three", value: "Three" },
    // { label: "Four", value: "Four" },
    // { label: "Five", value: "Five" },
  ];

  const handleSearch = (e) => {
    if (search) {
    }
  };

  const searchText = (
    <Form.Item>
      <AutoComplete
        name="search"
        onChange={(val) => setSearch(val)}
        onSelect={(val) => setSearch(val)}
        dataSource={options}
        value={search}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
        <Input
          name="search"
          suffix={<SearchOutlined />}
          placeholder="Search Products, category, top rated products etc"
        ></Input>
      </AutoComplete>
      <Button type="primary" onClick={() => handleSearch()}>
        <SearchOutlined /> Search
      </Button>
    </Form.Item>
  );

  const items = ["ECRON HUB ", searchText];

  return (
    <>
      <Navbar />
      {false && (
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Carousel autoplay>
              <div>
                <Row justify="center">
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                </Row>
                {/* <h3 style={contentStyle}>
                  {" "}
                  <img
                    src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                    alt="data.title"
                    width="350px"
                    height="150px"
                  />
                </h3> */}
              </div>
              <div>
                <Row justify="center">
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row justify="center">
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                </Row>{" "}
              </div>
              <div>
                <Row justify="center">
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src="https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg"
                      alt="data.title"
                      width="350px"
                      height="150px"
                    />
                  </Col>
                </Row>
              </div>
            </Carousel>
          </Col>
        </Row>
      )}
    </>
  );
};

export default HeadersCopy;
