import styled from "styled-components";

export const Heading = styled.h1`
  /* text-align: center; */
  color: pink;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 40px;
  align: center,
  height: 10px;
  font-size: 3rem;
  z-index:  1;
  cursor: pointer;
  color: red;
`;
