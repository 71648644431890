import React from "react";
import { Col, Row } from "antd";
import LayoutHome from "../components/layouts/LayoutHome";
import MailchimpForm from "../components/mailchimp/MailchimpFormContainer";

const Disclaimer = () => {
  return (
    <LayoutHome>
      <Row>
        <Col
          xs={{
            span: 24,
          }}
          //   md={{ span: 12 }}
          //   lg={{
          //     span: 12,
          //   }}
        >
          <p style={{ padding: 10, minHeight: 500 }}>
            <p>
              <strong>Disclaimer: </strong>
            </p>
            The Ecronhub.com is owned by Ecronsoft App Pvt Ltd, This site is a
            personal blog and affiliate site. The information provided on this
            site is for general informational purposes only and should not be
            considered as professional advice. While we strive to keep the
            information up to date and correct, we make no representations or
            warranties of any kind, express or implied, about the completeness,
            accuracy, reliability, suitability or availability with respect to
            the website or the information, products, services, or related
            graphics contained on the site for any purpose. Any reliance you
            place on such information is strictly at your own risk.
            <br /> <br /> This site may contain affiliate links. This means that
            if you click on a link and purchase a product or service, we may
            receive a commission. The commission is paid by the advertiser and
            does not affect the price you pay for the product or service.
            Additionally, we may receive compensation for some of the products
            or services we recommend.
            <br /> <br />
            The opinions expressed on this site are our own and do not represent
            the views of any company or advertiser. The information contained on
            this site should not be relied upon as a substitute for professional
            financial or legal advice. Always do your own research and seek the
            advice of a professional before making any financial or legal
            decisions.
            <br /> <br />
            This site assumes no responsibility for errors or omissions in the
            contents of the site. In no event will we be liable for any loss or
            damage including without limitation, indirect or consequential loss
            or damage, or any loss or damage whatsoever arising from loss of
            data or profits arising out of, or in connection with, the use of
            this site. <br /> <br />
            Through this site, you are able to link to other websites which are
            not under our control. We have no control over the nature, content,
            and availability of those sites. The inclusion of any links does not
            necessarily imply a recommendation or endorse the views expressed
            within them.
            <br /> <br /> This disclaimer is subject to change without notice.
            By using this site, you agree to the terms of this disclaimer. If
            you do not agree with any part of this disclaimer, you should stop
            using this site immediately.
            <br />
            <br />
          </p>
        </Col>
      </Row>
    </LayoutHome>
  );
};

export default Disclaimer;
