import { Button, Col, List, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import slugify from "react-slugify";
import SEO from "../../../seo/SEO";
import {
  dump_existing_products,
  getBlogs,
  getCategoryProducts,
} from "../../../store/actions/homepageActions";
import { getPageProducts } from "../../../utilities/findPageProducts";
import { writeXml } from "../../../utilities/writeCustomizedJson";
import BlogCard from "../blogs/BlogCard";
import BlogsListPagination from "../blogs/BlogsListPagination";
import ProductCard from "./ProductCard";

const ProductsListPagination = ({ path = "", loc = "" }) => {
  const dispatch = useDispatch();
  const [sortToggle, setSortToggle] = useState(false);
  const [allCategoryProducts, setAllCategoryProducts] = useState([]);
  const [filterPageProducts, setFilterPageProducts] = useState([]);
  const [sortPageData, setSortPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const productsDataArray =
    useSelector((state) => state.home.productsDataArrays) || [];
  const blogDatafrom = useSelector((state) => state.home.getAllBlogs) || [];
  const [blogsData, setBlogsData] = useState([]);
  const searchResults = useSelector((state) => state.home.searchResults) || [];
  //getPageProducts(path);
  const getListProducts = async () => {
    setLoading(true);
    const res = await dispatch(getCategoryProducts(loc));
    if (res && res.length > 0) {
      // console.log(" res ==>", res, res?.product);
      res && setAllCategoryProducts(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getBlogData = async () => {
    const res = await dispatch(getBlogs());
    if (res) {
      res && setBlogsData(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (path === "/") {
      setLoading(true);
      getListProducts();
    }

    if (path !== "/" && productsDataArray && productsDataArray.length < 1) {
      setLoading(true);
      getListProducts();
    } else {
      setAllCategoryProducts(productsDataArray);
    }
    return () => {};
  }, []);

  useEffect(() => {
    setFilterPageProducts(allCategoryProducts);

    return () => {};
  }, [allCategoryProducts]);

  useEffect(() => {
    if (blogDatafrom.length > 0) {
      const dataValue = blogDatafrom.sort(function (a, b) {
        return b.data.id - a.data.id;
      });
      setBlogsData(dataValue);
    } else {
      getBlogData();
    }
    return () => {};
  }, []);

  useEffect(() => {
    setSortPageData(filterPageProducts);
    return () => {};
  }, [filterPageProducts]);

  // const writeXml = (lox, bycat) => {
  //   const array = [];
  //   bycat.map((item, i) => {
  //     array.push({
  //       url: {
  //         loc:
  //           "https://www.ecronhub.com/" +
  //           lox +
  //           "/" +
  //           slugify(item.title) +
  //           "-" +
  //           item.asin,
  //         changefreq: 1,
  //         priority: 1,
  //       },
  //     });
  //   });

  //   console.log("array ", array);
  // };

  useEffect(() => {
    //setFilterPageProducts(getPageProducts(path));
    let byCategory = [];
    if (allCategoryProducts) {
      if (path === "/") {
        byCategory = allCategoryProducts;
        setFilterPageProducts(byCategory);
      } else if (path === "/electronics") {
        byCategory = allCategoryProducts.filter(
          (item) =>
            item.categories[0].name.includes("Smartphones") ||
            item.categories[0].name.includes("Electronics") ||
            item.categories[0].name.toLowerCase().includes("cell phones") ||
            item.categories[0].name.toLowerCase().includes("cell phones") ||
            item.categories[0].name.toLowerCase().includes("home theater audio")
        );
        //writeXml(loc, byCategory);

        setFilterPageProducts(byCategory);
      } else if (path === "/projectors") {
        byCategory = allCategoryProducts.filter(
          (item) =>
            item.categories[0].name.includes("Video Projectors") ||
            item.title.toLowerCase().includes("projector")
        );
        //writeXml(loc, byCategory);
        setFilterPageProducts(byCategory);
      } else if (path === "/oled-tv") {
        byCategory = allCategoryProducts.filter((item) => {
          return (
            item.categories[0].name.includes("OLED TVs") ||
            item.title.toLowerCase().includes("oled")
          );
        });
        //writeXml(loc, byCategory);

        setFilterPageProducts(byCategory);
      } else if (path === "/appliances") {
        byCategory = allCategoryProducts.filter(
          (item) =>
            item.categories[0].name.includes("Refrigerators") ||
            item.categories[0].name.includes("Washers & Dryers") ||
            item.categories[0].name.includes("Washing Machines & Dryers") ||
            item.categories[0].name.includes("Steam Cleaners & Steam Mops")
        );
        // writeXml(loc, byCategory);
        setFilterPageProducts(byCategory);
        setSortPageData(byCategory);
      } else if (path === "/car-accessories") {
        byCategory = allCategoryProducts.filter((item) =>
          item.categories[0].name.includes("Car Electronics & Accessories")
        );
        //writeXml(loc, byCategory);
        setFilterPageProducts(byCategory);
        setSortPageData(byCategory);
      }
    }

    const category = allCategoryProducts && [
      ...new Set(allCategoryProducts.map((item) => item.categories[0].name)),
    ];

    //console.log("category", category, filterPageProducts);

    // filterPageProducts.forEach((element) => {
    //   console.log(element.categories[0]);
    // });

    return () => {};
  }, [loc, allCategoryProducts]);

  const handleSort = (val) => {
    if (val === "rate") {
      let dataSort = filterPageProducts && filterPageProducts;
      // console.log("dataSort ", dataSort);

      const dataRate =
        sortToggle === true
          ? dataSort.sort(function (a, b) {
              return a.rating - b.rating;
            })
          : dataSort.sort(function (a, b) {
              return b.rating - a.rating;
            });
      setSortPageData(dataRate);
      setSortToggle(!sortToggle);
    } else if (val === "featured") {
    } else if (val === "LTH") {
      const filterPrice = filterPageProducts.filter(
        (item) => item.price !== undefined && item.price.value > 0
      );
      const dataValue = filterPrice.sort(function (a, b) {
        return a.price.value - b.price.value;
      });
      setSortPageData(dataValue);
    } else if (val === "HTL") {
      const filterPrice = filterPageProducts.filter(
        (item) => item.price !== undefined && item.price.value > 0
      );
      const dataValue = filterPrice.sort(function (a, b) {
        return b.price.value - a.price.value;
      });
      setSortPageData(dataValue);
    } else if (val === "Reset") {
      setSortPageData(filterPageProducts);
    }
  };

  return (
    <Row>
      <SEO
        title={
          "Best " +
          path.substring(1).charAt(0).toUpperCase() +
          path.substring(2) +
          " related Category Products"
        }
        // description={"Categories"}
        //article={loc}
      />
      {loading ? (
        <Col
          span={24}
          style={{
            textAlign: "center",
            height: 500,
            paddingTop: "8%",
          }}
        >
          <Spin style={{ textAlign: "center" }} size="large" tip="Loading..." />
        </Col>
      ) : (
        <div className="list-parent">
          <Row xs={24} sm={24} md={24} lg={12} xl={12}>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <Button onClick={() => handleSort("rate")}>
                Sort by Customer Rating
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <Button onClick={() => handleSort("LTH")}>
                Sort by Low to High{" "}
              </Button>
            </Col>
            {/* <Button onClick={() => handleSort("featured")}>
            Sort by Featured
          </Button>
          &nbsp; */}
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <Button onClick={() => handleSort("HTL")}>
                Sort by High to Low{" "}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <Button
                type="primary"
                style={{ width: 100 }}
                onClick={() => handleSort("Reset")}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <List
            // grid={{ gutter: [24, 12], column: 4 }}
            grid={{
              gutter: 12,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 4,
              xl: 4,
              xxl: 3,
            }}
            pagination={{
              onChange: (page) => {},
              pageSize: 12,
              size: "default",
              hideOnSinglePage: true,
            }}
            dataSource={sortPageData}
            renderItem={(item, key) => (
              <List.Item key={key}>
                <ProductCard data={item} path={path} loc={loc} />{" "}
              </List.Item>
            )}
            style={{ marginTop: 2 }}
          />
          &nbsp;
          {blogsData && blogsData.length > 0 && (
            <div>
              <h2> Recent Blog Articles</h2>
              <section className="recent-blogs">
                <List
                  // grid={{ gutter: [24, 12], column: 4 }}
                  grid={{
                    gutter: 12,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                  }}
                  pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                    size: "default",
                    hideOnSinglePage: true,
                  }}
                  dataSource={blogsData && blogsData}
                  renderItem={(item, key) => (
                    <List.Item key={key}>
                      <BlogCard data={item} path={path} loc={loc} />{" "}
                    </List.Item>
                  )}
                  style={{ marginTop: 2 }}
                />
              </section>
            </div>
          )}
          &nbsp;
        </div>
      )}
    </Row>
  );
};

export default ProductsListPagination;
