import { Button, Col, Menu, Row } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CopyrightOutlined } from "@ant-design/icons";
import SubscriptionForm from "./SubscriptionForm";

const Footers = () => {
  const [modal1Open, setModal1Open] = useState(false);

  const handleSubsribeVisible = () => {
    setModal1Open(true);
  };

  return (
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      <Row justify="center">
        <Col
          xs={{
            span: 24,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          <p style={{ justifyContent: "center", marginLeft: 10 }}>
            AS AN AMAZON AND OTHER ASSOCIATE THE ECRONHUB EARN FROM QUALIFYING
            PURCHASES OR OTHER REFFEREL.{" "}
            <NavLink to="/">WWW.ECRONHUB.COM</NavLink> IS AN AFFILIATE PROGRAM
            DESIGNED TO PROVIDE A MEANS FOR SITES TO EARN ADVERTISING FEES BY
            ADVERTISING AND LINKING TO{" "}
            <strong>AMAZON OR OTHER AFFILIATED LINK</strong> . THE ECRONHUB IS
            READER-SUPPORTED. WHEN YOU BUY OR REGISTER THROUGH LINKS ON OUR
            SITE, WE MAY EARN AN AFFILIATE COMMISSION
          </p>
        </Col>
        <Col
          // style={{ marginLeft: 50 }}
          xs={{
            span: 12,
          }}
          md={{ span: 6 }}
          lg={{
            span: 6,
          }}
          style={{ justifyContent: "center" }}
        >
          <Row justify="center">
            <Menu theme="dark">
              <Menu.Item key="22">
                <NavLink to="/privacy">
                  <strong>Privacy policy</strong>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="26">
                <NavLink to="/disclaimer">
                  <strong>Disclaimer</strong>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="23">
                <NavLink to="/contact">
                  <strong>Contact</strong>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="24">
                <NavLink to="/affiliate">
                  <strong>Affliate Disclosure</strong>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="25">
                <NavLink to="/about">
                  <strong>About</strong>
                </NavLink>
              </Menu.Item>
            </Menu>
          </Row>
        </Col>
        <Col
          xs={{
            span: 12,
          }}
          md={{ span: 6 }}
          lg={{
            span: 6,
          }}
        >
          <Row justify="center">
            {
              <Button type="primary" onClick={handleSubsribeVisible}>
                Subscribe Us
              </Button>
            }
          </Row>
          <Row justify="center">
            {
              <p style={{ justifyContent: "center", marginLeft: 10 }}>
                <CopyrightOutlined /> {new Date().getFullYear()}. All Rigts
                Reserved
              </p>
            }
          </Row>
        </Col>
      </Row>
      <SubscriptionForm
        subsribeVisible={modal1Open}
        setModal1Open={setModal1Open}
      />
    </Footer>
  );
};

export default Footers;
