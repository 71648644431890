import { Breadcrumb } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../seo/SEO";
import LayoutHome from "../../layouts/LayoutHome";
import { Form, Input, Button, message } from "antd";
// import { js2xml } from "xml-js";
import { format } from "xml-formatter";

const JToXConverter = () => {
  const [jsonData, setJsonData] = useState("");
  const [xmlData, setXmlData] = useState("");

  const handleConvert = () => {
    try {
      // js2xmlparser .parse("person", jsonData)
      // const data = JSON.parse(jsonData);
      // const options = { compact: true, ignoreComment: true, spaces: 4 };
      // const xml = js2xml(data, options);
      // const formattedXml = format(xml);
      // setXmlData(formattedXml);
    } catch (error) {
      message.error("Invalid JSON data");
    }
  };

  return (
    <LayoutHome>
      <SEO />
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Form>
          <Form.Item label="JSON Data">
            <Input.TextArea
              value={jsonData}
              onChange={(e) => setJsonData(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="XML Data">
            <Input.TextArea value={xmlData} readOnly />
          </Form.Item>
          <Button type="primary" onClick={handleConvert}>
            Convert
          </Button>
        </Form>
      </div>
    </LayoutHome>
  );
};

export default JToXConverter;
