import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  List,
  Rate,
  Row,
  Space,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { IconMap } from "antd/lib/result";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { onedB0B3S19V46 } from "../../datajson/oledTv/usa1_B0B3S19V46";
import LayoutHome from "../../layouts/LayoutHome";
import ProductCardSimilar from "../electronics/ProductCardSimilar";
import { useDispatch, useSelector } from "react-redux";
import { inB09GFLFMPS } from "../../datajson/inB09GFLFMPS";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { oledTvs } from "../../datajson/oledTv/oled";
import { getLinkTg } from "../../../utilities/getLinkRef";
import { usaProjectors } from "../../datajson/projectors/usaProjectors";
import { appliancesWashingMachines } from "../../datajson/appliances/appliances";
import { usaAppliances } from "../../datajson/appliances/usaAppliances";
import SEO from "../../../seo/SEO";
import { writeSiteMapXml } from "../../../utilities/writeXml";
import { reverseString } from "../../../utilities/reverseString";
import { inIPhones_p1 } from "../../datajson/electronics/inElectronics";
import { dataElectronicsIn } from "../../datajson/electronics/electronicsProductsInd";
import { inProjecrorsDetails } from "../../datajson/projectors/inProjectors";
import { oledTvsIndDetailsList } from "../../datajson/oledTv/oledTvsDelailsListInd";
import { getProductByAsin } from "../../../store/actions/homepageActions";
import FBShareButton from "../../../utilities/share/FBShareButton";
// import { WhatsappShareButton } from "react-share";
import ShareOnInstagram from "../../../utilities/share/ShareOnInstagram";
import WhatsappShareButton from "../../../utilities/share/WhatsappShareButtonProj";
import WhatsappShareButtonProj from "../../../utilities/share/WhatsappShareButtonProj";
import FBFullShare from "../../../utilities/share/FBFullShare";
import MetaTag from "../../../seo/MetaTag";
import { seoDataJsn } from "../../datajson/seoData";

const SingleProduct = () => {
  const loaction = useLocation();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const seoDataJs = seoDataJsn;
  const [seoData, setSeoData] = useState({
    title:
      "Car Phone Holder for CD Slot, Cell Phone Mount for CD Player, Magnetic or One Button Release, Silicone Pad Protection and 360° Rotation, Compatible with All iPhone, Android, Smartphones",
    image: "https://m.media-amazon.com/images/I/41mWDSsDxnL._AC_UY218_.jpg",
    asin: "B0BLNC7PPB",
  });

  const currentUrl = window.location.href;
  const { pathname } = useLocation();

  const asinFromUrl = slug.split("-");
  const asinNo = asinFromUrl[asinFromUrl.length - 1];
  //reverseString(id).toLocaleUpperCase();
  const [singleProductDetail, setSingleProductDetail] = useState();

  const [singleProductDetails, setSingleProductDetails] = useState([]);
  const [product, setProduct] = useState();
  const [compare_with_similar, setCompare_with_similar] = useState([]);
  const [request_metadata, setRequest_metadata] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [loading, setLoading] = useState(false);
  const getLink = (val) => {
    const splitVal = val && val.split("?");
    return splitVal && splitVal.length > 1
      ? splitVal[0] + getLinkTg() + splitVal[1]
      : splitVal + getLinkTg();
  };

  useEffect(() => {
    const filteredSEO = seoDataJsn.filter((item, i) => {
      return item.asin === asinNo;
    });
    setSeoData(filteredSEO[0]);
  }, [asinNo]);

  useEffect(() => {
    setLoading(true);
    getProduct(asinNo);

    // const allPrductsList = oledTvs
    //   .concat(usaProjectors)
    //   .concat(appliancesWashingMachines)
    //   .concat(usaAppliances)
    //   .concat(dataElectronicsIn)
    //   .concat(inProjecrorsDetails)
    //   .concat(oledTvsIndDetailsList);

    // const singleProductDetail = allPrductsList.filter((ite) => {
    //   if (ite.request_parameters.asin === asinNo) {
    //     return true;
    //   } else return false;
    // });

    // const singleProductDetailUs = allPrductsList.filter((ite) => {
    //   if (ite.request_parameters.amazon_domain === "amazon.com") {
    //     return true;
    //   } else return false;
    // });

    // console.log("singleProductDetailUs", singleProductDetailUs);
  }, []);

  const getProduct = async (asin) => {
    // console.log("getProduct " + 2);
    const res = await dispatch(getProductByAsin(asin));
    // console.log("getProduct " + res + 3);
    if (res && res.request_parameters && res.request_parameters.asin) {
      res && setSingleProductDetail(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
    //console.log(" res ==>", res?.product);
  };
  useEffect(() => {
    singleProductDetail && setSingleProductDetails(singleProductDetail);
    setCompare_with_similar(
      singleProductDetail && singleProductDetail.compare_with_similar
    );
    setRequest_metadata(
      singleProductDetail && singleProductDetail.request_metadata
    );
    setMainImage(
      singleProductDetail &&
        singleProductDetail.product &&
        singleProductDetail.product.main_image.link
    );
  }, [singleProductDetail]);

  // console.log("singleProductDetail ", singleProductDetail);

  useEffect(() => {
    setProduct(singleProductDetails.product);
    //return () => {};
  }, [singleProductDetails]);

  const handleClick = (val) => {
    setMainImage(val);
  };
  return (
    <LayoutHome>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>&nbsp;{"/"}&nbsp;
          <Link to={"/" + pathname.split("/")[1]}>
            {pathname.split("/")[1].charAt(0).toUpperCase() +
              pathname.split("/")[1].substring(1)}
          </Link>
          <Link>{" / " + seoData?.title?.substring(0, 60)}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {loading ? (
        <Col
          span={24}
          style={{
            textAlign: "center",
            height: 500,
            paddingTop: "8%",
          }}
        >
          <Spin style={{ textAlign: "center" }} size="large" tip="Loading..." />
        </Col>
      ) : (
        <>
          <SEO
            title={seoData && seoData.title.substring(0, 50)}
            description={
              product && product.description
                ? product.description.substring(0, 120)
                : product && product.title
            }
            image={seoData?.image}
            article={product && product.title}
            keywords={product && product.keywords_list}
          />

          <h1>{product && product.title}</h1>
          <Row>
            <Col>
              Price :{" "}
              {product &&
                product.buybox_winner &&
                product.buybox_winner.price &&
                product.buybox_winner.price.symbol &&
                product.buybox_winner.price.symbol}{" "}
              {product &&
                product.buybox_winner &&
                product.buybox_winner.price &&
                product.buybox_winner.price.value &&
                product.buybox_winner.price.value}{" "}
              <div style={{ display: "flex" }}>
                {product && product.rating && (
                  <Rate allowHalf defaultValue={product.rating} />
                )}{" "}
                <h5 style={{ margin: 10 }}>
                  <strong>{product && product.rating} : Rating</strong>
                </h5>
              </div>
              &nbsp;
            </Col>
            <Col></Col>
            {product && product.buybox_winner.rrp && (
              <Col style={{ marginLeft: 2 }}>
                Max Price : {product && product.buybox_winner.rrp.symbol}
                <del>
                  <strong>{product && product.buybox_winner.rrp.value}</strong>
                </del>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xs={{
                span: 24,
              }}
              md={{ span: 12 }}
              lg={{
                span: 12,
              }}
            >
              {" "}
              <LazyLoadImage
                src={mainImage}
                alt={product && product.title}
                width="100%"
                height={450}
                effect="blur"
              />
            </Col>
            <Col
              xs={{
                span: 24,
              }}
              md={{ span: 12 }}
              lg={{
                span: 12,
              }}
            >
              <Row>
                {product &&
                  product.images.slice(0, 9).map((item, index) => (
                    <Col
                      style={{ padding: 4 }}
                      xs={{
                        span: 12,
                      }}
                      md={{ span: 6 }}
                      lg={{
                        span: 6,
                      }}
                    >
                      {" "}
                      <LazyLoadImage
                        key={index}
                        src={item.link}
                        alt={product.title}
                        width="80%"
                        height={"80%"}
                        onClick={() => handleClick(item.link)}
                        onMouseEnter={() => handleClick(item.link)}
                        effect="blur"
                      />
                      &nbsp;
                    </Col>
                  ))}
              </Row>
              {product && !loading ? (
                <Row justify="center" style={{ marginTop: 40 }}>
                  <Col style={{ marginTop: 20, paddingLeft: 20 }}>
                    <Button type="ghost">
                      <HeartOutlined /> Add To Wish List
                    </Button>
                  </Col>
                  <Col style={{ marginTop: 20, paddingLeft: 20 }}>
                    <Button type="default">
                      <ShoppingCartOutlined />
                      Add To Cart
                    </Button>
                  </Col>
                  <Col style={{ marginTop: 20, paddingLeft: 20 }}>
                    <a
                      href={getLink(request_metadata.amazon_url)}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <Button type="primary">
                        <ThunderboltOutlined />

                        <strong>ORDER NOW</strong>
                      </Button>
                    </a>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" style={{ marginTop: 40 }}>
                  <Col
                    style={{
                      marginTop: 20,
                      paddingLeft: 20,
                      width: "80%",
                      height: 100,
                    }}
                  >
                    <Link to="/">
                      <Button
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        <ThunderboltOutlined />
                        <strong>OOPS! There is a error.. Goto Home</strong>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Divider style={{ width: 5 }} />
          <Row>
            <Col
              style={{ padding: 4 }}
              xs={{
                span: 24,
              }}
              md={{ span: 24 }}
              lg={{
                span: 24,
              }}
            >
              {product && product.description && (
                <div>
                  <h2>Product Description: </h2>
                  <p>{product && product.description}</p>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FBShareButton
                url={currentUrl}
                title={product && product.title}
                imageUrl={mainImage}
              />
            </Col>
            <Col style={{ marginLeft: 20 }}>
              <WhatsappShareButtonProj
                url={currentUrl}
                title={product && product.title}
                imageUrl={mainImage}
              />
            </Col>
            <Col style={{ marginLeft: 20 }}>
              <ShareOnInstagram
                url={currentUrl}
                title={product && product.title}
                imageUrl={mainImage}
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{ padding: 4 }}
              xs={{
                span: 24,
              }}
              md={{ span: 12 }}
              lg={{
                span: 12,
              }}
            >
              <ul>
                {product && product.feature_bullets && (
                  <h3>Key Features of this Item: </h3>
                )}
                <Divider />
                {product &&
                  product.feature_bullets &&
                  product.feature_bullets.map((item, index) => (
                    <li key={index}>
                      <p>{item}</p>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col
              style={{ padding: 4, marginLeft: 10 }}
              xs={{
                span: 22,
              }}
              md={{ span: 11 }}
              lg={{
                span: 11,
              }}
            >
              <h3>Product information Specifications: </h3>
              <Divider />
              {product &&
                product.specifications.map((item, index) => (
                  <>
                    <p>
                      <strong>{item.name}</strong> : {item.value}
                    </p>
                  </>
                ))}
            </Col>
          </Row>
          {compare_with_similar && <h3>Similar Products: </h3>}
          {compare_with_similar && (
            <Row>
              <List
                // grid={{ gutter: [24, 12], column: 4 }}
                grid={{
                  gutter: 12,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 4,
                  xl: 4,
                  xxl: 3,
                }}
                pagination={{
                  onChange: (page) => {},
                  pageSize: 4,
                  size: "default",
                }}
                dataSource={compare_with_similar}
                renderItem={(item) => (
                  <List.Item>
                    <ProductCardSimilar data={item} />
                  </List.Item>
                )}
              />
            </Row>
          )}
        </>
      )}
    </LayoutHome>
  );
};

export default SingleProduct;
