import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Menu,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  LoginOutlined,
  SearchOutlined,
  FilterOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountry,
  SEARCH_RESULTS,
  setCountryName,
} from "../../../store/actions/homepageActions";
// import Search from "antd/lib/transfer/search";
const { Option } = Select;
const { Search } = Input;

const Navbar = () => {
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const [country, setCountry] = useState(
    localStorage.getItem("Country") !== undefined
      ? localStorage.getItem("Country")
      : "USA"
  );
  const blogDatafrom = useSelector((state) => state.home.getAllBlogs) || [];
  const productsDataArrays =
    useSelector((state) => state.home.productsDataArrays) || [];
  const pathname = window.location.pathname;

  const dispatch = useDispatch();
  useEffect(() => {
    //setCountry(localStorage.getItem("Country"));
    return () => {};
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("Country") === undefined ||
      localStorage.getItem("Country") === null
    ) {
      dispatch(getCountry());
    } //else {
    //   setCountryName(localStorage.getItem("Country"));
    // }
  }, []);

  const handleSelect = (e) => {
    localStorage.setItem("Country", e);
    navigate("/");
    // openNotification();
    // setTimeout(() => {
    //   message.success("Country change successfully");
    // }, 2000);
    window.location.reload();
    dispatch(setCountryName(e));
  };

  const handleSearch = (value = "s") => {
    if (value.length > 6 && pathname.includes("/blogs")) {
      const searchBlog =
        blogDatafrom &&
        blogDatafrom.length > 0 &&
        blogDatafrom.filter((element) => {
          return element.data.title.toLowerCase().includes(value.toLowerCase());
        });
      dispatch({ type: SEARCH_RESULTS, payload: searchBlog });
    } else if (value.length > 6) {
      const searchProducts =
        productsDataArrays &&
        productsDataArrays.length > 0 &&
        productsDataArrays.filter((element) => {
          return element.title.toLowerCase().includes(value.toLowerCase());
        });
      dispatch({ type: SEARCH_RESULTS, payload: searchProducts });
    }
  };

  const handleClick = (e) => {
    if (search) {
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#0ef7f7" }}>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          <Col
            xs={6}
            sm={12}
            md={12}
            lg={4}
            style={{ padding: 2, textAlign: "center" }}
          >
            <Link to="/">
              <strong style={{ marginLeft: 20 }}> ECRON HUB </strong>
            </Link>
          </Col>
          <Col
            xs={15}
            sm={12}
            md={6}
            lg={8}
            style={{ margin: "auto", padding: 2 }}
          >
            <div className="search">
              <Search
                placeholder="Search Products, category, top rated products etc"
                enterButton={<SearchOutlined />}
                // enterButton="Search"
                onChange={(e) => handleSearch(e.target.value)}
                //onPressEnter={handleClick}
                onClick={handleClick}
              />
            </div>
            {/* <Form.Item style={{ display: "flex" }}> */}
            {/* <AutoComplete
              name="search"
              //   onChange={(val) => setSearch(val)}
              //   onSelect={(val) => setSearch(val)}
              //   dataSource={options}
              //   value={search}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            >
              <Input
                style={{ margin: "auto", padding: 2, width: 250 }}
                name="search"
                // suffix={<SearchOutlined />}
                placeholder="Search Products 1, category, top rated products etc"
                onChange={(e)=>setSearch(e.target.value)}
              ></Input>
            </AutoComplete>
            <Button
              type="primary"
              onClick={handleSearch}
              style={{ marginLeft: 2 }}
            >
              <SearchOutlined />
            </Button> */}
            {/* </Form.Item> */}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={8}
            style={{ marginLeft: "2rem", padding: 2 }}
          >
            <Select
              placeholder="Country"
              style={{ width: 200 }}
              defaultValue={country}
              onSelect={(e) => handleSelect(e)}
            >
              <Option value="usa">Country-USA</Option>
              <Option value="india">Country-India</Option>
            </Select>
          </Col>
          <Col
            style={{ marginLeft: 15, padding: 2, textAlign: "center" }}
            xs={8}
            sm={12}
            md={2}
            lg={2}
          >
            <Avatar icon={<UserOutlined />} />
          </Col>
        </Row>
      </div>
      <Menu
        mode="horizontal"
        //className="header-bar"
        theme="dark"
        style={{ alignContent: "center" }}
      >
        <Menu.Item key="1" className="page-select">
          <NavLink to="/electronics">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            <strong>Electronics</strong>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/projectors">
            <strong>Projectors </strong>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/oled-tv">
            <strong>OLED TVs</strong>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/appliances">
            <strong>HOME APPLIANCES</strong>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/car-accessories">
            <strong>CAR ACCESSSORIES</strong>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/blogs">
            <strong>ARTICLES</strong>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="7">
          <NavLink to="/json-to-xml">
            <strong>TOOLS</strong>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="8">
          <NavLink to="/home-loan-calculator">
            <strong>EMI CLAC</strong>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      {/* </Nav> */}
    </>
  );
};

export default Navbar;
