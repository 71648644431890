import { Button, Card, Rate } from "antd";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getLinkTg } from "../../../utilities/getLinkRef";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./electronics.css";
import { Link, useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import { getLink } from "../../../utilities/getLink";
import { reverseString } from "../../../utilities/reverseString";

const ProductCard = ({ data, path, loc }) => {
  const navigate = useNavigate();

  const pathUrl = loc === "/" ? "products" : loc;

  const asinNo = reverseString(data.asin) || "";

  const country = useSelector((state) => state.home.country);

  // useEffect(() => {
  //   window.location.reload();
  //   return () => {};
  // }, [country]);

  return (
    <Card className="product-card" style={{ height: 320, textAlign: "center" }}>
      <div>
        <Link
          to={`/${pathUrl}/${slugify(data.title.substring(0, 50))}-${
            data.asin
          }`}
          state={{ asinNo: data.asin }}
          // to={{
          //   pathname: `/electronics/${slugify(data.title)}`,
          //   state: { asinNo: data.asin },
          // }}
        >
          {" "}
          <h6 style={{ color: "#001529" }}>{data.title}</h6>
        </Link>

        <LazyLoadImage
          src={data.image}
          alt="data.title"
          width={200}
          height={160}
          effect="blur"
        />
      </div>
      {data.rating && (
        <div style={{ display: "-ms-grid", textAlign: "center" }}>
          <h5>
            Rating : {data.rating} &nbsp; &nbsp;
            <Rate allowHalf defaultValue={data.rating} />
          </h5>
        </div>
      )}
      <div style={{ display: "-ms-grid", textAlign: "center" }}>
        <a
          className="btn btn-sm btn-secondary"
          target="_blank"
          href={data.link + "&tag=kmusaf0b-20"}
          rel="noreferrer"
        >
          <strong>
            &nbsp; &nbsp; Price :{" "}
            {data.price && data.price.raw ? data.price.raw : "Check Price"}{" "}
            &nbsp; &nbsp;&nbsp; &nbsp;
          </strong>
        </a>
        <Button type="primary" size="middle" style={{ marginBottom: 2 }}>
          <a
            className="btn btn-sm btn-secondary"
            target="_blank"
            href={getLink(data.link)}
            rel="noreferrer"
          >
            Buy Now
          </a>
        </Button>
      </div>
    </Card>
  );
};

export default ProductCard;
