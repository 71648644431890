import { Alert } from 'antd'
import React from 'react'

const ErrorAlert = (props) => {
    const {message} =props
  return (
    <Alert>ErrorAlert</Alert>
  )
}

export default ErrorAlert