import React from "react";
import { Layout, Row, Col, Typography, List, Card, Avatar, Tag } from "antd";
import "./blog.css";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const DemoBlog = () => {
  const data = [
    {
      title: "Article 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Tag 1", "Tag 2"],
      author: "Author 1",
    },
    {
      title: "Article 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Tag 3", "Tag 4"],
      author: "Author 2",
    },
    {
      title: "Article 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Tag 5", "Tag 6"],
      author: "Author 3",
    },
  ];

  return (
    <Layout className="layout">
      <Header>
        <Title level={3} style={{ color: "#fff" }}>
          My Blog
        </Title>
      </Header>
      <Content style={{ padding: "20px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={18}>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    style={{ width: "100%" }}
                    cover={
                      <img
                        alt={item.title}
                        src="https://via.placeholder.com/500x300"
                      />
                    }
                  >
                    <Card.Meta
                      avatar={
                        <Avatar src="https://via.placeholder.com/100x100" />
                      }
                      title={item.title}
                      description={item.description}
                    />
                    <div style={{ marginTop: "10px" }}>
                      {item.tags.map((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                      ))}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Text type="secondary">Author: {item.author}</Text>
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: "center" }}>My Blog ©2022</Footer>
    </Layout>
  );
};

export default DemoBlog;
