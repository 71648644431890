import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useEffect } from "react";
import {
  UserOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ErrorAlert from "../../mailchimp/ErrorAlert";
import { createRef } from "react";
import { subscribeUs } from "../../../store/actions/homepageActions";

const SubscriptionForm = ({
  subsribeVisible,
  // message,
  onValidated,
  setModal1Open,
}) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const formRef = createRef();
  const [form] = Form.useForm();
  const [errorObj, setErrorObj] = useState({
    errorMessage: "",
    errorStatus: false,
  });
  const { errorMessage, errorStatus } = errorObj;

  const onFinish = async (values) => {
    const { name, email } = values;

    if (name.length > 6 && email.includes("@")) {
      const res = await subscribeUs(values);

      if (res && res.includes("successfully")) {
        message.success(res);
      } else {
        message.error("Failed to be subscribe. Try Again after some time!");
      }
    } else {
    }
    setModal1Open(false);
    form.resetFields();
  };
  return (
    <Modal
      title="Subcribe for new upcoming products and price drop info"
      icon={<ExclamationCircleOutlined />}
      style={{
        top: 20,
      }}
      visible={subsribeVisible}
      onOk={() => setModal1Open(false)}
      onCancel={() => setModal1Open(false)}
      footer={null}
    >
      {" "}
      <Form form={form} ref={formRef} onFinish={onFinish}>
        {errorStatus && (
          <Form.Item>
            <ErrorAlert prefix={errorMessage} placeholder="Name" size="large" />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          rules={[
            {
              min: 6,
              message: "Minimum 6 charecters",
            },
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              pattern: new RegExp(`^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$`),
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            size="large"
            name="MERGE0"
            id="MERGE0"
          />
        </Form.Item>

        <Form.Item style={{ display: "block" }}>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            value="Subscribe"
          >
            Subscribe
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscriptionForm;
