import { Breadcrumb, Button, Form, Input, InputNumber } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../seo/SEO";
import LayoutHome from "../../layouts/LayoutHome";

const { Item } = Form;

const EMI_CALCULATOR_CONSTANTS = {
  MONTHS_IN_YEAR: 12,
  HUNDRED: 100,
};

const EMI_CALCULATOR_FORM_LAYOUT = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const EMI_CALCULATOR_BUTTON_LAYOUT = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};

const EMI_CALCULATOR_RESULT_LAYOUT = {
  wrapperCol: {
    span: 8,
  },
};

const EMI_CALCULATOR_INITIAL_VALUES = {
  loanAmount: 0,
  interestRate: 0,
  loanTerm: 0,
};

const EMI_CALCULATOR_FORM_RULES = {
  loanAmount: [
    {
      required: true,
      message: "Please enter loan amount",
    },
  ],
  interestRate: [
    {
      required: true,
      message: "Please enter interest rate",
    },
  ],
  loanTerm: [
    {
      required: true,
      message: "Please enter loan term",
    },
  ],
};

const EMICalculator = () => {
  const [form] = Form.useForm();
  const [result, setResult] = useState(0.0);

  const calculateEmi = (values) => {
    const { loanAmount, interestRate, loanTerm } = values;

    const monthlyInterestRate =
      interestRate /
      EMI_CALCULATOR_CONSTANTS.HUNDRED /
      EMI_CALCULATOR_CONSTANTS.MONTHS_IN_YEAR;
    const totalMonths = loanTerm * EMI_CALCULATOR_CONSTANTS.MONTHS_IN_YEAR;

    const emi =
      (loanAmount *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, totalMonths)) /
      (Math.pow(1 + monthlyInterestRate, totalMonths) - 1);

    setResult(`Your EMI is ${emi.toFixed(2)} per month.`);
  };
  return (
    <LayoutHome>
      <SEO />
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div>
        <Form
          {...EMI_CALCULATOR_FORM_LAYOUT}
          form={form}
          onFinish={calculateEmi}
          initialValues={EMI_CALCULATOR_INITIAL_VALUES}
        >
          <Item
            label="Loan Amount"
            name="loanAmount"
            rules={EMI_CALCULATOR_FORM_RULES.loanAmount}
          >
            <InputNumber
              min={1}
              max={1000000000}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Item>

          <Item
            label="Interest Rate"
            name="interestRate"
            rules={EMI_CALCULATOR_FORM_RULES.interestRate}
          >
            <Input
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              style={{ width: 400 }}
            />
          </Item>

          <Item
            label="Loan Term (in years)"
            name="loanTerm"
            rules={EMI_CALCULATOR_FORM_RULES.loanTerm}
          >
            <InputNumber min={1} max={35} />
          </Item>

          <Item {...EMI_CALCULATOR_BUTTON_LAYOUT}>
            <Button type="primary" htmlType="submit">
              Calculate EMI
            </Button>
          </Item>
        </Form>
      </div>
      <div>Result is : {result}</div>
    </LayoutHome>
  );
};

export default EMICalculator;
