import React, { useState } from "react";
//import "./mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
//import InputField from "../../ui/InputField/InputField";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useEffect } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  ThunderboltOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import ErrorAlert from "./ErrorAlert";
import { createRef } from "react";
import { useDispatch } from "react-redux";
import { sendEmail } from "../../store/actions/homepageActions";

const CustomForm = ({ status, message1, onValidated }) => {
  //const { modalOpen, setModalOpen } = useGHStContext();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const formRef = createRef();
  const [form] = Form.useForm();
  const [errorObj, setErrorObj] = useState({
    errorMessage: "",
    errorStatus: false,
  });
  const { errorMessage, errorStatus } = errorObj;
  const handleMcSubmit = (values) => {
    // e.preventDefault();
    // email &&
    //   firstName &&
    //   lastName &&
    //   email.indexOf("@") > -1 &&
    //   onValidated({
    //     EMAIL: email,
    //     MERGE1: firstName,
    //     MERGE2: lastName,
    //   });
  };

  const handleSubmit = async (values) => {
    const { FNAME, EMAIL, MESSAGE } = values;

    const payload = {
      EMAIL: EMAIL,
      MESSAGE: MESSAGE,
      FNAME: FNAME,
      site: "ecronhub",
    };

    // EMAIL &&
    //   FNAME &&
    //   MESSAGE &&
    //   EMAIL.indexOf("@") > -1 &&
    //   onValidated(payload);

    const res = await dispatch(sendEmail(payload));
    if (res && res.includes("successfully")) {
      message.success("message sent successfully");
      form.resetFields();
    } else {
      message.error(res);
    }
  };

  return (
    <Form form={form} ref={formRef} onFinish={handleSubmit}>
      {errorStatus && (
        <Form.Item>
          <ErrorAlert prefix={errorMessage} placeholder="Name" size="large" />
        </Form.Item>
      )}
      <Form.Item
        name="FNAME"
        rules={[
          {
            min: 6,
            message: "Minimum 6 charecters",
          },
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Name" size="large" />
      </Form.Item>
      <Form.Item
        name="EMAIL"
        rules={[
          {
            pattern: new RegExp(`^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$`),
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="Email"
          size="large"
          name="MERGE0"
          id="MERGE0"
        />
      </Form.Item>
      <Form.Item name="MESSAGE">
        <TextArea
          placeholder="Your Message"
          rows={4}
          name="MESSAGE"
          id="MESSAGE"
        />
      </Form.Item>

      <Form.Item style={{ display: "block" }}>
        <Button type="ghost" onClick={() => form.resetFields()}>
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          htmlType="submit"
          size="middle"
          value="Subscribe"
        >
          Send Now
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomForm;
