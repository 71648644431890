import {
  GET_COUNTRY,
  SET_PRODUCT,
  GET_ALL_BLOGS,
  BLOG_DETAILS,
  SIDE_BAR_TITLES,
  SET_ALL_PRODUCTS,
  SEARCH_RESULTS,
} from "../actions/homepageActions";

const INITIAL_STATE = {
  isAuth: false,
  country: "USA",
  productData: {},
  productsDataArrays: [],
  getAllBlogs: [],
  blogDetails: [],
  sideBarTitles: [],
  searchResults: [],
};

const HomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRY: {
      return {
        ...state,
        isAuth: false,
        country: action.payload,
      };
    }

    case SET_PRODUCT: {
      return {
        ...state,
        isAuth: false,
        productData: action.payload,
      };
    }

    case SET_ALL_PRODUCTS: {
      return {
        ...state,
        isAuth: false,
        productsDataArrays: action.payload,
      };
    }

    case GET_ALL_BLOGS: {
      return {
        ...state,
        isAuth: false,
        getAllBlogs: action.payload,
      };
    }

    case BLOG_DETAILS: {
      return {
        ...state,
        isAuth: false,
        blogDetails: action.payload,
      };
    }

    case SIDE_BAR_TITLES: {
      return {
        ...state,
        isAuth: false,
        sideBarTitles: action.payload,
      };
    }

    case SEARCH_RESULTS: {
      return {
        ...state,
        isAuth: false,
        searchResults: action.payload,
      };
    }

    default:
      return {
        INITIAL_STATE,
      };
  }
};

export default HomeReducer;
