import axios from "axios";
import {
  inIPhones_p1,
  inIPhones_p2,
  inSmartPhones_p1,
} from "../../components/datajson/electronics/inElectronics";
import {
  indOledLists_p1,
  indOledLists_p2,
} from "../../components/datajson/oledTv/categoryOled";
import { ECRON_BACK_LOCAL_URL, ECRON_BACK_URL } from "../../utilities/config";
const { CancelToken, isCancel } = axios;
const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

export const GET_COUNTRY = "GET_COUNTRY";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";

export const SET_PRODUCT = "SET_PRODUCT";
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const BLOG_DETAILS = "BLOG_DETAILS";
export const SIDE_BAR_TITLES = "SIDE_BAR_TITLES";
export const SEARCH_RESULTS = "SEARCH_RESULTS";

const getCountryStorage = () => {
  return localStorage.getItem("Country");
};

export const setCountryName = (countryName) => {
  return {
    type: GET_COUNTRY,
    payload: countryName,
  };
};

export const setProductDetails = (product) => {
  return {
    type: SET_PRODUCT,
    payload: product,
  };
};

export const getCountry = () => (dispatch) => {
  new Promise((resolve, reject) => {
    const urlCountry = "https://ipapi.co/json/";
    axiosInstance
      .get(urlCountry)
      .then((response) => {
        const { location, country_name } = response.data;
        dispatch(setCountryName(country_name));
        localStorage.setItem("Country", country_name);
      })
      .catch((error) => {
        dispatch(setCountryName("usa"));
        localStorage.setItem("Country", "usa");
        return reject({});
      });
  });
};

const country = localStorage.getItem("Country");
const getEmptyProducts = async (country, asin) => {
  console.log("Hello ");
  return await dumpNonExistingProducts(country, asin);
};

export const getProductByAsin = (asin) => {
  return async (dispatch) => {
    const urlCountry = `${ECRON_BACK_URL}/products/${country}/${asin}`;
    dispatch(setProductDetails({}, false));

    const res = await axios
      .get(urlCountry)
      .then((response) => {
        // console.log("getEmptyProducts ", response.data);
        let val;
        if (response.data === null) {
          // console.log("getEmptyProducts ", response.data);
          val = dispatch(dumpNonExistingProducts(country, asin));
        } else {
          val = response.data;
        }
        dispatch(setProductDetails(response.data, false));
        return val;
      })
      .catch((error) => {
        // console.log(error);
        dispatch(setProductDetails({}, false));
        return error.message;
      });
    return res;
  };
};

export const getCategoryProducts = (loc) => {
  return async (dispatch) => {
    const urlCountry =
      country === "usa"
        ? `${ECRON_BACK_URL}/productsUs`
        : `${ECRON_BACK_URL}/productsInd`;
    //dispatch(setProductDetails({}, false));

    const res = await axios
      .get(urlCountry)
      .then((response) => {
        dispatch({ type: SET_ALL_PRODUCTS, payload: response.data.reverse() });
        return response.data;
      })
      .catch((error) => {
        return error.message;
      });

    return res;
  };
};

export const getBlogs = (asin) => {
  return async (dispatch) => {
    const urlBlogs = `${ECRON_BACK_URL}/blogs`;
    const res = await axios
      .get(urlBlogs)
      .then((response) => {
        dispatch({ type: GET_ALL_BLOGS, payload: response.data.reverse() });
        return response.data;
      })
      .catch((error) => {
        return error.message;
      });
    return res;
  };
};

export const dumpNonExistingProducts = (country, asinNo, dataArray) => {
  return async (dispatch) => {
    const urlCountry = `${ECRON_BACK_URL}/addAsinProduct`;
    const data = {
      domain: country === "usa" ? "amazon.com" : "amazon.in",
      asin: asinNo,
      type: "product",
    };
    // console.log("dumpNonExistingProducts ", asinNo);
    const res = await axios
      .post(urlCountry, data)
      .then((response) => {
        dispatch(setProductDetails(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(setProductDetails({}));
        return error.message;
      });

    return res;
  };
};

export const dump_existing_products = async (country, category, dataArray) => {
  const dumpUrlEndpoint = `${ECRON_BACK_URL}/dumpxx`;
  const response = await axios({
    method: "POST",
    url: dumpUrlEndpoint,
    data: inIPhones_p1.search_results[0],
  });
  return response;
  // axiosInstance
  //   .post(dumpUrlEndpoint, data)
  //   .then((response) => {
  //     dispatch(setCountryName(response.data));
  //   })
  //   .catch((error) => {
  //     return { error };
  //   });
  // new Promise((resolve, reject) => {
  //   const dumpUrlEndpoint = `${ECRON_BACK_URL}dump/${country}/${category}`;
  //   axiosInstance
  //     .post(dumpUrlEndpoint, data)
  //     .then((response) => {
  //       dispatch(setCountryName(response.data));
  //     })
  //     .catch((error) => {
  //       return reject({ error });
  //     });
  // });
};

export const subscribeUs = async (values) => {
  const country = getCountryStorage();
  const body = { country, email: values.email, name: values.name };
  const res = await axios
    .post(`${ECRON_BACK_URL}/subscribe`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });

  return res;
};

export const sendEmail = (values) => {
  return async (dispatch) => {
    const country = getCountryStorage();
    const body = values;
    body.country = country;
    const res = await axios
      .post(`${ECRON_BACK_URL}/email`, body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.message;
      });

    return res;
  };
};

export const searchProducts = (values) => {
  return async (dispatch) => {
    const country = getCountryStorage();
    const body = values;
    body.country = country;
    const res = await axios
      .post(`${ECRON_BACK_LOCAL_URL}/search`, body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.message;
      });

    return res;
  };
};
