import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import { useLocation } from "@reach/router";
import { useLocation } from "react-router-dom";

const SEO = ({ title, description, image, article, keywords }) => {
  const { pathname } = useLocation();
  const defaultTitle = "Best way to find high rating and quality products";
  const titleTemplate = " %s | EcronHub";
  const defaultDescription =
    "Shop a wide range of electronics products at EcronHub.com. We offer the latest technology and top brands in smartphones, laptops, TVs, cameras, and more. Find great deals and discounts on your favorite products today";
  const siteUrl = "https://www.ecronhub.com";
  const defaultImage = "https://m.media-amazon.com/images/I/81KRgpBOrsL.jpg";

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname === "/" ? "" : pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      {seo.image && (
        <meta
          property="og:image"
          content={seo.image}
          // data-react-helmet="true"
        />
      )}
      {/* <meta
        name="og:description"
        content={seo.description}
        data-react-helmet="true"
      /> */}
      <meta name="robots" content="max-image-preview:large" />
      {/* <meta name="image" content={seo.image} data-react-helmet="true" /> */}
      {keywords && (
        <meta
          name="keywords"
          content={keywords.join(", ")}
          data-react-helmet="true"
        />
      )}
      <meta
        name="google-site-verification"
        content="3df_Rigt9L9FkOsslwUI_-gOyfznuUsGDGVB4gEvX54"
      />
      {seo.url && (
        <meta property="og:url" content={seo.url} data-react-helmet="true" />
      )}
      {(article ? true : null) && (
        <meta property="og:type" content="article" data-react-helmet="true" />
      )}
      {seo.title && (
        <meta
          property="og:title"
          content={seo.title}
          data-react-helmet="true"
        />
      )}
      {seo.description && (
        <meta
          property="og:description"
          content={seo.description}
          data-react-helmet="true"
        />
      )}

      {/* <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={defaultTitle} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.title} />} */}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
