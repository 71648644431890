import { Breadcrumb, Button, Form, Input, message } from 'antd';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import SEO from '../../../seo/SEO';
import LayoutHome from '../../layouts/LayoutHome';
// import xml2js from "xml2js";

const Xml2JsonConv = () => {
    const [jsonData, setJsonData] = useState("");
    const [xmlData, setXmlData] = useState("");
    
  const handleConvert = () => {
    try {
    //     const parser = new xml2js.Parser();
    //   const jsnData=  parser.parseString(xmlData, function (err, result) {
    //       setXmlData(result);
    //     });
      // js2xmlparser .parse("person", jsonData)
      // const data = JSON.parse(jsonData);
      // const options = { compact: true, ignoreComment: true, spaces: 4 };
      // const xml = js2xml(data, options);
      // const formattedXml = format(xml);
      // setXmlData(formattedXml);
    } catch (error) {
      message.error("Invalid JSON data");
    }
  };
  return (
    <LayoutHome>
      <SEO />
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Form>
          <Form.Item label="XML Data">
            <Input.TextArea
              value={xmlData}
              onChange={(e) => setXmlData(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="JSON Data">
            <Input.TextArea value={jsonData} readOnly />
          </Form.Item>
          <Button type="primary" onClick={handleConvert}>
            Convert
          </Button>
        </Form>
      </div>
    </LayoutHome>
  );
}

export default Xml2JsonConv;