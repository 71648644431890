import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin, Col } from "antd";
import LayoutHome from "../../layouts/LayoutHome";
import SEO from "../../../seo/SEO";
import { Link } from "react-router-dom";
import BlogsListPagination from "./BlogsListPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogs,
  SIDE_BAR_TITLES,
} from "../../../store/actions/homepageActions";
import axios from "axios";
// import "./blog.css"

const Blogs = () => {
  const [blogsData, setBlogsData] = useState();
  const [loading, setLoading] = useState(false);

  const blogDatafrom = useSelector((state) => state.home.getAllBlogs) || [];

  const blogDataSearch = useSelector((state) => state.home.searchResults) || [];

  const dispatch = useDispatch();
  const getBlogData = async () => {
    const res = await dispatch(getBlogs());
    if (res) {
      res && setBlogsData(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (blogDatafrom.length < 1) {
      setLoading(true);
      getBlogData();
    } else {
      setBlogsData(blogDatafrom);
    }
  }, []);

  const relatedTitle = [];

  useEffect(() => {
    blogsData &&
      blogsData.length > 0 &&
      blogsData.map((item, index) => {
        const id = item.data.id;
        const title = item.data.title;
        return relatedTitle.push({ id, title });
      });
    if (relatedTitle.length > 0) {
      dispatch({ type: SIDE_BAR_TITLES, payload: relatedTitle });
    }

    return () => {};
  }, [blogsData]);

  // useEffect(() => {
  //   setBlogsData(blogDataSearch);
  //   console.log("blogDataSearch ", blogDataSearch.length);
  //   return () => {};
  // }, [blogDataSearch]);

  return (
    <LayoutHome>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>&nbsp;{"/"}&nbsp;
          <Link>Articles</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {loading ? (
        <Col
          span={24}
          style={{
            textAlign: "center",
            height: 500,
            paddingTop: "8%",
          }}
        >
          <Spin style={{ textAlign: "center" }} size="large" tip="Loading..." />
        </Col>
      ) : (
        <>
          <SEO
            description={"Blogs, Articles"}
            title={"Blogs"}
            keywords={[
              " Ecronhub blogs, Eletronics, Oled Tv, Car Accessories, Projectors, Blogs, Articles,",
            ]}
          />

          <BlogsListPagination data={blogsData} />
        </>
      )}
    </LayoutHome>
  );
};

export default Blogs;
