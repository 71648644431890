import { Button, Card, Rate } from "antd";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getLink } from "../../../utilities/getLink";
import { getLinkTg } from "../../../utilities/getLinkRef";
import "./electronics.css";

const ProductCardSimilar = ({ data }) => {
  return (
    <Card
      className="product-card"
      style={{ minHeight: 260, textAlign: "center" }}
    >
      <div>
        <h6>{data.title}</h6>
        <LazyLoadImage
          src={data.image}
          alt="data.title"
          width="150px"
          height="120px"
          effect="blur"
        />
      </div>
      <div>
        <Rate allowHalf defaultValue={data.rating} />
        <h5>Customer Rating : {data.rating}</h5>
      </div>
      <Button type="primary" size="middle" style={{ marginBottom: 2 }}>
        <a
          className="btn btn-sm btn-secondary"
          target="_blank"
          href={getLink(data.link)}
          rel="noreferrer"
        >
          Buy Now
        </a>
      </Button>
    </Card>
  );
};

export default ProductCardSimilar;
