import { Breadcrumb, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProductsListPagination from "../electronics/ProductsListPagination";
import LayoutHome from "../../layouts/LayoutHome";
import { useDispatch } from "react-redux";
import {
  dumpNonExistingProducts,
  getCountry,
} from "../../../store/actions/homepageActions";
import SEO from "../../../seo/SEO";

const Homepage = ({ loc }) => {
  //const country = localStorage.getItem("Country");
  useEffect(() => {
    if (
      localStorage.getItem("Country") === undefined ||
      localStorage.getItem("Country") === null
    ) {
      dispatch(getCountry());
    }
    dumpNonExistingProducts();
  }, []);

  const dispatch = useDispatch();

  const pathname = window.location.pathname;

  const bedcrumb = () => {};

  return (
    <LayoutHome>
      <SEO />
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={pathname}>
            {pathname.charAt(1).toUpperCase() + pathname.substring(2)}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <ProductsListPagination path={pathname} loc={loc} />
    </LayoutHome>
  );
};

export default Homepage;
