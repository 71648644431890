import { Button, Col, Row } from "antd";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import LayoutHome from "./LayoutHome";
import { HomeOutlined } from "@ant-design/icons";
import backgroundImage from "../../assets/technogy.png";

const ErrorPage = () => {
  return (
    <LayoutHome>
      <Row
        justify="center"
        style={{
          height: 400,
        }}
      >
        <Col
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          xs={{
            span: 24,
          }}
          md={{ span: 12 }}
          lg={{
            span: 12,
          }}
        >
          <h3 style={{ color: "green" }}>
            Sorry, the page you tried doesn't exist
          </h3>
          <NavLink to="/">
            <Button type="primary" size="large" style={{ width: "100%" }}>
              <HomeOutlined /> Back To Home
            </Button>
          </NavLink>
        </Col>
      </Row>
    </LayoutHome>
  );
};

export default ErrorPage;
