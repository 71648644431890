import React from "react";
import { FaFacebook } from "react-icons/fa";

const FBShareButton = ({ url, title, imageUrl }) => {
  return (
    <div
      className="fb-share-button"
      data-href={url}
      data-layout="button"
      data-size="large"
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse`}
        className="fb-xfbml-parse-ignore"
      >
        <FaFacebook size={30} color={"blue"} />
      </a>
    </div>
  );
};

export default FBShareButton;
